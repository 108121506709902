
import React from 'react'
import {
  Navbar,
  Image,
  Col,
  Row,
  Dropdown,
  Modal,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Spinner } from 'react-bootstrap'
import './Header.scss'
import useHeader from './hooks/useHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCheck, faUser, faMoneyCheckAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ADMIN_ROLES, NOTIFICATION_TYPE, notificationDropDownArr } from '../../utils/constants'
import { getItem } from '../../utils/storageUtils'
import InfiniteScrollComponent from '../InfiniteScrollWrapper/InfiniteScrollComponent'

const Header = ({ isTenantRoute, isAffiliate, isAgent }) => {
  const {
    navigate,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    isNewNotification,
    newNotificationData,
    handleCheckNotification,
    t,
    role,
    adminDetails,
    handleNavigate,
    isNotificationOpen,
    notification,
    handleMarkReadNotification,
    handleDeleteNotification,
    handleMarkReadSingleNotification,
    handleSingleDeleteNotification,
    updateLoading,
    deleteLoading,
    notificationLoading,
    handleCloseNotification,
    loadMoreData,
    page,
    limit,
    notificationType,
    setNotificationType,
    setPage
  } = useHeader({ isTenantRoute, isAffiliate, isAgent })

  const tenantBar = (tenantDetails) => {
    return (
      <>
        {tenantDetails?.tenantConfig?.logoUrl && (
          <Image
            thumbnail
            alt=''
            src={tenantDetails.tenantConfig.logoUrl}
            className='d-inline-block align-top mx-2'
          />
        )}
        {tenantDetails?.name}
      </>
    )
  }

  const superAdminBar = (adminDetails) => {
    return (
      <>
        {adminDetails?.tenantConfig?.logoUrl && (
          <Image
            thumbnail
            alt=''
            src={adminDetails.tenantConfig.logoUrl}
            className='d-inline-block align-top mx-2'
          />
        )}
        {role === ADMIN_ROLES.AFFILIATE ? adminDetails?.username : adminDetails?.firstName}
      </>
    )
  }

  return (
    <>
      <div style={{ padding: '0 0.8rem 0 0.8rem' }}>
        <Row className='mb-2 px-3 py-2 rounded-2 bg-white shadow align-items-center'>
          {/* Tenant info name and logo */}
          <Col>
            <Navbar.Brand>
              {(role === ADMIN_ROLES.SUPERADMIN || role === ADMIN_ROLES.AFFILIATE) ? superAdminBar(adminDetails) : tenantBar(tenantDetails)}
            </Navbar.Brand>
          </Col>
          <Col>
            <div className='d-flex gap-1 align-items-center justify-content-end'>
              <div className=' position-relative'>
                <div onClick={handleCheckNotification} className='notification-icon'>
                  <FontAwesomeIcon size='25' icon={faBell} />
                  {(isNewNotification > 0 || notification?.totalUnreadNotification > 0) && <div className='dot'>{notification?.totalUnreadNotification} </div>}

                </div>

                <Modal className='notification-modal' show={isNotificationOpen} fullscreen onHide={handleCloseNotification}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t('notification')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body id='notificationTarget'>
                    <InfiniteScrollComponent
                      hasMore={notification?.result?.length < notification?.totalNotification}
                      fetchData={loadMoreData}
                      length={notification?.result?.length}
                      limit={limit}
                      totalPages={Math.ceil(notification?.totalNotification / limit)}
                      page={page}
                      scrollableTarget='notificationTarget'
                    >

                      <div className='mb-2'>
                        <div className='d-flex gap-4 align-items-start justify-content-between'>
                          <div>
                            <BForm.Select
                              name='bonusType'
                              size='sm'
                              value={notificationType}
                              onChange={(e) => {
                                setNotificationType(e.target.value)
                                setPage(1)
                              }}
                              style={{ maxWidth: '230px' }}
                            >
                              <option value='' key=''>
                                {t('all')}
                              </option>
                              {notificationDropDownArr?.map(({ label, value }) => {
                                return (
                                  <option key={`bonusType ${label}`} value={value}>
                                    {t(label)}
                                  </option>
                                )
                              })}
                            </BForm.Select>
                          </div>
                          <div className='d-flex gap-4 align-items-center'>
                            <button disabled={notification?.totalUnreadNotification === 0} onClick={() => handleMarkReadNotification({ allNotifications: true })} className='notif-btn all-read'>{t('markAllRead')}</button>
                            <button disabled={notification?.totalNotification === 0} onClick={() => handleDeleteNotification({ allNotifications: true })} className='notif-btn all-delete'>{t('deleteAll')}</button>
                          </div>
                        </div>
                      </div>
                      <div className='notification-dropdown-wrapper'>
                        <div className='notification-dropdown'>
                          <>
                            {newNotificationData?.length > 0 &&
                              newNotificationData?.map((item, index) => {
                                return (
                                  <div key={index} className='mb-1'>
                                    <div onClick={() => handleNavigate(item)} className='notification-content d-flex gap-3 align-items-start unread  '>
                                      <div className='notif-icon'> <FontAwesomeIcon size='25' icon={item?.type === NOTIFICATION_TYPE?.BONUS ? faMoneyCheckAlt : faUser} /></div>
                                      <div className='d-flex align-items-start flex-grow-1 gap-2'>
                                        <span className='text flex-grow-1'>{
                                          item?.message && typeof item?.message === 'object'
                                            ? item?.message[getItem('language')]
                                            : item?.message
                                        }
                                        </span>

                                      </div>
                                    </div>
                                  </div>

                                )
                              }

                              )}
                          </>
                          <>
                            {
                              notification?.totalNotification > 0
                                ? notification?.result?.map((item, index) =>
                                  <div key={index} onClick={() => handleNavigate(item)} className={`mb-1 notification-content d-flex gap-3 align-items-start ${item?.isNotificationRead ? 'read' : 'unread'}  `}>
                                    <div className='notif-icon'> <FontAwesomeIcon size='25' icon={item?.type === NOTIFICATION_TYPE?.KYC_REQUEST ? faUser : faMoneyCheckAlt} /></div>
                                    <div className='d-flex align-items-start flex-grow-1 gap-2'>
                                      <span className='text flex-grow-1'>{
                                          item?.translations && typeof item?.translations === 'object'
                                            ? item?.translations[getItem('language')]
                                            : item?.description
                                        }
                                      </span>
                                      {item?.isNotificationRead === false && (
                                        <div
                                          onClick={(e) => {
                                            handleMarkReadSingleNotification({ notificationId: item?.notificationId, userId: item?.userId })
                                            e.stopPropagation()
                                          }} className='notif-icon read'
                                        > <FontAwesomeIcon size='25' icon={faCheck} />
                                        </div>
                                      )}
                                      <div
                                        onClick={(e) => {
                                          handleSingleDeleteNotification({ notificationId: item?.notificationId, userId: item?.userId })
                                          e.stopPropagation()
                                        }} className='notif-icon delete'
                                      > <FontAwesomeIcon size='25' icon={faTrash} />
                                      </div>
                                    </div>
                                  </div>
                                  )
                                : (!notificationLoading &&
                                  <div className='d-flex align-items-center notification-content py-4 text-center justify-content-center  flex-column'>
                                    <p>{t('noNotification')}</p>
                                    <img className='no-notification' src='/icon/no-notification.png' alt='no-notification' />
                                  </div>
                                  )
                            }
                          </>
                          {
                            notificationLoading && (
                              <div className='d-flex align-items-center justify-content-center py-4'>
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='lg'
                                  role='status'
                                  aria-hidden='true'
                                />
                              </div>
                            )
                          }

                        </div>

                      </div>
                      {
                            (updateLoading || deleteLoading) &&
                              <div className='d-flex loading align-items-center justify-content-center py-4'>
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='lg'
                                  role='status'
                                  aria-hidden='true'
                                />
                              </div>
                          }
                    </InfiniteScrollComponent>
                  </Modal.Body>
                </Modal>

              </div>

              {/* Tenant urls dropdown */}
              {tenantsList && tenantsList.rows && tenantDetails?.name &&
                <Dropdown
                  className=' d-inline mx-2'
                >
                  <Dropdown.Toggle id='dropdown-autoclose-outside flex-end' variant='white'>
                    <button
                      size='sm'
                      className={`tenant-circle-button ${tenantDetails?.isActive ? 'active-button' : 'inactive-button'}`}
                    />&nbsp;{`${tenantDetails?.name} (${tenantDetails?.domain})`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='user-dropdown tenant-dropdown'>
                    {tenantsList.rows.map(({ name, tenantId, domain, isActive }) => {
                      return (
                        <Dropdown.Item
                          key={name}
                          onClick={(e) => {
                            tenantChangeHandler(tenantId)
                            navigate(0)
                          }}
                        >
                          <button
                            size='sm'
                            className={`tenant-circle-button ${isActive ? 'active-button' : 'inactive-button'}`}
                          />&nbsp;{name}({domain})
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>}
            </div>
          </Col>
        </Row>
      </div>

    </>
  )
}

export default Header
