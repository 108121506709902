import { createSlice } from '@reduxjs/toolkit'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    documentLabels: null,
    loyaltyLevel: null,
    SABanners: null,
    allNotificationList: [],
    allReadRecieptList: [],
    userCreateLoading: false,
    markUnderInspectionLoading: false,
    userInpectedData: [],
    templateList: {},
    seasonList: {},
    createLoading: false,
    ranks: [],
    ranksLoading: false,
    seasonDetail: {},
    templateDetail: {},
    allTemplateList: {},
    deleteSeasonLoading: false,
    updateLoading: false,
    deleteLoading: false,
    rollingList: {},
    rollingListLoading: false,
    rollingCrudLoading: false,
    rollingReportList: {},
    eventsList: {},
    eventList: {},
    getSingleNotification: {},
    withdrawalTransaction: {},
    loadingMigrate: false
  },
  reducers: {
    updateAnonymityStatusStart: (state, action) => ({
      ...state,
      loading: true
    }),
    updateAnonymityStatusSuccess: (state, action) => ({
      ...state,
      loading: false
    }),
    updateAnonymityStatusFailure: (state, action) => ({
      ...state,
      loading: false
    }),
    updateCasinoHideStatusStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateCasinoHideStatusComplete: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updatetV2WithdrawalTransactionStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updatetV2WithdrawalTransactionComplete: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    getV2WithdrawalTransactionDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getV2WithdrawalTransactionDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      withdrawalTransaction: payload
    }),
    getV2WithdrawalTransactionDataFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getAllEventsStart: (state) => ({
      ...state,
      eventsListLoading: false
    }),
    getAllEventsSuccess: (state, { payload }) => ({
      ...state,
      eventsList: payload,
      eventsListLoading: false
    }),
    getAllEventsFailure: (state, { payload }) => ({
      ...state,
      eventsListLoading: false
    }),
    updateNotificationStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateNotificationSuccess: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updateNotificationFailure: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updateEventStatusStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateEventStatusSuccess: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updateEventStatusFailure: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),

    createEventStart: (state) => ({
      ...state,
      createLoading: true
    }),
    createEventSuccess: (state, { payload }) => ({
      ...state,
      createLoading: false
    }),
    createEventFailure: (state, { payload }) => ({
      ...state,
      createLoading: false
    }),
    deleteEventStart: (state) => ({
      ...state,
      deleteLoading: false
    }),
    deleteEventSuccess: (state, { payload }) => ({
      ...state,
      deleteLoading: false
    }),
    deleteEventFailure: (state, { payload }) => ({
      ...state,
      deleteLoading: false
    }),
    updateEventStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateEventSuccess: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    updateEventFailure: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),

    getRollingReportingListStart: (state) => ({
      ...state,
      rollingListLoading: true
    }),
    getRollingReportingListSuccess: (state, { payload }) => ({
      ...state,
      rollingReportList: payload,
      rollingListLoading: false
    }),
    getRollingReportingListFailure: (state, { payload }) => ({
      ...state,
      rollingListLoading: false
    }),
    getUserInspectionStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserInspectionSuccess: (state, { payload }) => ({
      ...state,
      userInpectedData: payload,
      loading: false
    }),
    getRollingListStart: (state) => ({
      ...state,
      rollingListLoading: true
    }),
    getRollingListSuccess: (state, { payload }) => ({
      ...state,
      rollingList: payload,
      rollingListLoading: false
    }),
    getRollingListFailure: (state, { payload }) => ({
      ...state,
      rollingList: {},
      rollingListLoading: false
    }),
    deleteRollingStart: (state) => ({
      ...state,
      rollingCrudLoading: true
    }),
    deleteRollingComplete: (state) => ({
      ...state,
      rollingCrudLoading: false
    }),
    updateRollingStatusStart: (state) => ({
      ...state,
      rollingCrudLoading: true
    }),
    updateRollingStatusComplete: (state) => ({
      ...state,
      rollingCrudLoading: false
    }),
    createAndUpdateRollingStart: (state) => ({
      ...state,
      rollingCrudLoading: true
    }),
    createAndUpdateRollingComplete: (state) => ({
      ...state,
      rollingCrudLoading: false
    }),
    deleteSeasonStart: (state) => ({
      ...state,
      deleteSeasonLoading: true
    }),
    deleteSeasonComplete: (state) => ({
      ...state,
      deleteSeasonLoading: false
    }),
    deleteTemplateStart: (state) => ({
      ...state,
      deleteLoading: true
    }),
    deleteTemplateComplete: (state) => ({
      ...state,
      deleteLoading: false
    }),
    getUserInspectionFailure: (state) => ({
      ...state,
      loading: false
    }),
    getRanksStart: (state) => ({
      ...state,
      ranksLoading: true
    }),
    getRanksSuccess: (state, { payload }) => ({
      ...state,
      ranks: payload,
      ranksLoading: false
    }),
    getRanksFailure: (state) => ({
      ...state,
      ranksLoading: false
    }),
    getTemplateListStart: (state) => ({
      ...state,
      loading: true
    }),
    getTemplateListSuccess: (state, { payload }) => ({
      ...state,
      templateList: payload,
      loading: false
    }),
    getTemplateListFailure: (state) => ({
      ...state,
      templateList: {},
      loading: false
    }),
    getAllTemplateListStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTemplateListSuccess: (state, { payload }) => ({
      ...state,
      allTemplateList: payload,
      loading: false
    }),
    getAllTemplateListFailure: (state) => ({
      ...state,
      allTemplateList: {},
      loading: false
    }),
    getSeasonListStart: (state) => ({
      ...state,
      loading: true
    }),
    getSeasonListSuccess: (state, { payload }) => ({
      ...state,
      seasonList: payload,
      loading: false
    }),
    getSeasonListFailure: (state) => ({
      ...state,
      seasonList: {},
      loading: false
    }),
    getSeasonDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getSeasonDetailSuccess: (state, { payload }) => ({
      ...state,
      seasonDetail: payload,
      loading: false
    }),
    getSeasonDetailFailure: (state) => ({
      ...state,
      seasonDetail: {},
      loading: false
    }),
    getTemplateDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getTemplateDetailSuccess: (state, { payload }) => ({
      ...state,
      templateDetail: payload,
      loading: false
    }),
    getTemplateDetailFailure: (state) => ({
      ...state,
      templateDetail: {},
      loading: false
    }),
    createSeasonListStart: (state) => ({
      ...state,
      createLoading: true
    }),
    createSeasonListComplete: (state) => ({
      ...state,
      createLoading: false
    }),
    updateSeasonListStart: (state) => ({
      ...state,
      createLoading: true
    }),
    updateSeasonListComplete: (state) => ({
      ...state,
      createLoading: false
    }),
    updateSeasonStatusStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateSeasonStatusComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    createTemplateListStart: (state) => ({
      ...state,
      createLoading: true
    }),
    createTemplateListComplete: (state) => ({
      ...state,
      createLoading: false
    }),
    updateTemplateListStart: (state) => ({
      ...state,
      createLoading: true
    }),
    updateTemplateListComplete: (state) => ({
      ...state,
      createLoading: false
    }),

    updateNotificationStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateNotificationStatusSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateNotificationStatusFaliure: (state) => ({
      ...state,
      loading: false
    }),
    createNotificationStart: (state) => ({
      ...state,
      loading: true
    }),
    createNotificationSuccess: (state) => ({
      ...state,
      loading: false
    }),
    createNotificationFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllReadRecieptStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllReadRecieptSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allReadRecieptList: payload
    }),
    getAllReadRecieptFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllNotificationStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllNotificationSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allNotificationList: payload
    }),
    getAllNotificationFailure: (state) => ({
      ...state,
      loading: false
    }),
    deleteBannerSuperAdminStart: (state) => ({
      ...state,
      deleteLoading: true
    }),
    deleteBannerSuperAdminSuccess: (state) => ({
      ...state,
      deleteLoading: false
    }),
    deleteBannerSuperAdminFailure: (state) => ({
      ...state,
      loading: false
    }),
    getDocumentLabelStart: (state) => ({
      ...state,
      loading: true
    }),
    getDocumentLabelSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      documentLabels: payload
    }),
    getDocumentLabelFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateDocumentLabelStart: (state) => ({
      ...state,
      loading: true
    }),
    updateDocumentLabelComplete: (state) => ({
      ...state,
      loading: false
    }),
    createDocumentLabelStart: (state) => ({
      ...state,
      loading: true
    }),
    createDocumentLabelComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateDocumentStart: (state) => ({
      ...state,
      loading: true
    }),
    updateDocumentComplete: (state) => ({
      ...state,
      loading: false
    }),
    getloyaltyLevelStart: (state) => ({
      ...state,
      loading: true
    }),
    getloyaltyLevelSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      loyaltyLevel: payload
    }),
    getloyaltyLevelFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateloyaltyLevelStart: (state) => ({
      ...state,
      loading: true
    }),
    updateloyaltyLevelComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllSABannersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllSABannersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      SABanners: payload
    }),
    getAllSABannersFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateSABannerStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    updateSABannerComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    uploadSABannerStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    uploadSABannerComplete: (state) => ({
      ...state,
      updateLoading: false
    }),
    updateSAUserStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSAUserStatusCompleted: (state, { payload }) => ({
      ...state,
      loading: false,
      SABanners: payload
    }),
    updateSAUserStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    userCreateStart: (state) => ({
      ...state,
      userCreateLoading: true
    }),
    userCreateComplete: (state) => ({
      ...state,
      userCreateLoading: false
    }),
    markUnderInspectionStart: (state) => ({
      ...state,
      markUnderInspectionLoading: true
    }),
    markUnderInspectionComplete: (state) => ({
      ...state,
      markUnderInspectionLoading: false
    }),
    getspecificNotificationStart: (state) => ({
      ...state,
      loading: true
    }),
    getspecificNotificationSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      getSingleNotification: payload
    }),
    getspecificNotificationFailure: (state) => ({
      ...state,
      loading: false,
      getSingleNotification: {}
    }),
    migratePlayerV2Start: (state) => ({
      ...state,
      loadingMigrate: true
    }),
    migratePlayerV2Success: (state, { payload }) => ({
      ...state,
      loadingMigrate: false,
      superadminUpdatePasswordMessage: payload
    }),
    migratePlayerV2Failure: (state, { payload }) => ({
      ...state,
      loadingMigrate: false,
      error: payload
    })

  }
})

export default settingsSlice.reducer

export const {
  getspecificNotificationStart,
  getspecificNotificationSuccess,
  getspecificNotificationFailure,
  getAllEventsStart,
  getAllEventsFailure,
  getAllEventsSuccess,
  createEventStart,
  createEventSuccess,
  createEventFailure,
  updateNotificationStart,
  updateNotificationSuccess,
  updateNotificationFailure,
  updateEventStatusStart,
  updateEventStatusSuccess,
  updateEventStatusFailure,
  updateEventStart,
  updateEventFailure,
  updateEventSuccess,
  deleteEventStart,
  deleteEventFailure,
  deleteEventSuccess,

  getRollingReportingListStart,
  getRollingReportingListFailure,
  getRollingReportingListSuccess,
  updateRollingReportingDetailStart,
  getUserInspectionStart,
  getUserInspectionSuccess,
  getUserInspectionFailure,
  updateNotificationStatusStart,
  updateNotificationStatusSuccess,
  updateNotificationStatusFaliure,
  createNotificationStart,
  createNotificationSuccess,
  createNotificationFailure,
  getDocumentLabelStart,
  getDocumentLabelSuccess,
  getDocumentLabelFailure,
  updateDocumentLabelStart,
  updateDocumentLabelComplete,
  createDocumentLabelStart,
  createDocumentLabelComplete,
  updateDocumentStart,
  updateDocumentComplete,
  getloyaltyLevelStart,
  getloyaltyLevelSuccess,
  getloyaltyLevelFailure,
  updateloyaltyLevelStart,
  updateloyaltyLevelComplete,
  getAllSABannersStart,
  getAllSABannersSuccess,
  getAllSABannersFailure,
  uploadSABannerStart,
  uploadSABannerComplete,
  updateSABannerStart,
  updateSABannerComplete,
  updateSAUserStatusStart,
  updateSAUserStatusCompleted,
  updateSAUserStatusFailure,
  deleteBannerSuperAdminStart,
  deleteBannerSuperAdminSuccess,
  deleteBannerSuperAdminFailure,
  getAllNotificationStart,
  getAllNotificationSuccess,
  getAllNotificationFailure,
  getAllReadRecieptStart,
  getAllReadRecieptSuccess,
  getAllReadRecieptFailure,
  userCreateStart,
  userCreateComplete,
  markUnderInspectionStart,
  markUnderInspectionComplete,
  getTemplateListStart,
  getTemplateListSuccess,
  getTemplateListFailure,
  getSeasonListStart,
  getSeasonListSuccess,
  getSeasonListFailure,
  createSeasonListStart,
  createSeasonListComplete,
  createTemplateListStart,
  createTemplateListComplete,
  getRanksStart,
  getRanksSuccess,
  getRanksFailure,
  getSeasonDetailStart,
  getSeasonDetailSuccess,
  getSeasonDetailFailure,
  getTemplateDetailStart,
  getTemplateDetailSuccess,
  getTemplateDetailFailure,
  getAllTemplateListStart,
  getAllTemplateListSuccess,
  getAllTemplateListFailure,
  deleteSeasonStart,
  deleteSeasonComplete,
  updateTemplateListStart,
  updateTemplateListComplete,
  updateSeasonListStart,
  updateSeasonListComplete,
  updateSeasonStatusStart,
  updateSeasonStatusComplete,
  deleteTemplateStart,
  deleteTemplateComplete,
  getRollingListStart,
  getRollingListSuccess,
  getRollingListFailure,
  deleteRollingStart,
  deleteRollingComplete,
  updateRollingStatusStart,
  updateRollingStatusComplete,
  createAndUpdateRollingStart,
  createAndUpdateRollingComplete,
  updateCasinoHideStatusStart,
  updateCasinoHideStatusComplete,
  updatetV2WithdrawalTransactionStart,
  updatetV2WithdrawalTransactionComplete,
  getV2WithdrawalTransactionDataStart,
  getV2WithdrawalTransactionDataSuccess,
  getV2WithdrawalTransactionDataFailure,
  updateAnonymityStatusStart,
  updateAnonymityStatusSuccess,
  updateAnonymityStatusFailure,
  migratePlayerV2Start,
  migratePlayerV2Success,
  migratePlayerV2Failure
} = settingsSlice.actions
