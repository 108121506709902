
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createEventStart } from '../../../../store/redux-slices/superAdminSettings'
import { getItem } from '../../../../utils/storageUtils'

const useCreateEvent = ({ eventData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { createLoading } = useSelector(state => state.superAdminSettings)
  const { languages } = useSelector(state => state.languages)
  const currentLang = getItem('language')?.toLowerCase()
  const handleCreateAndUpdateRolling = (values, handleClose, setRefetch, formActions) => {
    const formData = {
      ...values,
      validFrom: new Date(values?.validFrom).toISOString(),
      validTo: new Date(values?.validTo).toISOString()
    }
    const updateData = {}
    if (eventData) {
      updateData.competitionId = eventData?.competitionId
      updateData.eventName = formData?.eventName
      updateData.eventDescriptions = formData?.eventDescriptions
      updateData.desktopFile = formData?.desktopFile
      updateData.mobileFile = formData?.mobileFile
      updateData.imageText = formData?.imageText
      updateData.imageDescription = formData?.imageDescription
      updateData.languageCode = formData?.languageCode
      dispatch(createEventStart({
        formData: updateData,
        isUpdate: !!eventData,
        handleClose: () => {
          handleClose()
          setRefetch(prev => !prev)
        }
      }))
    } else {
      dispatch(createEventStart({
        formData: formData,
        isUpdate: !!eventData,
        handleClose: () => {
          handleClose()
          setRefetch(prev => !prev)
        }
      }))
    }
  }

  return {
    clientsFilterData,
    languages,
    t,
    currentLang,
    handleCreateAndUpdateRolling,
    createLoading
  }
}

export default useCreateEvent
