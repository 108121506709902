import React from 'react'
import { casinoReportingTable } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import Trigger from '../OverlayTrigger'
import { formatNumber } from '../../utils/storageUtils'
const CasinoReportingTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t,
  isV2
}) => {
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {casinoReportingTable(isV2).map((h, idx) => (
              <th key={h.label}>
                {t(h.label)} &nbsp;
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
    !loading && tableData?.rows &&
    tableData?.rows?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{(page - 1) * limit + index + 1}</td>
          {isV2
            ? (
              <>
                <td>{item?.username || t('na')}</td>
                <td>{item?.gameMaster?.name || t('na')}</td>
                <td>{item?.actionType || t('na')}</td>
                <td>{item?.casinoTransactionType || t('na')}</td>
                <td>{item?.casinoTransactionId || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameCategory?.categoryName?.EN || t('na')}</td>
                <td>{item?.gameProvider?.providerName || t('na')}</td>
                <td>{formatNumber(item?.amount || 0)} ₩</td>
                <td>{formatNumber(item?.seasonalBenefit || 0)} ₩</td>
                <td>{item?.createdAt ? moment(item?.createdAt).format('MM-DD-YYYY') : t('na')}</td>
                <td>{item?.createdAt ? moment(item?.createdAt).format('HH:mm:ss') : t('na')}</td>
              </>
              )
            : (
              <>
                <td>{item?.casinoTransactions?.username || t('na')}</td>
                <td>{item?.gameMaster?.name || t('na')}</td>
                {/* <td>{item?.actionType || t('na')}</td>
                <td>{item?.casinoTransactionId || t('na')}</td> */}
                <td>{item?.gameMaster?.MasterGameCategory?.categoryName?.EN || t('na')}</td>
                <td>{item?.gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || t('na')}</td>
                <td>{item?.gameProvider?.providerName || t('na')}</td>
                <td>{formatNumber(item?.amount + item?.nonCashAmount + item?.bonus) + ' ₩' || t('na')} ₩</td>
                <td>
                  <Trigger message={item?.superadminNotes}>
                    <div>{item?.superadminNotes ?? '-'}</div>
                  </Trigger>
                </td>
                <td>
                  <Trigger message={item?.seniorNotes}>
                    <div>{item?.seniorNotes ?? '-'}</div>
                  </Trigger>
                </td>
                <td>{item?.actionId || t('na')}</td>
                <td>{moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss') || t('na')}</td>
              </>
              )}
        </tr>
      )
    })
  }
          {!loading && tableData?.count === 0 && (
            <tr>
              <td colSpan={isV2 ? 13 : 12} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>

      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
    </div>
  )
}
export default CasinoReportingTable
