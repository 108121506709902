
/* eslint-disable */
import * as Yup from 'yup'
import { t } from 'i18next'
const imageDimensionCheck = Yup.addMethod(Yup.mixed, 'imageDimensionCheck', function (message, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight) {
  return this.test('image-width-height-check', message, async function (value) {
    const { path, createError } = this
    if (!value) {
      return true
    }

    const imgDimensions = await imageWidthAndHeight(value)

    if (imgDimensions.width < minRequiredWidth || imgDimensions.width > maxRequiredWidth) {
      return createError({
        path,
        message: `${t('imageWidthBetween')}` + ' ' + `${minRequiredWidth}px - ${maxRequiredWidth}px!`
      })
    }

    if (imgDimensions.height < minRequiredHeight || imgDimensions.height > maxRequiredHeight) {
      return createError({
        path,
        message: `${t('imageHeightBetween')}` + ' ' + `${minRequiredHeight}px - ${maxRequiredHeight}px!`
      })
    }

    return true
  })
})
export const uploadBannerSchema = ({ type, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight }, t) => Yup.object().shape({
  tenantId:
    Yup
      .string()
      .required(t('ownerIdRequired')),
  bannerType:
    Yup
      .string()
      .required(t('bannerTypeRequired')),
  bannerScreenType: Yup.string().required(t('bannerScreenTypeRequired')),
  bannerSubType: Yup.string().when('bannerType', {
    is: (type) => type === 'rackbackBonusBanner' || type === 'periodicPaybackBonusBanner',
    then: Yup.string().required(t('bannerSubTypeRequired')),
    otherwise: Yup.string(), // No additional validation for other cases
  }),
  description: Yup.string().when(['bannerSubType', 'bannerScreenType'], {
    is: (bannerSubType, bannerScreenType) =>
      bannerSubType === 'subBannerImage' && bannerScreenType === 'web',
    then: Yup.string().required(t('descriptionIsRequired')),
    otherwise: Yup.string(), // No additional validation for other cases
  }),
  file: type === 'Create'
    ? Yup
      .mixed()
      .required(t('bannerTypeRequired'))
      .imageDimensionCheck('Banner Required', minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight)
      .test('FILE_FORMAT', t('imageFormatRequired'),
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
          .includes(value.type)))
    :
    Yup.mixed()
      .test('fileFormat', t('imageFormatRequired'), value => {

        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(value.type)
      })
      .test('fileSize', t('sizeTooLarge'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return value.size <= 10485760 // 10MB limit
      })
      .imageDimensionCheck('Banner Required', minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight)

})
const imageWidthAndHeight = (provideFile) => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: null, height: null }

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.readAsDataURL(provideFile)
    reader.onload = function () {
      const img = new Image()
      img.src = reader.result

      img.onload = function () {
        imgDimensions.width = img.width
        imgDimensions.height = img.height

        resolve(imgDimensions)
      }
    }
  })
}

// export const uploadBannerSchema = ({ type, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight }, t) => {
//   return Yup.object().shape({
//     tenantId: Yup
//       .string()
//       .required(t('ownerIdRequired')),
//     bannerType: Yup
//       .string()
//       .required(t('bannerTypeRequired')),

//     file: type === 'Create'
//       ? Yup
//           .mixed()
//           .required(t('bannerRequired'))
//       : Yup
//         .mixed()
//         .required(t('bannerRequired'))
//   })
// }
