import React from 'react'
import CasinoReportingTable from '../../../components/ReportingTable/CasinoReportingTable'
import useCasinoReporting from './hooks/useCasinoReporting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import ClientFilter from '../../../components/ClientFilter'
import Trigger from '../../../components/OverlayTrigger'
import { Button, Col, Form } from 'react-bootstrap'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { transactionTypeConstants } from './constants.js'
import Select from 'react-select'

const CasinoReporting = ({ isV2 = false }) => {
  const {
    formData,
    setFormData,
    casinoReportingData,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    selectedClient,
    setSelectedClient,
    state,
    setState,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    handleCsvDownloadCasino,
    initialDate,
    inputRef,
    loading,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    transactionTypes,
    setTransactionTypes
  } = useCasinoReporting(isV2)
  return (
    <div className='p-2'>
      <Col xs='auto'>
        <h3>{t('casinoReporting')}</h3>
      </Col>
      <div className='d-flex flex-wrap p-2'>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('betResult')}
          </Form.Label>
          <Form.Select
            value={formData?.actionType}
            onChange={(e) => {
              setFormData({ ...formData, actionType: e.target.value })
              setPage(1)
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['BET', 'WIN', 'LOST'].map((requestAction) => (
              <option key={requestAction} value={requestAction}>
                {t(requestAction)}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('transactionType')}
          </Form.Label>
          <Form.Select
            value={formData?.transactionType}
            onChange={(e) => {
              setFormData({ ...formData, transactionType: e.target.value })
              setPage(1)
            }}
            size='sm'
          >
            <option value=''>{t('all')}</option>

            {['CREDIT', 'DEBIT'].map((transactionRequest) => (
              <option key={transactionRequest} value={transactionRequest}>
                {t(transactionRequest)}
              </option>
            ))}
          </Form.Select>
        </div>
        <Col className='d-flex align-items-center p-0'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col className='d-flex align-items-center p-1'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>

        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('category')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, categoryId: e.target.value })
              setPage(1)
            }}
            value={formData.categoryId}
          >
            <option value=''>{t('selectCategory')}</option>
            {gameCategory?.rows?.map(({ name, mastergamecategoryid }) => (
              <option key={mastergamecategoryid} value={mastergamecategoryid}>
                {t(name.EN)}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, subCategoryId: e.target.value })
              setPage(1)
            }}
            value={formData.subCategoryId}
          >
            <option value=''>{t('selectSubCategory')}</option>
            {gameSubCategory?.rows?.map(({ name, masterGameSubCategoryId }) => (
              <option
                key={masterGameSubCategoryId}
                value={masterGameSubCategoryId}
              >
                {t(name.EN)}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('provider')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setFormData({ ...formData, providerId: e.target.value })
              setPage(1)
            }}
            value={formData.providerId}
          >
            <option value=''>{t('selectProvider')}</option>
            {casinoProvidersData?.rows?.map(
              ({ name, masterCasinoProviderId }) => (
                <option
                  key={masterCasinoProviderId}
                  value={masterCasinoProviderId}
                >
                  {t(name)}
                </option>
              )
            )}
          </Form.Select>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('search')}
          </Form.Label>
          &nbsp;
          <Form.Control
            ref={inputRef}
            name='search'
            type='search'
            placeholder={t('searchName')}
            size='sm'
            className='w-90 mx-auto'
            style={{
              minWidth: '180px',
              maxWidth: '230px',
              width: 'auto'
            }}
            onChange={(e) => {
              debounceFn(e)
              setPage(1)
            }}
          />
        </div>

        <Col className='d-flex align-items-center p-2'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('transactionType')}
          </Form.Label>
          <Select
            isMulti
            options={transactionTypeConstants}
            onChange={(selectedOptions, e) => {
              setTransactionTypes(selectedOptions)
            }}
            value={transactionTypes}
            className='basic-multi-select'
            classNamePrefix='select'
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
                marginRight: '10px',
                fontSize: '13px'
              })
            }}
          />
        </Col>

        <div className='d-flex align-items-center p-2'>
          <ClientFilter
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </div>
        <div className='d-flex align-items-center p-2'>
          <AffiliateAgentFilter
            selectedClient={selectedClient}
            agent={agent}
            setAgent={setAgent}
            affiliate={affiliate}
            setAffiliate={setAffiliate}
          />
        </div>
        <div className='d-flex align-items-center p-2'>
          <Trigger message={t('downloadAsCsv')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={casinoReportingData?.count === 0}
              onClick={() => {
                handleCsvDownloadCasino()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </div>
        <div className='d-flex align-items-center p-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setFormData(initalWalletState)
                setSelectedClient('')
                setState(initialDate)
                setPage(1)
                inputRef.current.value = ''
                setAgent('')
                setAffiliate('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </div>

      </div>

      <CasinoReportingTable
        tableData={casinoReportingData}
        setLimit={setLimit}
        limit={limit}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        loading={loading}
        t={t}
        isV2={isV2}
      />
    </div>
  )
}
export default CasinoReporting
