import React from 'react'
import useBonusTransactions from '../hooks/useBonusTransactions'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import BonusTransactionsList from '../../../../components/BonusTransactionsList'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileDownload,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import { bonusType as BONUSTYPES, SPORTS_BONUSES } from '../../../Super-Admin/Bonus/components/CreateBonus/constants'
import AffiliateAgentFilter from '../../../../common/AffiliateAgentFilter'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'

const BonusTransactions = ({
  isReporting,
  isAffiliate,
  isAgent,
  selectedTab,
  isSportsBook,
  userData = {}
}) => {
  const {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    handleCsvDownload,
    initialDate,
    setBonusType,
    bonusType,
    bonusTransactions,
    bonusTransactionsLoading,
    handleDebouncSearch,
    inputRef,
    setSearch,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  } = useBonusTransactions({ isReporting, isAffiliate, isAgent, selectedTab, isSportsBook, userData })

  return (
    <>
      {isReporting && (
        <Row>
          <Col xs='auto'>
            <Col xs='auto' className='mb-3'>
              <h3>{isSportsBook ? t('sportsBonusTransactions') : t('casinoBonusTransactions')}</h3>
            </Col>
          </Col>
        </Row>
      )}

      <Row className={`search-section pb-2 gap-2  ${isReporting ? 'mt-1' : 'mt-5'}`}>
        {
        (!selectedTab && !isSportsBook) &&
        (
          <Col xs='auto' className='d-flex '>
            <AffiliateAgentFilter
              agent={agent}
              setAgent={setAgent}
              affiliate={affiliate}
              setAffiliate={setAffiliate}
            />
          </Col>
        )
       }
        <Col xs='auto' className='d-flex '>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('bonusType')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setBonusType(e.target.value)
              setPage(1)
            }}
            value={bonusType}
          >
            <option value=''>{t('selectBonus')}</option>
            {(isSportsBook ? SPORTS_BONUSES : BONUSTYPES)?.map(
              (item) => (
                <option key={item.value} value={item.value}>
                  {t(item.label)}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex '>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>

          <Form.Control
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              fontSize: '13px'
            }}
            ref={inputRef}
            onChange={handleDebouncSearch}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('startDate')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex'>
          <InputDatePicker
            isFilter
            label={t('endDate')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
              setPage(1)
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center gap-2 '>
          <Trigger message={t('downloadAsCSV')}>
            <Button
              variant='outline-success'
              size='sm'
              disabled={bonusTransactions?.count === 0}
              onClick={() => {
                handleCsvDownload()
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setLimit(15)
                setPage(1)
                setState(initialDate)
                setBonusType('')
                setSearch('')
                setAffiliate('')
                setAgent('')
                inputRef.current.value = ''
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <BonusTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={bonusTransactions}
        isTenant={false}
        userDetail
        loading={bonusTransactionsLoading}
        t={t}
      />
    </>
  )
}

export default BonusTransactions
