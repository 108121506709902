import React from 'react'
import {
  Row,
  Col,
  Table,
  Form,
  Button,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import {
  faCheckSquare,
  faEdit,
  faRedoAlt,
  // faTrash,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../../components/OverlayTrigger'
import useCasinoGamesListing from './hooks/useCasinoGamesListing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationModal, {
  DeleteConfirmationModal
} from '../../../components/ConfirmationModal'
import EditGames from './components/EditGames'
import { tableHeaders } from './constants'
import ProviderFilter from '../../../components/ProviderFilter'
import useCheckPermission from '../../../utils/checkPermission'

const CasinoGames = () => {
  const {
    limit,
    page,
    loading,
    setLimit,
    setPage,
    totalPages,
    games,
    casinoCategoryId,
    search,
    setSearch,
    setCasinoCategoryId,
    subCategories,
    show,
    setShow,
    handleShow,
    handleYes,
    active,
    handleShowModal,
    showModal,
    type,
    handleClose,
    gameData,
    masterCasinoGameId,
    setDeleteModalShow,
    deleteModalShow,
    handleDeleteYes,
    statusFilter,
    setStatusFilter,
    selectedProvider,
    setSelectedProvider,
    getProviderName,
    name,
    getImageByGameCategory,
    t,
    code
  } = useCasinoGamesListing()
  const { isHidden } = useCheckPermission()
  return (
    <div className='p-3'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3>{t('casinoGames')}</h3>
          </Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              isTenantRoute
              t={t}
            />
            <Form.Label
              column='sm'
              style={{
                marginBottom: '0',
                marginRight: '15px',
                minWidth: 'fit-content'
              }}
            >
              {t('subCategory')}
            </Form.Label>
            <Form.Select
              style={{
                marginBottom: '0',
                marginRight: '15px',
                maxWidth: '230px',
                width: 'auto'
              }}
              value={casinoCategoryId}
              size='sm'
              onChange={(e) => setCasinoCategoryId(e.target.value)}
            >
              <option value=''>{t('all')}</option>

              {subCategories &&
                subCategories?.rows?.map((c) => (
                  <option
                    key={c?.masterGameSubCategoryId}
                    value={c?.masterGameSubCategoryId}
                  >
                    {c?.name?.EN}
                  </option>
                ))}
            </Form.Select>
            <Form.Label
              column='sm'
              style={{ marginBottom: '0', marginRight: '15px' }}
            >
              {t('status')}
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setStatusFilter(e.target.value)
              }}
              value={statusFilter}
              size='sm'
              style={{ maxWidth: '230px', marginRight: '15px', width: 'auto' }}
            >
              <option value=''>{t('all')}</option>
              <option value='true'>{t('active')}</option>
              <option value='false'>{t('inactive')}</option>
            </Form.Select>
            <Form.Label
              column='sm'
              style={{ marginBottom: '0', marginRight: '15px' }}
            >
              {t('search')}
            </Form.Label>
            &nbsp;
            <Form.Control
              name='search'
              type='search'
              placeholder={t('search')}
              value={search}
              size='sm'
              className='w-90 mx-auto'
              style={{
                minWidth: '180px',
                maxWidth: '230px',
                width: 'auto'
              }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <Col className='d-flex p-3 float-end'>
              <Trigger message={t('resetFilters')}>
                <Button
                  variant='outline-success'
                  className='py-1'
                  size='sm'
                  onClick={() => {
                    setSearch('')
                    setSelectedProvider('')
                    setCasinoCategoryId('')
                    setStatusFilter('')
                    setLimit(15)
                    setPage(1)
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </Col>
          </div>
        </Col>
      </Row>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={idx}>{t(h.label)} &nbsp;</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading &&
            games &&
            games?.rows?.map(
              (
                {
                  name,
                  isActive,
                  thumbnailUrl,
                  tenantGameSubCategoryId,
                  returnToPlayer,
                  TenantGameSubCategory,
                  masterCasinoProviderId,
                  masterCasinoGameId,
                  identifier,
                  translations,
                  TenantGameCategory
                },
                index
              ) => {
                return (
                  <tr key={masterCasinoGameId}>
                    <td>{index + 1 + limit * (page - 1)}</td>
                    <td>
                      <Trigger message={name}>
                        <span
                          style={{
                            width: '130px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {translations?.[code] ?? name}
                        </span>
                      </Trigger>
                    </td>
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inactive') || 'NA'}
                    </td>

                    <td>
                      {
                                thumbnailUrl
                                  ? <img
                                      src={thumbnailUrl} style={{
                                        height: '50px',
                                        width: '60px'
                                      }} onClick={() => window.open(thumbnailUrl)}
                                    />
                                  : <img
                                      src={getImageByGameCategory(TenantGameCategory) || ''}
                                      style={{
                                        height: '50px',
                                        width: '60px'
                                      }} onClick={() => window.open(thumbnailUrl)}
                                    />

                               }
                    </td>
                    <td className='text-capitalize'>
                      {getProviderName(masterCasinoProviderId)}
                    </td>
                    <td>{returnToPlayer || 'N/A'}</td>
                    <td>{identifier}</td>

                    <td>{TenantGameSubCategory}</td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={t('edit')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='warning'
                            hidden={isHidden({
                              module: { key: 'CasinoManagement', value: 'U' }
                            })}
                            onClick={() => {
                              handleShowModal(
                                t('edit'),
                                games?.rows?.[index],
                                masterCasinoGameId
                              )
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>

                        {!isActive
                          ? (
                            <Trigger message={t('setGameStatusActive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                hidden={isHidden({
                                  module: { key: 'CasinoManagement', value: 'T' }
                                })}
                                onClick={() =>
                                  handleShow(
                                    identifier,
                                    tenantGameSubCategoryId,
                                    isActive,
                                    name,
                                    masterCasinoProviderId
                                  )}
                              >
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </Button>
                            </Trigger>
                            )
                          : (
                            <Trigger message={t('setGameStatusInactive')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='danger'
                                hidden={isHidden({
                                  module: { key: 'CasinoManagement', value: 'T' }
                                })}
                                onClick={() =>
                                  handleShow(
                                    identifier,
                                    tenantGameSubCategoryId,
                                    isActive,
                                    name,
                                    masterCasinoProviderId
                                  )}
                              >
                                <FontAwesomeIcon icon={faWindowClose} />
                              </Button>
                            </Trigger>
                            )}
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )}
          {games?.count === 0 && !loading && (
            <tr>
              <td colSpan={5} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {games?.count !== 0 && !loading && (
        <PaginationComponent
          page={games?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={games?.count}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={!active}
          name={name}
        />
      )}

      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          name={name}
        />
      )}

      {masterCasinoGameId !== null &&
        games?.masterCasinoGameId === masterCasinoGameId && (
          <EditGames
            handleClose={handleClose}
            show={showModal}
            gameData={gameData}
            type={type}
            subCategories={subCategories}
            limit={limit}
            pageNo={page}
            casinoCategoryId={casinoCategoryId}
            statusFilter={statusFilter}
            providerId={selectedProvider}
            t={t}
          />
      )}

      {/* {showGameIds &&
        <GameIdsModal
          setShow={setShowGameIds}
          show={showGameIds}
          isTenant
        />} */}
    </div>
  )
}

export default CasinoGames
