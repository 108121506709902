import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { tableHeaders } from '../../pages/Super-Admin/CasinoTransactions/constants'
import Trigger from '../OverlayTrigger'
import './CasinoTransaction.scss'
import moment from 'moment'
import NoteModal from '../NoteModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { formatNumber } from '../../../src/utils/storageUtils'
const CasinoTransactionsList = ({
  t,
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  isTenant,
  loading = false,
  userDetail = false,
  over,
  setOver,
  bannedGames,
  showBannedGameAmount,
  userData,
  addNoteModal,
  setAddNoteModal,
  individualUserData,
  setIndividualUserData,
  handleSubmit,
  isAffiliate,
  isAgent
}) => {
  const isV2 = userData?.isV2User
  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(userData?.isV2User, userDetail, isAffiliate, isAgent).map((c) => (
              <th key={c}>
                {t(c)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions && !loading &&
    transactions?.rows?.map(
      ({
        casinoTransactionId,
        User,
        createdAt,
        actionType,
        amountType,
        currencyCode,
        amount,
        status,
        nonCashAmount,
        gameMaster,
        gameProvider,
        Wallet,
        seniorNotes,
        superadminNotes,
        bonus,
        nonWithdrawableAmount,
        withdrawableAmount,
        promotionCash,
        seasonalBenefit,
        casinoTransactionType
      }, index) => {
        const rowNumber = (page - 1) * limit + index + 1

        return (
          <tr key={`casino-transaction-list ${casinoTransactionId}`}>
            <td>{rowNumber}</td>
            <td>{gameMaster?.name || 'NA'}</td>
            <td>{actionType}</td>
            <td>{casinoTransactionType}</td>
            <td>{casinoTransactionId}</td>
            <td>{gameMaster?.MasterGameCategory?.categoryName?.EN || 'NA'}</td>
            <td>{gameMaster?.MasterGameSubCategory?.subCategoryName?.EN || 'NA'}</td>
            <td>{gameProvider?.providerName || 'NA'}</td>
            <td>{`${formatNumber(amount)} ₩`}</td>
            <td>{`${formatNumber(seasonalBenefit?.toFixed(2))} ₩`}</td>
            <td>{createdAt ? moment(createdAt).format(isV2 ? 'MM-DD-YYYY' : 'MM-DD-YYYY hh:mm:ss') : 'NA'}</td>
            {isV2 && <td>{createdAt ? moment(createdAt).format('HH:mm:ss') : 'NA'}</td>}
            {(!isAffiliate || !isAgent) && (
              <td>
                <Trigger message={t('addNote')}>
                  <Button
                    className='m-1'
                    size='sm'
                    variant='info'
                    onClick={() => {
                      setAddNoteModal(true)
                      setIndividualUserData({ casinoTransactionId })
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Trigger>
              </td>
            )}
          </tr>
        )
      }
    )}

          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={userDetail && isV2 ? 15 : userDetail ? 14 : 11} className='text-danger text-center'>
                {t('noDataFound')}
              </td>
            </tr>
          )}
        </tbody>

      </Table>
      {
        addNoteModal
          ? <NoteModal
              addNoteModal={addNoteModal}
              setAddNoteModal={setAddNoteModal}
              individualUserData={individualUserData}
              handleSubmit={handleSubmit}
            />
          : <></>
      }
      {
        transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={transactions?.count}
          />
        )
      }
    </>
  )
}

export default CasinoTransactionsList
