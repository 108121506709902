import * as Yup from 'yup'

export const withdrawalSchema = (t) => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(t('amountRequired'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBeGreaterThanZero'))
      .max(9999999, t('amountLessThan8digit')),
    currency: Yup.string()
      .required(t('currencyRequired')) // Ensures the field is not empty
  })
}
