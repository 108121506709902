import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSuperAdminBonusTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { debounce } from 'lodash'
import { downloadFile } from '../../../../utils/fileDownloader'
import { useTranslation } from 'react-i18next'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useBonusTransactions = ({ isReporting, isSportsBook, userData = {}, isLapsed }) => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [bonusType, setBonusType] = useState('')
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [search, setSearch] = useState('')
  const { bonusTransactions, bonusTransactionsLoading } = useSelector(state => state.superAdminTransactions)
  const [isBonusTransactionReportV2, setIsBonusTransactionReportV2] = useState(userData?.isV2User ?? false)
  const [lapsedBonus, setLapsedBonus] = useState(false)
  const totalPages = Math.ceil(bonusTransactions?.count / limit)
  const handleDebouncSearch = debounce((e) => {
    setSearch(e.target.value)
    setPage(1)
  }, 1000)
  const [state, setState] = useState(initialDate)

  useEffect(() => {
    dispatch(getSuperAdminBonusTransactionsStart({
      data: {
        limit,
        pageNo: page,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || '',
        actionType: bonusType,
        userId,
        type: isLapsed ? 'AMOUNT_LAPSED' : isSportsBook ? 'SPORTBOOK' : 'CASINO',
        search,
        lapsedBonus,
        ...(selectedClient && { adminId: selectedClient || '', agentId: agent, affiliateId: affiliate }),
        isV2User: isBonusTransactionReportV2
      },
      isReporting: isReporting

    }))
  }, [limit, page, search, state, bonusType, selectedClient, agent, affiliate, isSportsBook, isBonusTransactionReportV2, lapsedBonus])

  const handleCsvDownload = () => {
    isReporting
      ? downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/report/bonus-transactions?pageNo=${page}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&actionType=${bonusType || ''}&tenantId=${selectedClient || ''}&search=${search || ''}&csvDownload=true&token=${getLoginToken()}&adminId=${selectedClient || ''}&agentId=${agent || ''}&affiliateId=${affiliate || ''}&type=${isLapsed ? 'AMOUNT_LAPSED' : isSportsBook ? 'SPORTBOOK' : 'CASINO'}&isV2User=${isBonusTransactionReportV2 || false}&lapsedBonus=${lapsedBonus || 'false'}`)
      : downloadFile(`${process.env.REACT_APP_API_URL}/api/superadmin/user/bonus-transaction?pageNo=${page}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment(state[0].endDate).toISOString() || ''}&actionType=${bonusType}&search=${search || ''}&userId=${userId}&csvDownload=true&token=${getLoginToken()}&isV2User=${isBonusTransactionReportV2 || false}&lapsedBonus=${lapsedBonus || 'false'}`)
  }
  return {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    state,
    setState,
    handleCsvDownload,
    initialDate,
    setBonusType,
    bonusType,
    bonusTransactions,
    bonusTransactionsLoading,
    selectedClient,
    setSelectedClient,
    inputRef,
    handleDebouncSearch,
    setSearch,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    isBonusTransactionReportV2,
    setIsBonusTransactionReportV2,
    setLapsedBonus,
    lapsedBonus
  }
}

export default useBonusTransactions
