import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'headerNotification',
  initialState: {
    notificationLoading: false,
    notification: {},
    updateLoading: false,
    deleteLoading: false
  },
  reducers: {
    getAllAdminNotificationStart: (state) => ({
      ...state,
      notificationLoading: true
    }),
    getAllAdminNotificationSuccess: (state, { payload }) => ({
      ...state,
      notificationLoading: false,
      notification: payload
    }),
    getAllAdminNotificationFailure: (state, { payload }) => ({
      ...state,
      notificationLoading: false,
      error: payload
    }),
    markAdminNotificationReadStart: (state) => ({
      ...state,
      updateLoading: true
    }),
    markAdminNotificationReadComplete: (state, { payload }) => ({
      ...state,
      updateLoading: false
    }),
    deleteAdminNotificationStart: (state) => ({
      ...state,
      deleteLoading: true
    }),
    deleteAdminNotificationComplete: (state, { payload }) => ({
      ...state,
      deleteLoading: false
    }),
    updateHeaderNotification: (state, { payload }) => ({
      ...state,
      notification: {
        result: [payload, ...state?.notification?.result],
        totalNotification: state?.notification?.totalNotification + 1,
        totalUnreadNotification: state?.notification?.totalUnreadNotification + 1
      }
    })

  }
})

export default notificationSlice.reducer

export const {
  getAllAdminNotificationStart,
  getAllAdminNotificationSuccess,
  getAllAdminNotificationFailure,
  markAdminNotificationReadStart,
  markAdminNotificationReadComplete,
  deleteAdminNotificationStart,
  deleteAdminNotificationComplete,
  updateHeaderNotification
} = notificationSlice.actions
