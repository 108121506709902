import { takeLatest, put, select } from 'redux-saga/effects'
import {
  getAllAdminNotification,
  markAdminNotificationRead,
  deleteAdminNotification
} from '../../utils/apiCalls'

import {
  getAllAdminNotificationStart,
  getAllAdminNotificationSuccess,
  getAllAdminNotificationFailure,
  markAdminNotificationReadStart,
  markAdminNotificationReadComplete,
  deleteAdminNotificationStart,
  deleteAdminNotificationComplete
} from '../redux-slices/headerNotification'

import { toast } from '../../components/Toast'
import { t } from 'i18next'
export default function * headerNotificationWatcher () {
  yield takeLatest(getAllAdminNotificationStart.type, getAllAdminNotificationWorker)
  yield takeLatest(markAdminNotificationReadStart.type, markAdminNotificationReadWorker)
  yield takeLatest(deleteAdminNotificationStart.type, deleteAdminNotificationWorker)
}
function * getAllAdminNotificationWorker (action) {
  try {
    const {
      formData
    } = action && action.payload
    const { data } = yield getAllAdminNotification({ data: formData })
    let tempData = {}
    if (formData?.pageNo === 1) {
      tempData = { ...data?.data }
    } else {
      const { notification } = yield select(state => state.headerNotification)
      tempData = {
        ...notification,
        result: [...notification.result, ...data?.data?.result]
      }
    }
    yield put(getAllAdminNotificationSuccess(tempData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(getAllAdminNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * markAdminNotificationReadWorker (action) {
  try {
    const {
      formData,
      onSuccess,
      isSingle
    } = action && action.payload

    const res = yield markAdminNotificationRead({ data: formData })
    if (res.status === 200) {
      onSuccess && onSuccess()
      if (!isSingle) {
        yield toast(t('notificationMarkedAllAsRead'), 'success')
      }
      yield put(markAdminNotificationReadComplete())
      if (isSingle) {
        const { notification } = yield select(state => state.headerNotification)
        const totalUnreadNotification = notification?.totalUnreadNotification
        const newResult = notification?.result?.map((item) => {
          if (item?.notificationId === formData.notificationId) {
            return { ...item, isNotificationRead: true }
          } else {
            return { ...item }
          }
        })
        const newData = { ...notification, totalUnreadNotification: totalUnreadNotification > 0 ? totalUnreadNotification - 1 : 0, result: newResult }
        yield put(getAllAdminNotificationSuccess(newData))
      }
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(markAdminNotificationReadComplete())
  }
}
function * deleteAdminNotificationWorker (action) {
  try {
    const {
      formData,
      onSuccess,
      isSingle
    } = action && action.payload
    const res = yield deleteAdminNotification({ data: formData })
    if (res.status === 200) {
      onSuccess && onSuccess()
      if (!isSingle) {
        yield toast(t('notificationDeleteAllSuccess'), 'success')
      }

      if (isSingle) {
        const { notification } = yield select(state => state.headerNotification)
        const totalNotification = notification?.totalNotification
        const newResult = notification?.result?.filter((item) => item?.notificationId !== formData.notificationId)
        const newData = { ...notification, totalNotification: totalNotification > 0 ? totalNotification - 1 : 0, result: newResult }
        yield put(getAllAdminNotificationSuccess(newData))
      }
      yield put(deleteAdminNotificationComplete())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(deleteAdminNotificationComplete())
  }
}
