import React from 'react'
import { Tabs, Tab, Row, Col, Modal, Table, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap'
import Overview from './components/Overview'
import useUserDetailsListing from './hooks/useUserDetailsListing'
import Reason from './components/Reason'
import CasinoTransactions from './components/CasinoTransactions'
import ConfirmationModal, { DuplicateUserModal, ResetPasswordEmailModal, ResetUserPasswordModal } from '../../../components/ConfirmationModal'
import TransactionBanking from './components/TransactionBanking'
import useCheckPermission from '../../../utils/checkPermission'
import TagModal from './components/TagModal'
import { formatDate } from '../../../utils/dateFormatter'
import FreeSpinBonusModal from '../../../components/FreeSpinBonusModal/FreeSpinBonusModal'
import EditUserModal from '../../Super-Admin/UserDetails/components/EditUserModal'
import Settings from './components/Settings'
import AccountTransaction from './components/AccountTransaction'
import BonusTransactions from './components/BonusTransactions'
import SportsTransactions from '../../../components/SportsTransactions'

const UserDetails = ({ isAffiliate, isAgent }) => {
  const {
    selectedTab,
    user,
    show,
    showModal,
    showReasonModal,
    showTagModal,
    tagOptions,
    selectedTag,
    limit,
    pageNo,
    duplicateUsers,
    showDuplicateModal,
    bonusDetail,
    bonusList,
    selectedBonus,
    bonusAmount,
    updateUserAffiliateLoading,
    showRemoveAffiliate,
    setBonusAmount,
    setSelectedBonus,
    issueBonusHandler,
    updateTenantUserStatus,
    setShow,
    setShowReasonModal,
    handleShow,
    handleClose,
    setSelectedTab,
    setTagOptions,
    addTag,
    setShowTagModal,
    updateTags,
    setShowModal,
    setSelectedTag,
    getDuplicateUsers,
    setShowDuplicateModal,
    setLimit,
    setPageNo,
    freespinModal,
    setFreespinModal,
    setIsInternalModal,
    isInternalModal,
    internalPlayerHandler,
    removeAffiliateHandler,
    navigate,
    editUserModal,
    setEditUserModal,
    userPasswordModal,
    setUserPasswordModal,
    handleResetUserPassword,
    setPasswordEmailModal,
    passwordEmailModal,
    userCurrentStatus,
    userStatusLoading,
    t,
    handleResetPasswordEmail
  } = useUserDetailsListing({ isAffiliate, isAgent })
  const { isHidden } = useCheckPermission()
  return (
    <>
      <div className='p-3'>
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate(-1)}>
              {t('players')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {t('username')}: &nbsp;
              {user && user?.username}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row className='px-3'>
          <Col className='d-flex'>
            <h3>{t('userID')}:&nbsp;</h3>
            <h3>
              <div className='d-flex'>{user && user?.userId}</div>
            </h3>
          </Col>
        </Row>
        <Tabs
          activeKey={selectedTab}
          onSelect={(tab) => setSelectedTab(tab)}
          className='nav-light px-3'
        >
          <Tab eventKey='overview' title={t('overview')}>
            <Row className='mt-5 px-3'>
              <Overview
                setShowReasonModal={setShowReasonModal}
                handleShow={handleShow}
                addTag={addTag}
                getDuplicateUsers={getDuplicateUsers}
                user={user}
                isHidden={isHidden}
                duplicateUsers={duplicateUsers}
                setShowModal={setShowModal}
                setFreespinModal={setFreespinModal}
                setIsInternalModal={setIsInternalModal}
                showRemoveAffiliate={showRemoveAffiliate}
                updateUserAffiliateLoading={updateUserAffiliateLoading}
                removeAffiliateHandler={removeAffiliateHandler}
                setEditModal={setEditUserModal}
                setUserPassword={setUserPasswordModal}
                setPasswordEmail={setPasswordEmailModal}
                userCurrentStatus={userCurrentStatus}
                loading={userStatusLoading}
                t={t}
                isAffiliate={isAffiliate}
                isAgent={isAgent}
              />
            </Row>
          </Tab>

          <Tab eventKey='casino-transactions' title={t('casinoBetHistory')}>
            <div className='mt-5'>
              <Row className='mt-3 d-flex flex-row-reverse text-right'>
                <CasinoTransactions
                  t={t}
                  isAffiliate={isAffiliate}
                  isAgent={isAgent}
                  user={user}
                  selectedTab={selectedTab}
                />
              </Row>
            </div>
          </Tab>

          {!isAffiliate &&
            !isAgent &&
            !isHidden({ module: { key: 'Transactions', value: 'R' } }) && (
              <Tab eventKey='transactions-banking' title={t('transactions')}>
                <div className='mt-5'>
                  <Row className='mt-3 d-flex text-right'>
                    <TransactionBanking
                      t={t}
                      selectedTab={selectedTab}
                      user={user}
                    />
                  </Row>
                </div>
              </Tab>
          )}

          <Tab eventKey='kyc' title={t('kycSettings')}>
            <div className='mt-5'>
              <Row className='mt-3 d-flex flex-row-reverse '>
                {user
                  ? (
                    <Settings
                      selectedTab={selectedTab}
                      userDetails={user}
                      t={t}
                      isAffiliate={isAffiliate}
                      isAgent={isAgent}
                    />
                    )
                  : (
                    <></>
                    )}
              </Row>
            </div>
          </Tab>

          {!isAffiliate && !isAgent && (
            <Tab eventKey='deposit' title={t('deposit')}>
              <div className='mt-5'>
                <Row className='mt-3 d-flex flex-row-reverse '>
                  <AccountTransaction
                    isDeposit
                    t={t}
                    buttonTitle={t('deposit')}
                    userData={user}
                  />
                </Row>
              </div>
            </Tab>
          )}
          {!isAffiliate && !isAgent && (
            <Tab eventKey='withdrawal' title={t('withdrawal')}>
              <div className='mt-5'>
                <Row className='mt-3 d-flex flex-row-reverse '>
                  <AccountTransaction
                    t={t}
                    buttonTitle={t('withdrawal')}
                    userData={user}
                    isWithdraw
                  />
                </Row>
              </div>
            </Tab>
          )}
          {!isAffiliate && !isAgent && (
            <Tab
              eventKey='bonusTransactions'
              title={t('casinoBonusTransactions')}
            >
              <div className=''>
                <BonusTransactions
                  selectedTab={selectedTab}
                  t={t}
                  userData={user}
                />
              </div>
            </Tab>
          )}
          {!isHidden({ module: { key: 'Transactions', value: 'R' } }) && (
            <Tab
              eventKey='betBySportsBetHistory'
              title={t('betBySportsBetHistory')}
            >
              {selectedTab === 'betBySportsBetHistory' && (
                <div className='pt-3'>
                  <SportsTransactions isBetBy userData={user} />
                </div>
              )}
            </Tab>
          )}
          <Tab
            eventKey='sportsBonusTransactions'
            title={t('sportsBonusTransactions')}
          >
            {selectedTab === 'sportsBonusTransactions' && (
              <div className=''>
                <BonusTransactions isSportsBook userData={user} />
              </div>
            )}
          </Tab>
        </Tabs>

        {showReasonModal
          ? (
              user?.isActive
                ? (
                  <Reason
                    show={show}
                    handleClose={handleClose}
                    markUserStatusInactive={updateTenantUserStatus}
                    name={user?.legalName}
                    setShow={setShowReasonModal}
                  />
                  )
                : (
                  <ConfirmationModal
                    show={show}
                    setShow={setShow}
                    handleYes={() => {
                      updateTenantUserStatus()
                      setShow(false)
                    }}
                    active={user?.isActive}
                    name={user?.legalName}
                  />
                  )
            )
          : null}
        {showTagModal && (
          <TagModal
            show={showTagModal}
            handleClose={setShowTagModal}
            updateTags={updateTags}
            tagOptions={tagOptions}
            setTagOptions={setTagOptions}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            name={`${user?.firstName} ${user?.lastName}`}
          />
        )}
        {showDuplicateModal && (
          <DuplicateUserModal
            show={showDuplicateModal}
            setShow={setShowDuplicateModal}
            limit={limit}
            pageNo={pageNo}
            setLimit={setLimit}
            setPageNo={setPageNo}
          />
        )}
      </div>

      <Modal size='xl' show={showModal} centered>
        <Modal.Header className='justify-content-center'>
          <h4>
            {t('giveBonusTo')} '{user?.firstName} {user?.lastName}'
          </h4>
        </Modal.Header>
        <Modal.Body>
          {bonusList?.length > 0 && (
            <Row>
              <Col style={{ maxWidth: '35%' }}>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }}
                >
                  {t('availableBonuses')}
                </p>
                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <Table>
                    <tbody>
                      {bonusList?.length > 0 &&
                        bonusList?.map((item, i) => {
                          return (
                            <tr key={`player-modal-bonus-history ${i}`}>
                              <td>
                                <Form.Check
                                  readOnly
                                  type='checkbox'
                                  label={item?.promotionTitle?.EN}
                                  checked={
                                    item?.bonusId === selectedBonus?.bonusId
                                  }
                                  onClick={() => setSelectedBonus(item)}
                                />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col style={{ maxWidth: '65%' }}>
                <p
                  className='text-center'
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  {t('bonusDetails')}
                </p>
                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <Table striped className='text-start'>
                    {bonusDetail && (
                      <tbody>
                        <tr>
                          <td>{t('promotionTitle')}</td>
                          <td>{bonusDetail?.promotionTitle?.EN}</td>
                        </tr>
                        <tr>
                          <td>{t('bonusType')}</td>
                          <td>{bonusDetail?.bonusType}</td>
                        </tr>
                        {['match', 'deposit', 'wagering'].includes(
                          bonusDetail?.bonusType
                        ) && (
                          <tr>
                            <td>{t('bonusPercentage')}</td>
                            <td>{bonusDetail?.depositBonusPercent}%</td>
                          </tr>
                        )}
                        {['match', 'deposit', 'wagering'].includes(
                          bonusDetail?.bonusType
                        ) && (
                          <tr>
                            <td>{t('sticky')}</td>
                            <td>
                              {bonusDetail?.isSticky ? t('yes') : t('no')}
                            </td>
                          </tr>
                        )}
                        {['match', 'deposit', 'wagering'].includes(
                          bonusDetail?.bonusType
                        ) && (
                          <tr>
                            <td>{t('bonusBetOnly')}</td>
                            <td>
                              {bonusDetail?.bonusBetOnly ? t('yes') : t('no')}
                            </td>
                          </tr>
                        )}
                        {bonusDetail?.bonusType === 'freespins' && (
                          <tr>
                            <td>{t('quantity')}</td>
                            <td>{bonusDetail?.quantity}</td>
                          </tr>
                        )}
                        {bonusDetail?.validFrom && (
                          <tr>
                            <td>{t('validFrom')}</td>
                            <td>{formatDate(bonusDetail?.validFrom)}</td>
                          </tr>
                        )}
                        {bonusDetail?.validTo && (
                          <tr>
                            <td>{t('validTo')}</td>
                            <td>{formatDate(bonusDetail?.validTo)}</td>
                          </tr>
                        )}
                        <tr>
                          <td>{t('daysToClear')}</td>
                          <td>{bonusDetail?.daysToClear}</td>
                        </tr>
                        {bonusDetail?.wageringMultiplier !== 0 && (
                          <tr>
                            <td>{t('wageringType')}</td>
                            <td>{bonusDetail?.wageringRequirementType}</td>
                          </tr>
                        )}
                        {bonusDetail?.wageringMultiplier !== 0 && (
                          <tr>
                            <td>{t('wageringMultiplier')}</td>
                            <td>{bonusDetail?.wageringMultiplier}</td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </Table>
                  {bonusDetail &&
                    ['match', 'wagering'].includes(bonusDetail?.bonusType) && (
                      <div className='d-flex justify-content-start align-items-center'>
                        <Form.Label
                          className='mb-0'
                          style={{ marginRight: '10px' }}
                        >
                          {t('amount')} <small>({t('optional')})</small>
                        </Form.Label>
                        <Form.Control
                          type='number'
                          min={0}
                          name='amount'
                          className='w-50'
                          placeholder={t('enterAmount')}
                          value={bonusAmount || ''}
                          onChange={(e) => setBonusAmount(e?.target?.value)}
                        />
                      </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          {bonusList?.length === 0 && (
            <div className='text-danger text-center'>{t('noBonusesFound')}</div>
          )}
        </Modal.Body>
        <Modal.Footer
          className={`d-flex justify-content-${
            bonusList?.length > 0 ? 'between' : 'center'
          }`}
        >
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setShowModal(false)
              setBonusAmount('')
            }}
          >
            {t('cancel')}
          </Button>
          {bonusList?.length > 0 && (
            <Button
              className='m-2'
              variant='outline-success'
              style={{ width: '75px' }}
              onClick={issueBonusHandler}
            >
              {t('add')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={isInternalModal}>
        <Modal.Header>
          <h4>
            {t('markAsInternal')} '{user?.firstName} {user?.lastName}'
          </h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('markInternalConfirmation', {
              firstName: user?.firstName,
              lastName: user?.lastName
            })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='m-2'
            variant='outline-warning'
            onClick={() => {
              setIsInternalModal(false)
            }}
          >
            {t('no')}
          </Button>
          <Button
            className='m-2'
            variant='outline-success'
            style={{ width: '75px' }}
            onClick={internalPlayerHandler}
          >
            {t('yes')}
          </Button>
        </Modal.Footer>
      </Modal>

      {freespinModal && (
        <FreeSpinBonusModal
          show={freespinModal}
          setShow={setFreespinModal}
          isTenant
          userData={user}
        />
      )}

      {editUserModal && (
        <EditUserModal
          show={editUserModal}
          setShow={setEditUserModal}
          userData={user}
          isTenant
        />
      )}

      {userPasswordModal && (
        <ResetUserPasswordModal
          show={userPasswordModal}
          setShow={setUserPasswordModal}
          userData={user}
          handleResetUserPassword={handleResetUserPassword}
        />
      )}

      {passwordEmailModal && (
        <ResetPasswordEmailModal
          show={passwordEmailModal}
          setShow={setPasswordEmailModal}
          userData={user}
          handleResetPasswordEmail={handleResetPasswordEmail}
        />
      )}
    </>
  )
}

export default UserDetails
