import { depositAddonEnum } from '../pages/Super-Admin/DepositAddOn/depositAddonConstant'
import { deleteRequest, getRequest, postRequest, putRequest } from './axios'
import { safeStringify } from './helpers'
import { getCurrentLang, getItem } from './storageUtils'

const { REACT_APP_API_URL } = process.env

const superAdminLogin = (data) =>

  postRequest(`${REACT_APP_API_URL}/api/superadmin/login`, data)
const updatetV2WithdrawalTransaction =
  (data) =>
    postRequest(`${REACT_APP_API_URL}/api/superadmin/admin-wallet-action-withdraw-v2`, data)

const getV2WithdrawalTransaction = (data) => {
  const formData = { ...data }
  const urlParams = new URLSearchParams(formData).toString()
  return getRequest(
    `${REACT_APP_API_URL}/api/superadmin/promotion-cash-mapping-details?${urlParams}`
  )
}
const getAgentAndAffiliateList = ({ adminUserId, isTenant }) => {
  return isTenant
    ? getRequest(`${REACT_APP_API_URL}/api/tenant/admin-roles`)
    : getRequest(`${REACT_APP_API_URL}/api/superadmin/admin-roles?adminUserId=${adminUserId || ''}`)
}
const affiliateLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/affiliate/login`, data)

const agentLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/agent/login`, data)

const getAffiliateAllDetails = ({ isAgent, isAffiliate }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : 'affiliate'}/details`)

const userCreate = ({ formData, isTenant, isAffiliate, isAgent }) => {
  return isAffiliate || isAgent ? postRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'agent'}/user/create-player`, formData) : postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/sign-up`, formData)
}

const markUnderInspection = ({ formData }) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/user/mark-under-inspection`, formData)

const tenantLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin/login`, data)

const getUserInspectionDetails = ({ formData }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/user/details`, formData)

const getRanks =
  () =>
    getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-ranks?lang=${getCurrentLang()}`)

const getTenantsCurrencies = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/get-tenant-currencies?tenantId=${tenantId}`)

const getTemplateList =
  ({ tenantId }) =>
    getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-templates?tenantId=${tenantId}&lang=${getCurrentLang()}`)

const getAllTemplateList =
  ({ limit = 10, pageNo = 1, tenantId, searchParam }) =>
    getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-templates-list?tenantId=${tenantId}&limit=${limit}&pageNo=${pageNo}&lang=${getCurrentLang()}&searchParam=${searchParam}`)

const getTemplateDetail =
  ({ templateId }) =>
    getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-template-detail?templateId=${templateId}&lang=${getCurrentLang()}`)

const getSeasonDetail =
  ({ seasonId, tenantId }) =>
    getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-season-details?seasonId=${seasonId}&tenantId=${tenantId}&lang=${getCurrentLang()}`)

const getSeasonList = ({ isActive, tenantId, limit, pageNo, searchParam, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/get-seasons?lang=${getCurrentLang()}&status=${isActive}&tenantId=${tenantId}&limit=${limit}&pageNo=${pageNo}&searchParam=${searchParam}&startDate=${startDate}&endDate=${endDate}`)

const createSeason = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/create-season`, data)

const updateSeason = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/update-season`, data)

const updateSeasonStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/update-season-status`, data)

const deleteSeason = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/delete-season`, data)

const deleteTemplate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/delete-template`, data)

const getTenantSeasonList = ({ isActive, tenantId, limit, pageNo, searchParam, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/ranking-system/get-seasons?lang=${getCurrentLang()}&status=${isActive}&tenantId=${tenantId}&limit=${limit}&pageNo=${pageNo}&searchParam=${searchParam}&startDate=${startDate}&endDate=${endDate}`) // tenant

const getTenantSeasonDetails = ({ tenantId, seasonId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/ranking-system/get-season-details?lang=${getCurrentLang()}&tenantId=${tenantId}&seasonId=${seasonId}`) // tenant

const createTemplate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/create-template`, data)

const updateTemplate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/ranking-system/edit-template`, data)

const getAllTenants = ({ limit, pageNo, search, adminId, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant?limit=${limit}&pageNo=${pageNo}&search=${search}&adminId=${adminId}&status=${status}`
  )
const getThemeData = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/themes`)

const getAllThemes = ({ pageNo, limit }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/themes?limit=${limit}&pageNo=${pageNo}`
  )

const createTenant = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant`, data)

const getAllAdmins = ({
  limit,
  pageNo,
  orderBy,
  sort,
  search,
  superAdminId,
  superRoleId,
  status
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin?orderBy=${orderBy}&pageNo=${pageNo}&limit=${limit}&sort=${sort}&search=${search}&superRoleId=${superRoleId}&superAdminId=${superAdminId}&status=${status}`
  )

const getTenantWalletTransactions = ({
  currency,
  country,
  actionSource,
  search,
  actionType,
  transactionId,
  limit,
  pageNo,
  transactionType,
  from,
  to,
  agentId,
  affiliateId,
  isV2
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/wallet-transactions?currency=${currency || ''}&country=${country || ''}&limit=${limit || 10}&actionSource=${actionSource || ''}&search=${search || ''}&actionType=${actionType || ''}&transactionId=${transactionId || ''}&pageNo=${pageNo || 1}&transactionType=${transactionType || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}&isV2=${isV2 || ''}`
  )
const getAllWalletTransactions = ({
  currency,
  country,
  actionSource,
  search,
  actionType,
  transactionId,
  limit,
  pageNo,
  tenantId,
  transactionType,
  isRequestTimeout,
  from,
  to,
  agentId,
  affiliateId,
  isV2 = false
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/wallet-transactions?currency=${currency || ''}&country=${country || ''}&limit=${limit || 10}&actionSource=${actionSource || ''}&search=${search || ''}&actionType=${actionType || ''}&transactionId=${transactionId || ''}&pageNo=${pageNo || 1}&tenantId=${tenantId || ''}&transactionType=${transactionType || ''}&from=${from || ''}&to=${to || ''}&isRequestTimeout=${isRequestTimeout || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}&isV2=${isV2}`
  )
const getAllTenantAffiliates = ({ limit, pageNo, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/affiliate/all?pageNo=${pageNo}&limit=${limit}&search=${search}`
  )

const getTenantAffiliateById = ({ affiliateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/affiliate?affiliateId=${affiliateId}`
  )

const getAllTenantAdmins = ({ limit, pageNo, orderBy, sort, search, status }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/admin?orderBy=${orderBy}&limit=${limit}&pageNo=${pageNo}&sort=${sort}&search=${search}&status=${status}`
  )

const getAllTenantCMS = ({ limit, pageNo, search, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/cms?pageNo=${pageNo}&limit=${limit}&search=${search}&isActive=${isActive}`
  )

const getAllThemesTenant = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/themes?limit=50&pageNo=1`)

const getAllCurrencies = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/currency?limit=${limit}&pageNo=${pageNo}`
  )

const getAllTenantCurrencies = ({ limit, pageNo }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/currencies?limit=${limit}&pageNo=${pageNo}`
  )

const getAllAffiliates = ({ limit, pageNo, tenantId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/affiliate/all?limit=${limit}&pageNo=${pageNo}&tenantId=${tenantId}&search=${search}`
  )

const getAffiliateById = ({ affiliateId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/affiliate?affiliateId=${affiliateId}`
  )

const getAllCms = ({ pageNo, limit, tenantId, search, adminId, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/cms?pageNo=${pageNo}&limit=${limit}&tenantId=${tenantId}&search=${search}&adminId=${adminId}&isActive=${isActive}`
  )

const getCmsByPageId = ({ cmsPageId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/cms/details?cmsPageId=${cmsPageId}`
  )

const getAllUsers = ({
  limit, pageNo, tenantId, search, adminId, kycStatus, redFlag,
  affiliateSearch, dobStart, dobEnd, userId, orderBy,
  sortBy, registrationDateStart, registrationDateEnd, isInternal, country, ftdFrom, ftdTo, usersType, restrictedPlayers, agentId,
  affiliateId, isAnonymous, isUserV2
}) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/user/all?limit=${limit}&pageNo=${pageNo}&tenantId=${tenantId}&search=${search}&adminId=${adminId}&kycStatus=${kycStatus}&affiliateName=${affiliateSearch}&dobStart=${dobStart}&dobEnd=${dobEnd}&userId=${userId}&orderBy=${orderBy}&sort=${sortBy}&registrationDateStart=${registrationDateStart}&registrationDateEnd=${registrationDateEnd}&isInternal=${isInternal}&countryCode=${country}&ftdFrom=${ftdFrom || ''}&ftdTo=${ftdTo || ''}&usersType=${usersType}&restrictedPlayers=${restrictedPlayers ? `[${restrictedPlayers}]` : ''}&redFlag=${redFlag || ''}&agentId=${agentId}&affiliateId=${affiliateId}&isAnonymous=${isAnonymous || ''}&isV2User=${isUserV2}`
  )

const getAllCasinoProviders = ({ limit, pageNo, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/providers?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}`
  )

const getAllCasinoGames = ({ defaultRtp, languageCode, bonusId, limit, pageNo, casinoCategoryId, categoryId, search, isActive, tenantId, selectedProvider, freespins, countryCode, portalId = '' }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/games?defaultRtp=${defaultRtp}&limit=${limit}&pageNo=${pageNo}&languageCode=${languageCode || 'en'}&casinoCategoryId=${casinoCategoryId}&search=${search}&isActive=${isActive}&tenantId=${tenantId}&providerId=${selectedProvider}&freespins=${freespins}&bonusId=${bonusId}&countryCode=${countryCode}&portalId=${portalId}&categoryId=${categoryId}`)

const updateTenantAffiliate = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/affiliate/user`, data)

const updateTheme = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/theme`, data)

const updateThemeTenant = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/theme-setting`, data)

const createTheme = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/theme`, data)

const createTenantAdmin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin`, data)

const createAffiliate = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/affiliate/create`, data)

const editTenant = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant`, data)

const getTenant = ({ tenantId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/details?tenantId=${tenantId}`
  )
const createCurrency = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/currency`, data)

const editCurrency = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/currency`, data)

const getCurrencyById = (currencyId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/currency/details?currencyId=${currencyId}`
  )

const getAdminRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/roles`)

const getAdmin = ({ adminId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/details?superAdminUserId=${adminId && adminId}`
  )
const getAllCredentials = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/credentials-keys`)

const createTenantCredentials = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/credential`,
    data
  )

const updateTenantCredentials = (data) =>
  putRequest(
    `${REACT_APP_API_URL}/api/superadmin/tenant/credential`,
    data
  )

const getUser = ({ userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/user?userId=${userId}`)

const getAllCredKeys = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/credentials-keys`)

const createCredentials = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/credential`, data)

const getAllCreds = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/credential`)

const getCredByKey = ({ credKey }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/credential/by-key?key=${credKey}`
  )

const updateCreds = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/credential`, data)

const getTenantDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/details`)

const getAllTenantUsers = (data) => {
  const formData = { ...data }
  delete formData.isAffiliate
  delete formData.isAgent
  const urlParams = new URLSearchParams(formData).toString()
  return data?.isAgent
    ? getRequest(
      `${REACT_APP_API_URL}/api/agent/user/get-players?${urlParams}`
      )
    : data?.isAffiliate
      ? getRequest(
        `${REACT_APP_API_URL}/api/affiliate/user/get-players?${urlParams}`
        )
      : getRequest(
        `${REACT_APP_API_URL}/api/tenant/user/all?${urlParams}`
      )
}

const getTenantUserDetails = ({ userId, isAffiliate, isAgent }) => {
  return isAffiliate || isAgent ? getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'agent'}/user/get-player?userId=${userId}`) : getRequest(`${REACT_APP_API_URL}/api/tenant/user?userId=${userId}`)
}

const getUserFields = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/registration`)

const updateUserFields = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/registration`, data)

const getAdminRoles = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/roles`)

const getAdminUsers = (limit, pageNo, sort, roleId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/admin?orderBy=email&limit=${limit}&pageNo=${pageNo}&sort=${sort}&adminRoleId=${roleId}`
  )

const getAdminUserDetails = ({ adminUserId, isTenant }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${isTenant ? 'tenant/admin/details' : 'superadmin/tenant/admin-details'}?adminUserId=${adminUserId}`
  )

const createAdminUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin`, data)

const createTenantUser = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/user`, data)

const updateTenantUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/user`, data)

const createCms = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/cms`, data)

const getCmsDetails = (cmsId) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/cms/details?cmsPageId=${cmsId}`
  )
const updateFlag = ({
  userId,
  tenantId,
  redFlag,
  reason,
  description,
  securityFlag
}) => putRequest(
  `${REACT_APP_API_URL}/api/superadmin/status-red-flag`, {
    userId,
    tenantId,
    redFlag,
    reason,
    description,
    securityFlag
  }
)
const updateTenantFlag = ({
  userId,
  redFlag,
  reason,
  description,
  isAffiliate,
  isAgent
}) => {
  return isAffiliate || isAgent
    ? putRequest(
      `${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'agent'}/user/status-red-flag`, {
        userId,
        redFlag,
        reason,
        description
      }
      )
    : putRequest(
      `${REACT_APP_API_URL}/api/tenant/status-red-flag`, {
        userId,
        redFlag,
        reason,
        description
      }
    )
}
const setLanguageStatus = ({
  languageId,
  isActive
}) => putRequest(`${REACT_APP_API_URL}/api/superadmin/language/status`, {
  languageId,
  isActive
})
const updateAction = (formData) =>
  putRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/casino-game-management?gameId=${formData?.gameId || ''}&actionType=${formData?.actionType || ''}`, formData.actionType === 'update-status' ? formData : formData.data)
const updateCms = (cmsData) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/cms`, cmsData)

const getTenantRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/roles`)

const getTenantAdminRole = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/roles`)

const getAffiliateRole = ({ isAgent, isAffiliate }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'agent'}/roles`)

const getTenantWallet = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wallet`)

const getSuperAdminWallet = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wallets`)

const getTenantCasinoCategories = ({ limit, pageNo, isAffiliate, isAgent }) => {
  return getRequest(
    `${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/casino/all-category?limit=${limit}&pageNo=${pageNo}`
  )
}

const getTenantGames = ({ languageCode, limit, pageNo, casinoCategoryId, isActive, orderBy, sort, providerId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/category-games?limit=${limit}&pageNo=${pageNo}&languageCode=${languageCode || 'en'}&casinoCategoryId=${casinoCategoryId}&isActive=${isActive}&orderBy=${orderBy}&sort=${sort}&providerId=${providerId}&search=${search}`
  )

const getTenantCasinoProviders = ({ limit, pageNo, isAffiliate, isAgent }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/casino/providers?limit=${limit || ' '}&pageNo=${pageNo || ' '}`
  )

const getMasterGames = ({ bonusId, limit, pageNo, search, casinoCategoryId, providerId, freespins, countryCode }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/games?limit=${limit}&pageNo=${pageNo}&search=${search}&casinoCategoryId=${casinoCategoryId}&providerId=${providerId}&freespins=${freespins}&bonusId=${bonusId}&isActive=''&countryCode=${countryCode}`)

const getTenantCasinoSubCategories = ({
  limit,
  pageNo,
  categoryId,
  search,
  isActive,
  orderBy,
  sort,
  isAffiliate,
  isAgent
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/casino/all-sub-category?limit=${limit || ''}&pageNo=${pageNo || ''}&gameCategoryId=${categoryId || ''}&search=${search || ''}&isActive=${isActive || ''}&orderBy=${orderBy || ''}&sort=${sort || ''}`)

const createTenantCasinoCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const addGamesToSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const createTenantCasinoSubCategory = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const updateTenantCasinoSubCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const updateSubCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-sub-category`, data)

const updateCategoryReOrder = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-category`, data)

const getTenantCasinoMenuById = ({ casinoMenuId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/casino/menu?casinoMenuId=${casinoMenuId}`
  )

const updateTenantCasinoCategory = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const getAllTenantCasinoMenuItems = ({ limit, pageNo, type, isActive }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/tenant/menu-items?limit=${limit}&pageNo=${pageNo}&type=${type}&isActive=${isActive}`
  )

const getCountries = ({ limit, pageNo, name, isActive, kycMethod = '', sportId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/country/list?limit=${limit}&pageNo=${pageNo}&name=${name}&kycMethod=${kycMethod}&isActive=${isActive}&sportId=${sportId}`
  )

const getRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/country/restricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  )

const getUnRestrictedItems = ({ limit, pageNo, type, countryId, search }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/country/unrestricted-items?limit=${limit}&pageNo=${pageNo}&type=${type}&countryId=${countryId}&search=${search}`
  )

const addRestrictedItems = (data) => putRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted`, data)

const updateSuperAdminUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/`, data)

const createSuperAdminUser = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/`,
    data
  )
const tenantCasinoAction = ({
  casinoName,
  thumbnailUrl,
  subcategory,
  gameId,
  description,
  activeStatus,
  languageCode,
  providerId
}) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/casino-game-management?gameId=${gameId}`,
    {
      casinoName,
      thumbnailUrl,
      subcategory,
      description,
      activeStatus,
      languageCode,
      providerId
    }
  )
const tenantCasinoSourceAction = ({
  activeStatus,
  actionSource,
  sourceId
}) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/casino-action-source-management`, {
    activeStatus,
    actionSource,
    sourceId
  })
const createCasinoGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/casino/game`, data)

const createCasinoProvider = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/provider`,
    data
  )

const createCasinoProviderAddGames = (data) =>
  postRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/add-all-games?masterCasinoProviderId=${data}`
  )

const updateCasinoGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/game`, data)

const getSpecificCasinoGame = ({ gameId }) =>
  getRequest(
    `${REACT_APP_API_URL}/api/superadmin/casino/specific-casino-game?gameId=${gameId}`
  )
const updateCasinoProvider = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/provider`, data)

const getSuperAdminDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/details`)

const createTenantCasinoMenuItem = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data)

const getAllWageringCasinoGames = ({ isTenant, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/all-games?providerId=${providerId}&search=${search}`)

const getAllTenantCasinoTables = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/casino/tables`)

const updateTenantCasinoMenuItem = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/menu-item`, data)

const updateTenantAdminUser = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin`, data)

const updateTenantAdmin = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin`, data)

const getAllSuperAdminTransactions = ({ countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, paymentMethod, userId, status, transactionId, createdAtStartDate, createdAtEndDate, check }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/transactions?limit=${limit}&pageNo=${pageNo}&actioneeType=${search}&status=${status}${check.updatedAt ? `&startDate=${startDate}&endDate=${endDate}` : ''}&currencyCode=${currencyId}&transactionType=${transactionType?.length ? transactionType : '[]'}&tenantId=${tenantId}&adminId=${adminId}&paymentProvider=${paymentProvider}&transactionId=${transactionId}&countryCode=${countryCode}&userId=${userId}${check.createdAt ? `&createdAtStartDate=${createdAtStartDate}&createdAtEndDate=${createdAtEndDate}` : ''}&paymentMethod=${paymentMethod}`)

const getSuperAdminAllTransactions = ({ isTenant, countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, transactionId, userId, status, orderBy, orderType }) => {
  return (
    getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions-all?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&actioneeType=${search}&status=${status}&transactionId=${transactionId}&currencyCode=${currencyId}&transactionType=${transactionType}&tenantId=${tenantId}&adminId=${adminId}&paymentProvider=${paymentProvider}&countryCode=${countryCode}&userId=${userId}&sort=${orderType}&orderBy=${orderBy}`)
  )
}

const getBannedGamesDetails = ({ startDate, endDate, userId, tenantId, isTenant = false, orderBy, orderType, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/banned-games?userId=${userId}&endDate=${endDate}&startDate=${startDate}&tenantId=${tenantId}&orderType=${orderType}&orderBy=${orderBy}&pageNo=${pageNo}&limit=${limit}`)

const getAllTenantTransactions = ({ countryCode, limit, pageNo, search, startDate, endDate, currencyId, transactionType, paymentProvider, paymentMethod, userId, status, transactionId, createdAtStartDate, createdAtEndDate, check }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/transactions?limit=${limit}&pageNo=${pageNo}&status=${status}&actioneeType=${search}${check.updatedAt ? `&startDate=${startDate}&endDate=${endDate}` : ''}&transactionId=${transactionId}&currencyCode=${currencyId}&transactionType=${transactionType?.length ? transactionType : '[]'}&paymentProvider=${paymentProvider}&countryCode=${countryCode}&userId=${userId}${check.createdAt ? `&createdAtStartDate=${createdAtStartDate}&createdAtEndDate=${createdAtEndDate}` : ''}&paymentMethod=${paymentMethod}`)

const getTenantTransactionUsers = ({ type, email }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/transaction-users?type=${type}&email=${email}`)

const getSuperAdminTransactionUsers = ({ email }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/transaction-users?email=${email}`)

const getAllTenantCasinoTransactions = ({
  limit,
  pageNo,
  startDate,
  endDate,
  transactionType,
  userId,
  isAffiliate,
  isAgent,
  username,
  isUserV2,
  nonWithdrawableRolled,
  bonusRolled,
  promotionRolled,
  nonWithdrawable,
  bonus,
  promotionCash

}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent/user' : isAffiliate ? 'affiliate/user' : 'tenant/casino'}/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate || ''}&endDate=${endDate || ''}&transactionType=${transactionType || ''}&userId=${userId}&username=${username}&isV2User=${isUserV2}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&nonWithdrawable=${nonWithdrawable || ''}&bonus=${bonus || ''}&promotionCash=${promotionCash || ''}`)

const getTenantCountries = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/all-country`)

const getSuperAdminAggregators = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/aggregators?limit=${limit}&pageNo=${pageNo}`)

const createSuperAdminAggregator = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/casino/aggregator`, data)

const getSuperAdminGameCategory = ({ limit, pageNo, search, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/all-category?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}&tenantId=${tenantId || ''}`)

const getSuperAdminGameSubCategory = ({ limit, pageNo, search, gameCategoryId, isActive, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/all-sub-category?pageNo=${pageNo || ''}&gameCategoryId=${gameCategoryId || ''}&search=${search || ''}&limit=${limit || ''}&isActive=${isActive || ''}&tenantId=${tenantId || ''}`)

const deleteTenantCasinoGame = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const updateTenantCategoryGame = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/category-game`, data)

const deleteRestrictedItems = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted-items`, data)

const getRestrictedCountries = ({ itemId, type, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}`)

const getUnRestrictedCountries = ({ itemId, type, limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/country/unrestricted?itemId=${itemId}&type=${type}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const deleteRestrictedCountries = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted`, data)

const addRestrictedCountries = (data) => putRequest(`${REACT_APP_API_URL}/api/superadmin/country/restricted-items`, data)

const addDepositToOther = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/add-balance`, data)

const getSuperAdminCasinoTransactions = ({
  limit,
  pageNo,
  startDate,
  endDate,
  currencyCode,
  transactionType,
  tenantId,
  status,
  email,
  adminId,
  userId,
  categoryId,
  subCategoryId,
  providerId,
  isUserV2,
  nonWithdrawableRolled,
  bonusRolled,
  promotionRolled,
  nonWithdrawable,
  bonus,
  promotionCash,
  casinoTransactionType
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate || ''}&endDate=${endDate || ''}&currencyCode=${currencyCode || ''}&transactionType=${transactionType?.length ? transactionType : 'all'}&tenantId=${tenantId || ''}&status=${status || 'all'}&email=${email || ''}&adminId=${adminId || ''}&userId=${userId || ''}&categoryId=${categoryId || ''}&subCategoryId=${subCategoryId || ''}&providerId=${providerId || ''}&isV2User=${isUserV2}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&nonWithdrawable=${nonWithdrawable || ''}&bonus=${bonus || ''}&promotionCash=${promotionCash || ''}&casinoTransactionType=${casinoTransactionType || ''}`)

const getSuperAdminBonusTransactions = ({
  limit,
  pageNo,
  from,
  to,
  actionType,
  userId,
  adminId,
  search,
  agentId,
  affiliateId,
  type,
  isV2User,
  lapsedBonus
}, isReporting) => {
  return isReporting
    ? getRequest(`${REACT_APP_API_URL}/api/superadmin/report/bonus-transactions?limit=${limit}&pageNo=${pageNo}&from=${from}&to=${to}&actionType=${actionType}&search=${search}&tenantId=${adminId || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}&type=${type || ''}&isV2User=${isV2User || false}&lapsedBonus=${lapsedBonus || 'false'}`)
    : getRequest(`${REACT_APP_API_URL}/api/superadmin/user/bonus-transaction?limit=${limit}&pageNo=${pageNo}&from=${from}&to=${to}&actionType=${actionType}&search=${search}&userId=${userId}&isV2User=${isV2User || false}&type=${type || 'CASINO'}&lapsedBonus=${lapsedBonus || 'false'}`)
}
const getTenantBonusTransactions = (data, isReporting, isAffiliate, isAgent) => {
  return isReporting ? getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/bonus-transactions`, data) : getRequest(`${REACT_APP_API_URL}/api/tenant/user/bonus-transaction`, data)
}

const getAllClients = ({ orderBy, limit, pageNo, sort, search, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/admin-users?orderBy=${orderBy || ''}&limit=${limit || ''}&pageNo=${pageNo || ''}&sort=${sort || ''}&search=${search || ''}&status=${status || ''}`)

const getAllPortals = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/list?adminId=${adminId}`)

const getTenantList = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/list`)

const updateSuperAdminCMS = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/cms`, data)

const isDomainExist = ({ domain, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/check-domain?domain=${domain}&tenantId=${tenantId}`)

const tenantViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin/status`, data)

const superAdminViewToggleStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/status`, data)

const deleteTenantAdminCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/category`, data)

const deleteTenantAdminSubCategory = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/tenant/casino/sub-category`, data)

const getAllSAProviders = ({ isAffiliate, isAgent }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'superadmin'}/casino/all-providers`)

const getAllTAProviders = ({ isAffiliate, isAgent }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'tenant'}/casino/providers`)

const getGlobalRegistration = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/global-registration`)

const updateGlobalRegistration = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/global-registration`, data)

const getSAConvertAmount = ({ currencyFields, currencyCode, tenantIds }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/convert-amount?currencyFields=${safeStringify(currencyFields)}&currentCurrencyCode=${currencyCode}&tenantIds=${tenantIds}`)

const getAllBonus = ({ formData, isTenant, isAffiliate }) => {
  const paramsString = new URLSearchParams(formData).toString()
  return isAffiliate ? getRequest(`${REACT_APP_API_URL}/api/affiliate/bonus/list-bonus?${paramsString}`) : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus-new?${paramsString}`)
}

const createBonus = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new`, data)

const updateBonus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new`, data)

const updateBonusStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new/update-bonus-status`, data)

const getBonus = ({ formData, isTenant, isAffiliate }) => {
  const paramString = new URLSearchParams(formData).toString()
  return isAffiliate ? getRequest(`${REACT_APP_API_URL}/api/affiliate/bonus/get-bonus?${paramString}`) : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus-new/get-bonus?${paramString}`)
}

const issueBonus = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/issue`, data)

const getUserBonus = ({ limit, pageNo, bonusType, status, userId, isTenant, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/user?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}&status=${status}&userId=${userId}&bonusId=${bonusId}`)

const cancelBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/cancel`, data)

const getUserDocument = (userId, flag) =>
  getRequest(`${REACT_APP_API_URL}/api/${flag}/user/document?userId=${userId}`)

const verifyUserDocument = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/user/verify-document`, data)

const getDocumentLabel = (userId) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/document-label?userId=${userId}`)

const getTransactionStatTenant = ({ endDate, startDate, dateOptions }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/demographic?startDate=${startDate}&endDate=${endDate}&dateOptions=${dateOptions}`)

const getTransactionStatSuperAdmin = ({
  endDate,
  startDate,
  adminId,
  tenantId,
  dateOptions
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/dashboard/transactions-history?startDate=${startDate || ''}&endDate=${endDate || ''}&tenantId=${tenantId || ''}&dateOptions=${dateOptions || ''}`)

const updateDocumentLabel = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/document-label`, data)

const createDocumentLabel = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/document-label`, data)

const requestDocument = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/user/request-document`, data)

const cancelDocumentRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/user/cancel-document-request`, data)

const getProviderStatSuperAdmin = ({
  tenantId,
  providerId,
  categoryId,
  from,
  to,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'superadmin'}/dashboard/provider-transaction?tenantId=${tenantId || ''}&providerId=${providerId || ''}&categoryId=${categoryId || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)

const getProviderStatTenant = ({
  providerId,
  categoryId,
  from,
  to,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/dashboard/provider-transaction?providerId=${providerId || ''}&categoryId=${categoryId || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)

const getPaymentMethod = ({ flag }) =>
  getRequest(`${REACT_APP_API_URL}/api/${flag}/bonus/payment-methods`)

const getWageringTemplateDetail = ({ wageringTemplateId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/details?wageringTemplateId=${wageringTemplateId}`)

const getTenantLivePlayerReport = ({
  languageCode,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'tenant'}/dashboard/users?languageCode=${languageCode || 'en'}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)

const getSuperAdminLivePlayerReport = ({
  tenantId,
  languageCode,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'superadmin'}/dashboard/users?tenantId=${tenantId}&languageCode=${languageCode || 'en'}&agentId=${agentId}&affiliateId=${affiliateId}`)

const getSuperAdminAllWageringTemplate = ({ adminId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/all?adminId=${adminId}`)

const getTenantAllWageringTemplate = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/all`)

const getSuperAdminWageringTemplateDetail = ({ wageringTemplateId, limit, pageNo, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template/details?wageringTemplateId=${wageringTemplateId}&pageNo=${pageNo}&limit=${limit}&providerId=${providerId}&search=${search}`)

const getTenantWageringTemplateDetail = ({ wageringTemplateId, limit, pageNo, providerId, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/details?wageringTemplateId=${wageringTemplateId}&pageNo=${pageNo}&limit=${limit}&providerId=${providerId}&search=${search}`)

const createWageringTemplate = (isTenant, data) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/wagering-template`, data)

const updateWageringTemplate = (isTenant, data) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/wagering-template`, data)
const getBalanceBonuses = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/balance?tenantId=${tenantId}`)
const getTABalanceBonuses = ({ tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/balance?tenantId=${tenantId}`)

const getTAConvertAmount = ({ currencyFields, currencyCode, tenantIds }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/convert-amount?currencyFields=${safeStringify(currencyFields)}&currentCurrencyCode=${currencyCode}&tenantIds=${tenantIds}`)

const getAllTABonus = ({ limit, pageNo, bonusType, isActive, search, userId, reorder, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/bonus?limit=${limit}&pageNo=${pageNo}&search=${search}&isActive=${isActive}&bonusType=${bonusType}&userId=${userId}&reorder=${reorder}&bonusId=${bonusId}`)

const getTAWageringTemplateDetail = ({ wageringTemplateId, limit, providerId, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template/details?wageringTemplateId=${wageringTemplateId}&providerId=${providerId}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const getSuperAdminWageringTemplate = ({ adminId, search, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/wagering-template?adminId=${adminId}&pageNo=${pageNo}&limit=${limit}&search=${search}`)

const getTenantWageringTemplate = ({ search, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/wagering-template?pageNo=${pageNo}&limit=${limit}&search=${search}`)

const getSAPlayerLiability = ({ tenantId, adminId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/players-liability?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}`)

const getTAPlayerLiability = ({ tenantId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/players-liability?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`)

const getSAKPISummary = ({ tenantId, adminId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/kpi-summary?tenantId=${tenantId}&adminId=${adminId}&startDate=${startDate}&endDate=${endDate}`)

const getTAKPISummary = ({ tenantId, startDate, endDate }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/kpi-summary?tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}`)

const getSAKPIReport = ({ tenantId, adminId, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/kpi?tab=${selectedTab}&tenantId=${tenantId}&adminId=${adminId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}`)

const getTAKPIReport = ({ tenantId, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/kpi?tab=${selectedTab}&tenantId=${tenantId}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}`)

const getTenantOwnerDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/admin/details`)

const getSAWalletStats = ({
  tenantId,
  from,
  to,
  languageCode,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'superadmin'}/dashboard/wallet-history?tenantId=${tenantId}&from=${from || ''}&to=${to || ''}&languageCode=${languageCode || 'ko'}&agentId=${agentId}&affiliateId=${affiliateId}`)

const getTAWalletStats = ({
  from,
  to,
  languageCode,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'tenant'}/dashboard/wallet-history?from=${from || ''}&to=${to || ''}&languageCode=${languageCode || 'ko'}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)

const getSAPlayerGameReport = ({ userId, limit, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/game?userId=${userId}&limit=${limit}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}`)

const getTAPlayerGameReport = ({ userId, limit, startDate, endDate, dateOptions, selectedTab }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/report/game?userId=${userId}&limit=${limit}&customStartDate=${startDate}&customEndDate=${endDate}&dateOptions=${dateOptions}&tab=${selectedTab}`)

const getFreeSpinGames = ({ providerId, bonusId, limit, pageNo, search, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/freespin-games?providerId=${providerId}&bonusId=${bonusId}&limit=${limit}&pageNo=${pageNo}&search=${search}`)

const getEmailTemplates = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/all`)

const getEmailTemplate = ({ emailTemplateId, isTenant, tenantId }) =>
  tenantId
    ? getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email?emailTemplateId=${emailTemplateId}&tenantId=${tenantId}`)
    : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email?emailTemplateId=${emailTemplateId}`)

const updateEmailTemplate = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email`, data)

const primaryEmailTemplate = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/email/mark-primary`, data)

const getDynamicKeys = ({ isTenant, type }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/dynamic-data?type=${type}`)

const createEmailTemplate = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email`, data)

const getloyaltyLevel = ({ isTenant, tenantId }) =>
  isTenant
    ? tenantId
        ? getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/loyalty-level?tenantId=${tenantId}`)
        : getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/loyalty-level`)
    : getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus/loyalty-level?tenantId=${tenantId}`)

const updateloyaltyLevel = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/loyalty-level`, data)

const updateLoyaltyPoint = ({ loyaltyPoint }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/loyalty-point`, loyaltyPoint)

const updateLoyaltyLimit = ({ loyaltyLimit }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/limit`, loyaltyLimit)

const getLanguages = ({ limit, pageNo, name, isTenant, isAffiliate, isAgent }) => {
  return isAgent
    ? getRequest(`${REACT_APP_API_URL}/api/agent/languages`)
    : isAffiliate
      ? getRequest(`${REACT_APP_API_URL}/api/affiliate/languages`)
      : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language?limit=${limit || ''}&pageNo=${pageNo || ''}&name=${name || ''}`)
}

const updateProfile = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/profile`, data)

const updateAdminProfile = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/admin/profile`, data)

const getAdminChildren = ({ superAdminId, superRoleId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/childs?superAdminId=${superAdminId}&superRoleId=${superRoleId}`)

const getAllGroups = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/all-group`)

const getTags = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/user/tags`)

const updateTags = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/tags`, data)

const getImageGallery = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/gallery`)

const deleteFromGallery = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/gallery`, data)

const getDuplicateUsers = ({ limit, pageNo, userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/duplicate?limit=${limit}&pageNo=${pageNo}&userId=${userId}`)

const testEmailTemplateEndPoint = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/test`, data)

const setDailyLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/daily-limit`, data)

const setDepositLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/deposit-limit`, data)

const setLossLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/loss-limit`, data)

const getOwnerPermissions = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/owner-permission`)

const setEmailCreds = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/email/credentials`, data)

const disableUser = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/disable-until`, data)

const setSessionLimit = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/session-time`, data)

const getBanners = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/banner/list`)

const uploadBanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/banner/update`, data)

const getCMSDynamicKeys = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/cms/dynamic-data`)

const getAllSABanners = ({ limit, pageNo, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/banner/list?limit=${limit}&pageNo=${pageNo}&tenantId=${tenantId || ''}`)

const uploadSABanner = (data) => {
  return postRequest(`${REACT_APP_API_URL}/api/superadmin/banner/create`, data)
}

const updateSABanner = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/banner/update`, data)

const deleteEmailTemplateLanguage = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/language`, data)

const getEmailTypes = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/email/dynamic-data`)

const deleteCMSLanguage = ({ isTenant, data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/cms`, data)

const updateReorderGames = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/casino/order-category-games`, data)

const getTenantLanguages = ({ isTenant, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/tenant'}/language?tenantId=${tenantId}`)

const addInternalTag = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/internal`, data)

const getLanguageSupportKeys = ({ tenantId, language, isTenant, errorCodes }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/support-keys?tenantId=${tenantId}&language=${language}&errorCodes=${errorCodes}`)

const uploadLanguageCSV = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/load-file`, data)

const updateLanguageSupport = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/support-keys`, data)

const getCMSLanguage = ({ adminId, tenantId }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/cms/language?adminUserId=${adminId}&tenantId=${tenantId}`)

const sendMail = ({ email, isSuperAdmin }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant/admin'}/forget-password`, { email })

const resetPassword = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant/admin'}/verify-forget-password`, data)

const updateUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant'}/affiliate/user`, data)

const addUserAffiliate = ({ isSuperAdmin, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isSuperAdmin ? 'superadmin' : 'tenant'}/affiliate/add-user`, data)

const getPaymentAggregators = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/payment'}/aggregators`)

const getPaymentProvider = ({ isTenant, paymentType, aggregator, tenant, group, status, tenantIds, category }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/payment/providers?paymentType=${paymentType}&aggregator=${aggregator}&tenant=${tenant}&group=${group}&status=${status}&tenantIds=${tenantIds}&category=${category}`)

const getProviderDetails = ({ providerId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/payment'}/provider-details?providerId=${providerId}`)

const configProviderSettings = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin/payment'}/provider-settings`, data)

const updateKycMethod = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/country/kyc-method`, data)

const updatePaymentProviderStatus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/update-provider-status`, data)

const reOrderPaymentProviders = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/tenant/payment/order-providers`, data)

const getCommentsList = ({ limit, search, pageNo, userId, isTenant, role, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comments?limit=${limit}&pageNo=${pageNo}&search=${search}&userId=${userId}&role=${role}&status=${status}`)

const addComments = ({ isTenant, data }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comment`, data)

const updateSAUserStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/status`, data)

const updateComment = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/comment-status`, data)
const reorderBonus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/order`, data)

const getSegments = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/bonus/segments`)

const deleteBonus = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new?bonusId=${data?.bonusId}`)

const verifyPlayerEmail = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/verify-email`, data)

const getAllReviews = ({ limit, search, pageNo, isTenant, tenantId, status }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? `tenant/review?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}` : `superadmin/tenant/review?limit=${limit}&pageNo=${pageNo}&search=${search}&status=${status}&tenantId=${tenantId}`}`)

const addReview = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/review`, data)

const updateReview = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/review`, data)

const resetProfilePassword = ({
  isTenant, data, isAffiliate,
  isAgent
}) =>
  putRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : isTenant ? 'tenant/admin' : 'superadmin'}/change-password`, data)

const resetPasswordEmail = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/reset-password`, data)

const resetUserPassword = ({ isTenant, data }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/update-password`, data)

const getPaymentCategories = ({ paymentType }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/payment/category?paymentType=${paymentType}`)

const orderPaymentCategories = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/payment/order-category`, data)

const addTop = ({ isTenant, formData }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/update-top-status`, formData)

const updateUser = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user`, data)

const getCashbackData = ({ isTenant, userId, level, tenantId, currencyCode }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/cashback?userId=${userId}&level=${level}&tenantId=${tenantId}&currencyCode=${currencyCode}`)

const getCurrentCashbackData = ({ isTenant, userId, level, tenantId, currencyCode }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/current-cashback?userId=${userId}&level=${level}&tenantId=${tenantId}&currencyCode=${currencyCode}`)

const sendUserEmail = ({ data, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/send-mail`, data)

const getEmailLogs = ({ isTenant, limit, pageNo, search, role, userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/email-logs?limit=${limit}&pageNo=${pageNo}&search=${search}&role=${role}&userId=${userId}`)

const getOwnerManagers = ({ ownerId, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/owner-admins?ownerId=${ownerId}&limit=${limit}&pageNo=${pageNo}`)

const getWalletData = ({ userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/wallet-details?userId=${userId}`)

const getQrCode = ({ userType, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/generate-otp-2fa?userType=${userType}`)

const verifyAuthCode = ({ token, isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/verify-otp-2fa`, { token })

const disableAuthenticator = ({ isTenant }) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/disable-auth`)

const updateUserCurrPermission = ({ userId, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/check-currency-editable?userId=${userId}`)

const getTransactionPaymentCategories = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transaction-providers`)

const getTransactionReportingPaymentCategories = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/provider-reporting-names`)

const updateTransactionStatus = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/${data.isTenant ? 'tenant' : 'superadmin'}/user/transaction`, data)

const updateErrorCodes = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/payment/errors`, data)

const getCasinoThemes = ({ isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/all-themes`)

const getCasinoGameIds = ({ search, rating, provider, category, themes, tenantId, country, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/filtered-games?search=${search}&rating=${rating}&themes=${themes}&provider=${provider}&deviceType=desktop&tenantId=${tenantId}&country=${country}&category=${category}`)

const updateUserTransaction = ({
  actionType,
  actionSource,
  transactionId,
  addOnConfigData,
  tenantConfiguration
}) => {
  const key = addOnConfigData?.type === 'addOnPercentage' ? 'depositPercentage' : 'relativeAmount'
  const tempData = {
    actionType,
    actionSource,
    transactionId,
    ...(addOnConfigData && {
      depositType: depositAddonEnum[addOnConfigData?.type],
      [key]: tenantConfiguration[addOnConfigData?.type][addOnConfigData?.name],
      rollingPercentage: tenantConfiguration[addOnConfigData?.type].rollingPercentage

    })
  }
  const urlParams = new URLSearchParams(tempData).toString()
  return putRequest(`${REACT_APP_API_URL}/api/tenant/wallet-transactions?${urlParams}`)
}

const updateUserTransactionSuperAdmin = ({
  actionType,
  actionSource,
  transactionId,
  tenantId,
  addOnConfigData,
  tenantConfiguration
}) => {
  const key = addOnConfigData?.name === 'addOnPercentage' ? 'depositPercentage' : 'relativeAmount'
  // const tempData = addOnConfigData === 'hotTimeBonus'
  //   ? {
  //       actionType,
  //       actionSource,
  //       transactionId,
  //       tenantId
  //     }
  //   : {
  //       actionType,
  //       actionSource,
  //       transactionId,
  //       tenantId,
  //       depositType: addOnConfigData === 'UNIQUE_CODE_BONUS' ? 'UNIQUE_CODE_BONUS' : depositAddonEnum[addOnConfigData?.type] || '',
  //       ...(addOnConfigData !== 'UNIQUE_CODE_BONUS' && {
  //         [key]: tenantConfiguration[addOnConfigData?.type]?.[addOnConfigData?.name] || '',
  //         rollingPercentage: tenantConfiguration[addOnConfigData?.type]?.rollingPercentage || ''
  //       })
  //     }
  const tempData = addOnConfigData?.type === 'hotTimeBonus'
    ? {
        actionType,
        actionSource,
        transactionId,
        tenantId,
        depositType: depositAddonEnum.hotTimeBounusConfig
      }
    : {
        actionType,
        actionSource,
        transactionId,
        tenantId,
        depositType: addOnConfigData === 'UNIQUE_CODE_BONUS' ? 'UNIQUE_CODE_BONUS' : depositAddonEnum[addOnConfigData?.type] || '',
        ...(addOnConfigData !== 'UNIQUE_CODE_BONUS' && addOnConfigData !== null && {
          [key]: tenantConfiguration[addOnConfigData?.type]?.[addOnConfigData?.name] || '',
          rollingPercentage: tenantConfiguration[addOnConfigData?.type]?.rollingPercentage || ''
        })
      }

  const urlParams = new URLSearchParams(tempData).toString()
  return putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/wallet-transactions?${urlParams}`)
}

const getBannerTypes = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/banner/type
  `)
const getCMSCategory = () =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/cms/category-options
  `)

const getUserTransactionDetails = ({
  userId,
  limit,
  pageNo,
  actionSource,
  actionType,
  search,
  from,
  to
}) => getRequest(`${REACT_APP_API_URL}/api/tenant/user-wallet-transactions?
limit=${limit || ''}&pageNo=${pageNo || ''}&userId=${userId || ''}&actionType=${actionType || ''}&actionSource=${actionSource || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}`)

const updateKycStatus = ({
  userId,
  kycStatus,
  isAffiliate
}) => putRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'tenant'}/user/update-kyc-status`, {
  kycStatus,
  userId
})
const updateKycRequestBySuperAdmin = ({
  userId,
  kycStatus
}) => putRequest(`${REACT_APP_API_URL}/api/superadmin/user/update-kyc-status`, {
  kycStatus,
  userId
})
const deleteBannerSuperAdmin = (
  data
) => putRequest(`${REACT_APP_API_URL}/api/superadmin/banner/delete-banner`, data)

const getAllReferrals = ({
  limit,
  pageNo,
  search
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/user/user-referral?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}`
  )

const getAllReferredUser = ({
  userId,
  limit,
  pageNo,
  search
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/user/user-referral-details?referedBy=${userId || ''}&limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}`)

const getCasinoReportingData = ({
  pageNo,
  adminId,
  createdAt,
  actionType,
  limit,
  search,
  transactionType,
  categoryId,
  subCategoryId,
  providerId,
  from,
  to,
  agentId,
  affiliateId,
  isV2,
  nonWithdrawableRolled,
  bonusRolled,
  promotionRolled,
  nonWithdrawable,
  bonus,
  promotionCash,
  casinoTransactionType
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/casino-transaction?pageNo=${pageNo || ''}&adminId=${adminId || ''}&createdAt=${createdAt || ''}&actionType=${actionType || ''}&limit=${limit || ''}&search=${search || ''}&transactionType=${transactionType || ''}&from=${from || ''}&to=${to || ''}&categoryId=${categoryId}&subCategoryId=${subCategoryId}&providerId=${providerId}&agentId=${agentId}&affiliateId=${affiliateId}&isV2User=${isV2}&nonWithdrawableRolled=${nonWithdrawableRolled}&bonusRolled=${bonusRolled}&promotionRolled=${promotionRolled}&nonWithdrawable=${nonWithdrawable || ''}&bonus=${bonus || ''}&promotionCash=${promotionCash || ''}&casinoTransactionType=${casinoTransactionType || ''}`)

const getUserReportingData = ({
  pageNo,
  adminId,
  createdAt,
  actionType,
  limit,
  search,
  from,
  to,
  agentId,
  affiliateId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/user-transaction?pageNo=${pageNo || ''}&adminId=${adminId || ''}&createdAt=${createdAt || ''}&actionType=${actionType || ''}&limit=${limit || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)

const getTenantCasinoReportingData = ({ form, isAffiliate, isAgent }) => {
  const formData = { ...form }
  const urlParams = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/casino-transaction?${urlParams}`)
}

const getTenantUserReportingData = ({ form, isAffiliate, isAgent }) => {
  const urlParams = new URLSearchParams(form).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : 'tenant'}/report/user-transaction?${urlParams}`)
}

const getAllUserHistory = ({
  action,
  section,
  limit,
  pageNo,
  tenantId,
  from,
  to,
  search,
  agentId,
  affiliateId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/logs?limit=${limit || ''}&pageNo=${pageNo || ''}&action=${action || ''}&section=${section || ''}&tenantId=${tenantId || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId}&affiliateId=${affiliateId}`)

const getSecurityReport = ({
  action,
  section,
  limit,
  pageNo,
  tenantId,
  from,
  to,
  search,
  agentId,
  affiliateId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/securtity/get-user-report?limit=${limit || ''}&pageNo=${pageNo || ''}&action=${action || ''}&section=${section || ''}&tenantId=${tenantId || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&agentId=${agentId}&affiliateId=${affiliateId}`)

const getTenantAllUserHistory = ({
  form, isAffiliate,
  isAgent
}) => {
  const urlParams = new URLSearchParams(form).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent/report' : isAffiliate ? 'affiliate/report' : 'tenant/admin'}/logs?${urlParams}`)
}

const depositSuperAdminAmount = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/admin-wallet-action`, data)

const withdrawalSuperAdminAmount = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/admin-wallet-action-withdraw`, data)

const depositTenantAmount = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin-wallet-action`, data)

const withdrawalTenantAmount = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin-wallet-action-withdraw`, data)

const addHotCombo = (paramsData, isTenant) =>
  postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/add-combo-bets`, paramsData)

const updateUserWalletLimitSuperAdmin = ({
  userId,
  withdrawlLimit,
  withdrawlLimitCurrency
}) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/update-player-withdrawl-limit`, {
    userId,
    withdrawlLimit,
    withdrawlLimitCurrency
  })

const getAllNotification = ({
  limit,
  pageNo,
  referenceType,
  status,
  affiliateId,
  agentId,
  referenceId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/crm?limit=${limit || ''}&pageNo=${pageNo || ''}&referenceType=${referenceType || ''}&status=${status || ''}&affiliateId=${affiliateId || ''}&agentId=${agentId || ''}&referenceId=${referenceId || ''}`)

const getAllReadReciept = (
  notificationId,
  limit,
  pageNo,
  isRead
) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/crm/notification-detail?notificationId=${notificationId || ''}&limit=${limit || ''}&pageNo=${pageNo || ''}&isRead=${isRead || ''}`)

const getAllTenantNotification = ({
  limit,
  pageNo,
  referenceType,
  status,
  affiliateId,
  agentId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/crm?limit=${limit || ''}&pageNo=${pageNo || ''}&referenceType=${referenceType || ''}&status=${status || ''}&affiliateId=${affiliateId || ''}&agentId=${agentId || ''}`)

const getAllTenantReadReciept = (
  notificationId,
  limit,
  pageNo,
  isRead
) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/crm/notification-detail?notificationId=${notificationId || ''}&limit=${limit || ''}&pageNo=${pageNo || ''}&isRead=${isRead || ''}`)

const createNotification = (formData) => postRequest(`${REACT_APP_API_URL}/api/${formData?.isTenant ? 'tenant' : 'superadmin'}/crm`, formData)
const createTenantNotification = ({
  referenceType,
  notificationMessage
}) => postRequest(`${REACT_APP_API_URL}/api/tenant/crm`, {
  referenceType,
  notificationMessage
})
const updateNotificationStatus = ({
  notificationId,
  status
}) => postRequest(`${REACT_APP_API_URL}/api/superadmin/crm/update-status`, {
  notificationId,
  status
})
const updateTenantNotificationStatus = ({
  notificationId,
  status
}) => postRequest(`${REACT_APP_API_URL}/api/tenant/crm/update-status`, {
  notificationId,
  status
})
const getSelectedSeniorBonus = (type, tenantId, bonusId) => getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new/bonus-dropdown-options?type=${type}&tenantId=${tenantId}${bonusId ? `&bonusId=${bonusId}` : ''}`)

const getBonusUsers = ({
  search,
  limit,
  pageNo,
  tenantId,
  bonusId,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/bonus-users?limit=${limit}&pageNo=${pageNo}&tenantId=${tenantId}&bonusId=${bonusId}&search=${search}`)

const getUniqueNumberBonus = ({
  bonusType,
  limit,
  pageNo,
  from,
  to
}) => getRequest(`${REACT_APP_API_URL}/api/tenant/bonus/bonus-list?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}&from=${from}&to=${to}`)

const getBonusGames = ({
  search,
  limit,
  pageNo,
  bonusId,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/bonus-games?limit=${limit}&pageNo=${pageNo}&search=${search}&bonusId=${bonusId}`)

const getUserLotteryTickets = ({
  limit,
  pageNo,
  userId,
  from,
  to,
  actionType,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/user-lottery-ticket?limit=${limit || 15}&pageNo=${pageNo || 1}&userId=${userId || ''}&from=${from || ''}&to=${to || ''}&actionType=${actionType || ''}`)

const getSports = ({ paramsData, isTenant }) => {
  if (!isTenant) {
    paramsData = { ...paramsData, isTop: true }
  }
  return getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/sports`, paramsData)
}

const getSportsCountries = ({
  limit,
  pageNo,
  countryName,
  status,
  sportId,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/countries?limit=${limit || 15}&pageNo=${pageNo || 1}&countryName=${countryName || ''}&status=${status || ''}&sportId=${sportId || ''}`)

const getMatches = ({ paramsData, isTenant }) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/matches`, paramsData)

const getMarkets = (data) => getRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/sportsbook/markets`, data)

const getMatchMarkets = ({ paramsData, isTenant }) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-match-markets`, paramsData)
const getTopSports = ({ isTenant }) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-all-top-sports`)
const updateSportsReordering = ({ data, isTenant }) => putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/reorder-sports`, data)

const getLeagues = ({ paramsData, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/tournaments`, paramsData)

const getTeamListing = ({ paramsData, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-all-teams`, paramsData)

const updateTeamImage = ({ paramsData, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/update-team-logo`, paramsData)

const getTopTournaments = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-all-top-tournaments`)

const getUserCounountryTenant = ({ isAffiliate, isAgent, agentId, affiliateId }) => getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : 'tenant'}/dashboard/users-details?agentId=${agentId || ''}&affiliateId=${affiliateId || ''}`)
const updateActionSportsBook = ({
  isTenant,
  entityType,
  status,
  entityId,
  sadminTopOnly,
  position
}) => putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/update-entitiy-status`, {
  entityType,
  status,
  entityId,
  sadminTopOnly,
  position
})

const getUserCounountrySuperAdmin = ({
  tenantId,
  languageCode,
  agentId,
  affiliateId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/dashboard/users-details?tenantId=${tenantId || ''}&languageCode=${languageCode || ''}&agentId=${agentId}&affiliateId=${affiliateId}`)

const updateUserNote = ({
  userId,
  note,
  isTenant
}) => putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/note`, {
  userId,
  note
})

const updateBetNote = ({
  transactionId,
  note,
  isTenant
}) => putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/transaction/note`, {
  transactionId,
  note
})

const updateKycDetails = ({
  userId,
  legalName,
  phone,
  phoneCode,
  accountNumber,
  bankName,
  isTenant,
  isAffiliate,
  isAgent,
  firstName
}) => putRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isTenant ? 'tenant' : 'superadmin'}/user/update-kyc-details`, {
  userId,
  legalName,
  phone,
  phoneCode,
  accountNumber,
  bankName,
  firstName
})
const getUserGGR = ({
  isTenant,
  startDate,
  endDate,
  tenantId,
  userId,
  categoryId,
  subCategoryId,
  providerId,
  isAffiliate,
  isAgent
}) => getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : isAffiliate ? 'affiliate' : isTenant ? 'tenant' : 'superadmin'}/user/ggr-status?startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}&userId=${userId}&categoryId=${categoryId || ''}&subCategoryId=${subCategoryId || ''}&providerId=${providerId || ''}`)

const getUserInspection = ({
  startDate,
  endDate,
  tenantId,
  userId,
  categoryId,
  subCategoryId,
  providerId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/user/transaction-status?startDate=${startDate}&endDate=${endDate}&tenantId=${tenantId}&userId=${userId}&categoryId=${categoryId || ''}&subCategoryId=${subCategoryId || ''}&providerId=${providerId || ''}`)

const getSeniorWalletTransactions = ({
  actionSource,
  search,
  actionType,
  transactionId,
  limit,
  pageNo,
  tenantId,
  transactionType,
  from,
  to,
  status
}) => getRequest(
  `${REACT_APP_API_URL}/api/superadmin/report/senior-wallet-transactions?status=${status || ''}&limit=${limit || 15}&actionSource=${actionSource || ''}&search=${search || ''}&actionType=${actionType || ''}&transactionId=${transactionId || ''}&pageNo=${pageNo || 1}&tenantId=${tenantId || ''}&transactionType=${transactionType || ''}&from=${from || ''}&to=${to || ''}`
)

const nonCashWalletSuperAdmin = (data) => postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/wallet/non-request`, data.data)
const updateSeniorTransactionSuperAdmin = ({
  status,
  transactionId,
  seniorId,
  seniorWalletRequestId
}) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/senior-wallet-action`, {
    status,
    transactionId,
    seniorId,
    seniorWalletRequestId
  })

const getSeniorTenantWalletList = ({
  actionSource,
  search,
  actionType,
  transactionId,
  limit,
  pageNo,
  tenantId,
  transactionType,
  from,
  to,
  status
}) => getRequest(
  `${REACT_APP_API_URL}/api/tenant/report/senior-wallet-transactions?status=${status || ''}&limit=${limit || 15}&actionSource=${actionSource || ''}&search=${search || ''}&actionType=${actionType || ''}&transactionId=${transactionId || ''}&pageNo=${pageNo || 1}&tenantId=${tenantId || ''}&transactionType=${transactionType || ''}&from=${from || ''}&to=${to || ''}`
)

const tenantWalletRequest = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/tenant/admin/wallet-request`, data)

const getSeniorUserReport = ({
  isTenant,
  tenantId,
  from,
  to,
  transactionType,
  isAffiliate,
  isAgent,
  agentId,
  affiliateId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : isAgent ? 'agent' : isTenant ? 'tenant' : 'superadmin'}/dashboard/transaction-report?tenantId=${tenantId || ''}&from=${from || ''}&to=${to || ''}&transactionType=${transactionType || ''}&agentId=${agentId}&affiliateId=${affiliateId}`)

const updateUserPasswordRequest = ({
  logId,
  undergoerId,
  action,
  newPassword,
  isTenant,
  isAffiliate,
  isAgent,
  withdrawalPassword
}) => {
  return putRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent/user' : isAffiliate ? 'affiliate/user' : isTenant ? 'tenant/admin' : 'superadmin'}/review/password-reset`, {
    logId,
    undergoerId,
    action,
    newPassword,
    withdrawalPassword
  })
}
const getSportsTransactions = (isTenant, data) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/${data?.isBetBy ? 'betby/' : ''}bet-history`, data)

const getBetslipDetails = ({
  isTenant,
  betslipId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/bet-history-details?betslipId=${betslipId || ''}`)

const superadminUpdatePassword = ({
  username,
  userId,
  tenantId,
  newPassword,
  withdrawalPassword
}) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/non-request/password-reset`, {
    username,
    userId,
    tenantId,
    newPassword,
    withdrawalPassword
  })

const tenantUpdatePassword = ({
  username,
  userId,
  tenantId,
  newPassword,
  isAffiliate,
  isAgent,
  withdrawalPassword
}) => {
  return isAffiliate || isAgent
    ? putRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate' : 'agent'}/user/non-request/password-reset`, {
        username,
        userId,
        tenantId,
        newPassword,
        withdrawalPassword
      })
    : putRequest(`${REACT_APP_API_URL}/api/tenant/admin/non-request/password-reset`, {
      username,
      userId,
      tenantId,
      newPassword,
      withdrawalPassword
    })
}
const createVoucher = (data, isTenant) =>
  postRequest(`${REACT_APP_API_URL}/api/superadmin/${data?.isUniqueCodeBonus ? 'unique-code-bonus/create' : 'voucher'}`, data)

const getVouchers = (data) => {
  const tempData = { ...data }
  if (!data?.isUniqueCodeBonus) {
    delete tempData.isUniqueCodeBonus
  }
  const urlString = new URLSearchParams(tempData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/${data?.isUniqueCodeBonus ? 'unique-code-bonus/all' : 'voucher'}?${urlString}`)
}

const getVoucherDetails = ({ voucherId, isTenant, isUniqueCodeBonus }) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/${isUniqueCodeBonus ? 'unique-code-bonus/get-by-id' : 'voucher/get-voucher'}?voucherId=${voucherId}`)

const updateVoucher = (formData) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/${formData?.isUniqueCodeBonus ? 'unique-code-bonus/update' : 'voucher'}`, formData)

const updateVoucherStatus = (formData, isUniqueCodeBonus) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/${isUniqueCodeBonus ? 'unique-code-bonus/update-status' : 'voucher/update-voucher'} `, formData)

const updateAffiliateStatus = ({ data, isTenant }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/status`, data)

const getVoucherUsers = ({
  search,
  limit,
  pageNo,
  voucherId,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/voucher/get-voucher-users?limit=${limit}&pageNo=${pageNo}&voucherId=${voucherId}&search=${search}`)

const getVoucherGames = ({
  search,
  limit,
  pageNo,
  voucherId,
  isTenant,
  providerId,
  casinoCategoryId
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/voucher/get-voucher-games?limit=${limit}&pageNo=${pageNo}&voucherId=${voucherId}&search=${search}&providerId=${providerId}&casinoCategoryId=${casinoCategoryId}`)

const getSportsBookTransactions = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/sportsbook/${data?.isBetBy ? 'betby/' : ''}transaction`, data)

const getMarketOutcomes = ({ paramsData, isTenant }) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-market-outcomes`, paramsData)

const getHotCombos = (isTenant) =>
  getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/get-combo-list`)

const deleteHotCombo = ({ isTenant, formData }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/delete-combo-bets`, formData)

const updateHotComboBets = ({ isTenant, formData }) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/update-combo-bets`, formData)

const getVoucherReports = ({
  search,
  limit,
  pageNo,
  tenantId,
  isTenant,
  startDate,
  endDate,
  actionType
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/voucher/voucher-report?limit=${limit}&pageNo=${pageNo}&search=${search}&tenantId=${tenantId}&startDate=${startDate}&endDate=${endDate}&actionType=${actionType}`)

const getAffiliates = ({
  adminUserId,
  from,
  to,
  search,
  status,
  parentRole,
  isTenant,
  limit,
  pageNo
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/list?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}&adminUserId=${adminUserId || ''}&from=${from || ''}&to=${to || ''}&parentRole=${parentRole || ''}&status=${status || ''}`)

const getAffiliateDetails = ({ affiliateId, isTenant }) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/affiliate/detail?affiliateId=${affiliateId}`)

const updateAffiliate = (formData) =>
  putRequest(`${REACT_APP_API_URL}/api/${formData?.isTenant ? 'tenant' : 'superadmin'}/affiliate/update`, formData)

const createAgent = (data) => {
  const formData = { ...data }
  delete formData?.isTenant
  delete formData?.isAffiliate
  if (data?.isAffiliate) {
    return postRequest(`${REACT_APP_API_URL}/api/affiliate/create-agent`, formData)
  } else {
    return postRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/agent/create`, formData)
  }
}

const updateAgent = (data) => {
  const formData = { ...data }
  delete formData?.isTenant
  delete formData?.isAffiliate
  return data?.isAffiliate ? putRequest(`${REACT_APP_API_URL}/api/affiliate/update-agent`, formData) : putRequest(`${REACT_APP_API_URL}/api/${data?.isTenant ? 'tenant' : 'superadmin'}/agent/update`, formData)
}

const getAgentDetails = ({ agentId, isTenant, isAffiliate }) => {
  return isAffiliate ? getRequest(`${REACT_APP_API_URL}/api/affiliate/get-agent?agentId=${agentId}`) : getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/agent/detail?agentId=${agentId}`)
}

const getAffiliateCountry = ({ isAgent, isAffiliate }) => getRequest(`${REACT_APP_API_URL}/api/${isAgent ? 'agent' : 'affiliate'}/all-country`)

const getAgents = ({
  adminUserId,
  affiliateId,
  from,
  to,
  search,
  status,
  parentRole,
  isTenant,
  limit,
  agentWithoutAffiliate,
  pageNo,
  isAffiliate
}) => {
  let roleString
  if (isAffiliate) {
    roleString = 'affiliate'
  } else if (isTenant) {
    roleString = 'tenant'
  } else {
    roleString = 'superadmin'
  }
  return getRequest(`${REACT_APP_API_URL}/api/${roleString}/agent/list?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}&adminUserId=${adminUserId || ''}&from=${from || ''}&to=${to || ''}&parentRole=${parentRole || ''}&status=${status || ''}&affiliateId=${affiliateId || ''}&agentWithoutAffiliate=${agentWithoutAffiliate || ''}`)
}

const updateAgentStatus = ({
  data,
  isTenant,
  isAffiliate
}) => {
  return isAffiliate ? putRequest(`${REACT_APP_API_URL}/api/affiliate/update-agent-status`, data) : putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/agent/status`, data)
}
const getRollingCompetetionList = ({ formData, isTenant }) => {
  const tempData = { ...formData }
  const urlString = new URLSearchParams(tempData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/rolling-competitions/get-all?${urlString}`)
}

const createAndUpdateRollingCompetion = ({ isUpdate, formData }) => {
  return isUpdate ? putRequest(`${REACT_APP_API_URL}/api/superadmin/rolling-competitions/update`, formData) : postRequest(`${REACT_APP_API_URL}/api/superadmin/rolling-competitions/create`, formData)
}

const updateRollingCompetionStatus = ({ formData }) => {
  return putRequest(`${REACT_APP_API_URL}/api/superadmin/rolling-competitions/update-status`, formData)
}
const deleteRollingCompetetion = ({ formData }) => {
  return deleteRequest(`${REACT_APP_API_URL}/api/superadmin/rolling-competitions/delete`, formData)
}
const getBonusAffiliates = ({ formData }) => {
  const urlParams = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/tenant/bonus-new/get-senior-affiliates?${urlParams}`)
}
const getBonusAgents = ({ formData, isAffiliate }) => {
  const urlParams = new URLSearchParams(formData).toString()
  return isAffiliate ? getRequest(`${REACT_APP_API_URL}/api/affiliate/bonus/get-agents?${urlParams}`) : getRequest(`${REACT_APP_API_URL}/api/tenant/bonus-new/get-senior-agents?${urlParams}`)
}
const updateAffiliateAndAgentBonusLimit = ({ formData, isPlayer, isAffiliate }) => {
  return isAffiliate ? putRequest(`${REACT_APP_API_URL}/api/affiliate/bonus/${isPlayer ? 'manage-player-limits' : 'manage-agents'}`, formData) : putRequest(`${REACT_APP_API_URL}/api/tenant/bonus-new/${isPlayer ? 'update-own-players' : 'manage-bonus-entity'}`, formData)
}
const getAdminAffiliateAndAgentsListing = ({ formData }) => {
  const urlParams = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new/get-entity?${urlParams}`)
}
const updateAdminBonusLimit = ({ formData, isPlayer }) => {
  return putRequest(`${REACT_APP_API_URL}/api/superadmin/bonus-new/${isPlayer ? 'manage-player-limits' : 'manage-bonus-entity'}`, formData)
}
const removeAffiliateAndAgent = ({ formData, isTenant, isAffiliate }) => {
  return putRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate/bonus' : isTenant ? 'tenant/bonus-new' : 'superadmin/bonus-new'}/remove-entity`, formData)
}
const getEntityBonusDetails = ({ formData, isAffiliate, isTenant }) => {
  const urlParams = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/${isAffiliate ? 'affiliate/bonus' : isTenant ? 'tenant/bonus-new' : 'superadmin/bonus-new'}/entity-details?${urlParams}`)
}

const getRollingList = ({
  search,
  limit,
  pageNo,
  from,
  to,
  tenantId,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/rolling-competitions/competition-report?limit=${limit || ''}&pageNo=${pageNo || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&tenantId=${tenantId || ''}&languageCode=${getItem('language') || 'en'}`)
const cancelBetslip = ({ betslipId, isTenant }) => postRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/cancel-bet`, { betslipId: Number(betslipId) })

const getAllSportsCountries = ({
  limit,
  pageNo,
  isTenant
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/sportsbook/all-country?limit=${limit || 15}&pageNo=${pageNo || 1}`)

const reorderLeagues = (formData) => {
  const tournaments = { tournaments: formData.tournaments }
  return putRequest(`${REACT_APP_API_URL}/api/${formData.isTenant ? 'tenant' : 'superadmin'}/sportsbook/update-is-top-position`, tournaments)
}

const getAllEvents = ({
  search,
  limit,
  pageNo,
  from,
  to,
  tenantId,
  isTenant,
  isActive
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/event-competitions/get-all?limit=${limit || 15}&pageNo=${pageNo || 1}&search=${search || ''}&from=${from || ''}&to=${to || ''}&isActive=${isActive || ''}&tenantId=${tenantId || ''}&languageCode=${getItem('language') || 'en'}`)

const createAndUpdateEvent = ({ isUpdate, formData }) => {
  return isUpdate
    ? putRequest(`${REACT_APP_API_URL}/api/superadmin/event-competitions/update`, formData)
    : postRequest(`${REACT_APP_API_URL}/api/superadmin/event-competitions/create`, formData)
}
const updateEventStatus = ({ formData }) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/event-competitions/update-status`, formData)

const deleteEvent = ({ formData }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/superadmin/event-competitions/delete`, formData)

const getDashboardSBTransactions = (isTenant, data) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/dashboard/sportbook-transaction`, data)

const getRevenueSeniorData = ({ tenantId }) => getRequest(`${REACT_APP_API_URL}/api/superadmin/dashboard/revenue?tenantId=${tenantId}`)

const getRevenueAffiliateAndAgentData = (data) => {
  const urlParams = new URLSearchParams(data).toString()
  return getRequest(`${REACT_APP_API_URL}/api/superadmin/dashboard/tenant-revenue-details?${urlParams}`)
}
const updateEntity = (formData) => putRequest(`${REACT_APP_API_URL}/api/superadmin/user/update-ownership`, formData)
const updateCasinoGamesHideStatus = (formData) => putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/hide-game`, formData)

const depositAddonTypeAdd = ({ data }) => postRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/add-deposit-config`, data)
const depositAddonTypeEdit = ({ data }) => putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/edit-deposit-config`, data)

const getBetBySportsLogsData = (data) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/log-management`, data)

const getTotalAmount = (
  forEntity,
  search,
  registrationDateStart,
  registrationDateEnd,
  isActive,
  tenantId,
  adminId,
  redFlag,
  kycSeniorStatus,
  kycStatus,
  affiliateSearch,
  affiliateId,
  agentId,
  countryCode,
  isUserV2
) => getRequest(`${REACT_APP_API_URL}/api/${forEntity}/user/user-wallet-dashboard?search=${search || ''}&registrationDateStart=${registrationDateStart || ''}&registrationDateEnd=${registrationDateEnd || ''}&isActive=${isActive || ''}&adminId=${adminId || ''}&tenantId=${tenantId || ''}&redFlag=${redFlag || ''}&kycSeniorStatus=${kycSeniorStatus || ''}&kycStatus=${kycStatus || ''}&affiliateSearch=${affiliateSearch || ''}&affiliateId=${affiliateId || ''}&agentId=${agentId || ''}&countryCode=${countryCode || ''}&isV2User=${isUserV2}`)

const getAllNewGames = ({
  search,
  masterCasinoProviderId,
  limit,
  pageNo
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/casino/new-games?search=${search || ''}&masterCasinoProviderId=${masterCasinoProviderId || ''}&limit=${limit || 15}&pageNo=${pageNo || 1}`)

const getUserForNotification = ({
  tenantId,
  agentId,
  affiliateId,
  limit,
  pageNo,
  isTenant,
  notificationId,
  search
}) => getRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/all-users?limit=${limit || 15}&pageNo=${pageNo || 1}&tenantId=${tenantId || ''}&agentId=${agentId || []}&affiliateId=${affiliateId || []}&notificationId=${notificationId || ''}&search=${search || ''}`)

const updateNotification = (formdata) => putRequest(`${REACT_APP_API_URL}/api/${formdata?.isTenant ? 'tenant' : 'superadmin'}/crm`, formdata)
const getSpecificNotification = (formData) => getRequest(`${REACT_APP_API_URL}/api/${formData.isTenant ? 'tenant' : 'superadmin'}/crm/get-notification-detail?notificationId=${formData?.notificationId || ''}&tenantId=${formData?.tenantId || ''}`)
const getAddonDropDown = ({
  tenantId,
  adminId,
  affiliateId,
  agentId,
  createdAt,
  amount,
  userId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/wallet-request-configs?tenantId=${tenantId || ''}&amount=${amount || ''}&userId=${userId || ''}&createdAt=${createdAt || ''}&adminId=${adminId || ''}&affiliateId=${affiliateId || ''}&agentId=${agentId || ''}`)

const getAddonReportingData = ({
  pageNo,
  adminId,
  limit,
  search,
  from,
  to,
  agentId,
  affiliateId,
  type,
  amountLimit
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/user/addon-transaction?pageNo=${pageNo || ''}&adminId=${adminId || ''}&limit=${limit || ''}&search=${search || ''}&transactionDateStart=${from || ''}&transactionDateEnd=${to || ''}&agentId=${agentId || ''}&affiliateId=${affiliateId || ''}&type=${type || ''}&amountLimit=${amountLimit || ''}`)

const getCasinoLogsData = ({
  pageNo,
  createdAt,
  logType,
  limit,
  search,
  from,
  to,
  categoryId,
  subCategoryId,
  providerId
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/log-management?pageNo=${pageNo || ''}&createdAt=${createdAt || ''}&logType=${logType || ''}&limit=${limit || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&categoryId=${categoryId || ''}&subCategoryId=${subCategoryId || ''}&providerId=${providerId || ''}`)

const getErrorLogsData = ({
  pageNo,
  createdAt,
  logType,
  category,
  limit,
  search,
  from,
  to,
  selectedClient
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/log-management?pageNo=${pageNo || ''}&createdAt=${createdAt || ''}&logType=${logType || ''}&limit=${limit || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&category=${category || ''}&adminId=${selectedClient || ''}`)

const getUserMigratedData = ({
  pageNo,
  tenantId,
  limit,
  search,
  from,
  to
}) => getRequest(`${REACT_APP_API_URL}/api/superadmin/report/user-migration-report?pageNo=${pageNo || ''}&limit=${limit || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&tenantId=${tenantId || ''}`)

const updateAnonymityStatus = (isAnonymousFlag, isTenant, userId) =>
  putRequest(`${REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/update-user-anonymous-status`, isAnonymousFlag, userId)

const bulkUpdateDefaultRtp = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/update-system-rtp`, data)

const providerCategoryUpdateDefaultRtp = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/casino/update-category-provider-system-rtp`, data)

const migratePlayerV2 = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/superadmin/update-player-V2`, data)

const getAllRollTransactions = ({
  pageNo,
  tenantId,
  limit,
  search,
  from,
  to
}) =>
  getRequest(`${REACT_APP_API_URL}/api/superadmin/report/roll-transactions?pageNo=${pageNo || ''}&limit=${limit || ''}&search=${search || ''}&from=${from || ''}&to=${to || ''}&tenantId=${tenantId || ''}`)

const promotionCashAddUpdate = ({ data }) => putRequest(`${REACT_APP_API_URL}/api/superadmin/tenant/edit-promotion-config`, data)

const getAllSportsProvider = ({ data }) => getRequest(`${REACT_APP_API_URL}/api/superadmin/sportsbook/providers`, data)

const updateSportsProvider = ({ data }) => putRequest(`${REACT_APP_API_URL}/api/superadmin/status`, data)

const getAllAdminNotification = ({ data }) => getRequest(`${REACT_APP_API_URL}/api/superadmin/unread-notification-count`, data)
const markAdminNotificationRead = ({ data }) => putRequest(`${REACT_APP_API_URL}/api/superadmin/mark-as-read`, data)
const deleteAdminNotification = ({ data }) => deleteRequest(`${REACT_APP_API_URL}/api/superadmin/mark-as-delete`, data)

export {
  getAllAdminNotification,
  markAdminNotificationRead,
  deleteAdminNotification,
  promotionCashAddUpdate,
  updateSportsProvider,
  getAllSportsProvider,
  updateAnonymityStatus,
  getAddonReportingData,
  getAddonDropDown,
  getAllNewGames,
  updateNotification,
  getSpecificNotification,
  getUserForNotification,
  getTotalAmount,
  getTopSports,
  updateEntity,
  getRevenueSeniorData,
  getRevenueAffiliateAndAgentData,
  getAllEvents,
  getDashboardSBTransactions,
  createAndUpdateEvent,
  updateEventStatus,
  deleteEvent,
  getRollingList,
  depositAddonTypeEdit,
  depositAddonTypeAdd,
  getEntityBonusDetails,
  removeAffiliateAndAgent,
  getAdminAffiliateAndAgentsListing,
  updateAdminBonusLimit,
  getBonusAffiliates,
  getBonusAgents,
  getRollingCompetetionList,
  createAndUpdateRollingCompetion,
  updateRollingCompetionStatus,
  deleteRollingCompetetion,
  updateUserPasswordRequest,
  getSeniorUserReport,
  tenantWalletRequest,
  getSeniorTenantWalletList,
  updateSeniorTransactionSuperAdmin,
  nonCashWalletSuperAdmin,
  getSeniorWalletTransactions,
  getUserInspection,
  getUserGGR,
  updateKycDetails,
  updateUserNote,
  updateBetNote,
  getUserCounountryTenant,
  getUserCounountrySuperAdmin,
  getSports,
  getSportsCountries,
  getMarkets,
  getMatches,
  getLeagues,
  getSelectedSeniorBonus,
  updateTenantNotificationStatus,
  updateNotificationStatus,
  createTenantNotification,
  createNotification,
  getAllTenantNotification,
  getAllTenantReadReciept,
  getAllReadReciept,
  getAllNotification,
  updateUserWalletLimitSuperAdmin,
  getAllUserHistory,
  getTenantAllUserHistory,
  getTenantCasinoReportingData,
  getTenantUserReportingData,
  getCasinoReportingData,
  getCMSCategory,
  getBannerTypes,
  tenantCasinoAction,
  addReview,
  getAllReviews,
  updateReview,
  superAdminLogin,
  tenantLogin,
  getAllTenants,
  getAllAdmins,
  createTenant,
  getThemeData,
  getAllThemes,
  getAllCurrencies,
  getAllTenantAffiliates,
  getAllTenantAdmins,
  getAllTenantCMS,
  createCurrency,
  editCurrency,
  getAllAffiliates,
  getAllCms,
  getAllUsers,
  createTenantAdmin,
  getTenant,
  editTenant,
  getAdminRole,
  getAdmin,
  getCurrencyById,
  getUser,
  getAllCredKeys,
  updateTheme,
  createTheme,
  updateSportsReordering,
  getAllCredentials,
  createCredentials,
  getAllCreds,
  getCredByKey,
  updateCreds,
  getTenantDetails,
  getAllTenantUsers,
  createTenantCredentials,
  getTenantUserDetails,
  getUserFields,
  updateUserFields,
  updateTenantCredentials,
  getAdminRoles,
  getAdminUsers,
  getAdminUserDetails,
  createAdminUser,
  createTenantUser,
  updateTenantUser,
  getAllThemesTenant,
  updateThemeTenant,
  getTenantAffiliateById,
  updateTenantAffiliate,
  createCms,
  getCmsDetails,
  updateCms,
  getTenantRole,
  getTenantAdminRole,
  getTenantWallet,
  getSuperAdminWallet,
  getAffiliateById,
  getCmsByPageId,
  getTenantCasinoCategories,
  getTenantCasinoSubCategories,
  createTenantCasinoCategory,
  getTenantCasinoMenuById,
  updateTenantCasinoCategory,
  getAllCasinoProviders,
  getAllCasinoGames,
  getCountries,
  createSuperAdminUser,
  updateSuperAdminUser,
  getAllTenantCasinoMenuItems,
  getSuperAdminDetails,
  createCasinoGame,
  createCasinoProvider,
  createCasinoProviderAddGames,
  updateCasinoGame,
  updateCasinoProvider,
  createTenantCasinoMenuItem,
  getAllWageringCasinoGames,
  getAllTenantCasinoTables,
  updateTenantCasinoMenuItem,
  updateTenantAdminUser,
  updateTenantAdmin,
  getAllSuperAdminTransactions,
  getAllTenantTransactions,
  getAllTenantCasinoTransactions,
  getTenantTransactionUsers,
  getSuperAdminTransactionUsers,
  getTenantCountries,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  createTenantCasinoSubCategory,
  updateTenantCasinoSubCategory,
  getTenantGames,
  getMasterGames,
  addGamesToSubCategory,
  deleteTenantCasinoGame,
  updateTenantCategoryGame,
  updateSubCategoryReOrder,
  updateCategoryReOrder,
  getRestrictedItems,
  getUnRestrictedItems,
  addRestrictedItems,
  deleteRestrictedItems,
  getRestrictedCountries,
  getUnRestrictedCountries,
  deleteRestrictedCountries,
  addRestrictedCountries,
  getAllTenantCurrencies,
  addDepositToOther,
  getSuperAdminCasinoTransactions,
  getAllClients,
  getAllPortals,
  getTenantList,
  isDomainExist,
  updateSuperAdminCMS,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  deleteTenantAdminCategory,
  deleteTenantAdminSubCategory,
  getAllSAProviders,
  getAllTAProviders,
  getGlobalRegistration,
  updateGlobalRegistration,
  getSAConvertAmount,
  getAllBonus,
  createBonus,
  updateBonus,
  updateBonusStatus,
  getBonus,
  issueBonus,
  getUserBonus,
  cancelBonus,
  getUserDocument,
  verifyUserDocument,
  getDocumentLabel,
  updateDocumentLabel,
  getTransactionStatTenant,
  getTransactionStatSuperAdmin,
  createDocumentLabel,
  requestDocument,
  cancelDocumentRequest,
  getPaymentMethod,
  getWageringTemplateDetail,
  getProviderStatSuperAdmin,
  getProviderStatTenant,
  getSuperAdminLivePlayerReport,
  getTenantLivePlayerReport,
  getSuperAdminAllWageringTemplate,
  getTenantAllWageringTemplate,
  getSuperAdminWageringTemplateDetail,
  createWageringTemplate,
  updateWageringTemplate,
  getBalanceBonuses,
  getTAConvertAmount,
  getAllTABonus,
  getTAWageringTemplateDetail,
  getSuperAdminWageringTemplate,
  getTenantWageringTemplate,
  getTenantWageringTemplateDetail,
  getTABalanceBonuses,
  getSAPlayerLiability,
  getTAPlayerLiability,
  getSAKPISummary,
  getTAKPISummary,
  getSAKPIReport,
  getTAKPIReport,
  getSAWalletStats,
  getTAWalletStats,
  getSAPlayerGameReport,
  getTAPlayerGameReport,
  getFreeSpinGames,
  getTenantOwnerDetails,
  getloyaltyLevel,
  updateloyaltyLevel,
  getLanguages,
  getEmailTemplates,
  getEmailTemplate,
  primaryEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
  updateLoyaltyPoint,
  updateLoyaltyLimit,
  getDynamicKeys,
  getAdminChildren,
  updateProfile,
  updateAdminProfile,
  getAllGroups,
  getTags,
  updateTags,
  getImageGallery,
  deleteFromGallery,
  getDuplicateUsers,
  testEmailTemplateEndPoint,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  getOwnerPermissions,
  setEmailCreds,
  disableUser,
  setSessionLimit,
  getBanners,
  uploadBanner,
  getCMSDynamicKeys,
  deleteEmailTemplateLanguage,
  getEmailTypes,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  deleteCMSLanguage,
  updateReorderGames,
  getTenantLanguages,
  addInternalTag,
  getLanguageSupportKeys,
  uploadLanguageCSV,
  updateLanguageSupport,
  getCMSLanguage,
  sendMail,
  resetPassword,
  updateUserAffiliate,
  addUserAffiliate,
  updateKycMethod,
  getPaymentAggregators,
  getPaymentProvider,
  getProviderDetails,
  configProviderSettings,
  updatePaymentProviderStatus,
  reOrderPaymentProviders,
  getCommentsList,
  addComments,
  updateSAUserStatus,
  updateComment,
  reorderBonus,
  getSegments,
  deleteBonus,
  verifyPlayerEmail,
  resetProfilePassword,
  resetPasswordEmail,
  resetUserPassword,
  getPaymentCategories,
  orderPaymentCategories,
  updateUser,
  getCashbackData,
  sendUserEmail,
  getEmailLogs,
  getOwnerManagers,
  getWalletData,
  getQrCode,
  verifyAuthCode,
  disableAuthenticator,
  updateUserCurrPermission,
  getTransactionPaymentCategories,
  updateTransactionStatus,
  getSuperAdminAllTransactions,
  updateErrorCodes,
  getBannedGamesDetails,
  getCasinoThemes,
  getCasinoGameIds,
  getCurrentCashbackData,
  getTransactionReportingPaymentCategories,
  getSpecificCasinoGame,
  updateAction,
  updateFlag,
  tenantCasinoSourceAction,
  updateTenantFlag,
  setLanguageStatus,
  getTenantWalletTransactions,
  updateUserTransaction,
  getUserTransactionDetails,
  updateKycStatus,
  updateKycRequestBySuperAdmin,
  deleteBannerSuperAdmin,
  getAllReferrals,
  getAllReferredUser,
  getAllWalletTransactions,
  updateUserTransactionSuperAdmin,
  getUserReportingData,
  depositSuperAdminAmount,
  withdrawalSuperAdminAmount,
  depositTenantAmount,
  getTenantCasinoProviders,
  withdrawalTenantAmount,
  getSuperAdminBonusTransactions,
  getTenantBonusTransactions,
  getBonusUsers,
  getUserLotteryTickets,
  getBonusGames,
  getUniqueNumberBonus,
  userCreate,
  markUnderInspection,
  getUserInspectionDetails,
  superadminUpdatePassword,
  tenantUpdatePassword,
  createVoucher,
  getVouchers,
  updateVoucher,
  getVoucherDetails,
  getVoucherUsers,
  getVoucherGames,
  updateVoucherStatus,
  getTemplateList,
  getSeasonList,
  createSeason,
  createTemplate,
  getRanks,
  getTemplateDetail,
  getSeasonDetail,
  deleteSeason,
  getAllTemplateList,
  updateTemplate,
  updateSeason,
  updateSeasonStatus,
  deleteTemplate,
  getTenantSeasonList,
  getTenantSeasonDetails,
  getTenantsCurrencies,
  getVoucherReports,
  createAffiliate,
  getAffiliates,
  getAffiliateDetails,
  updateAffiliateStatus,
  updateAffiliate,
  createAgent,
  getAgentDetails,
  getAgents,
  updateAgent,
  updateAgentStatus,
  affiliateLogin,
  agentLogin,
  getAffiliateAllDetails,
  getAffiliateRole,
  getAffiliateCountry,
  getSecurityReport,
  getAgentAndAffiliateList,
  updateActionSportsBook,
  getSportsTransactions,
  getBetslipDetails,
  getSportsBookTransactions,
  cancelBetslip,
  addTop,
  getAllSportsCountries,
  reorderLeagues,
  getTopTournaments,
  updateAffiliateAndAgentBonusLimit,
  getMarketOutcomes,
  getHotCombos,
  addHotCombo,
  getMatchMarkets,
  deleteHotCombo,
  updateHotComboBets,
  getTeamListing,
  updateTeamImage,
  updateCasinoGamesHideStatus,
  updatetV2WithdrawalTransaction,
  getV2WithdrawalTransaction,
  getCasinoLogsData,
  getBetBySportsLogsData,
  getErrorLogsData,
  bulkUpdateDefaultRtp,
  migratePlayerV2,
  providerCategoryUpdateDefaultRtp,
  getUserMigratedData,
  getAllRollTransactions
}
