import React from 'react'
import { Accordion, Button, Col, Row, Table, Form as BForm } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import Preloader from '../../../components/Preloader'
import EditUploadBanner from './EditUploadBanner'
import useBannerManagement from './useBannerManagement'
import { DeleteConfirmationModal } from '../../../components/ConfirmationModal'
import TableRow from './TableRow'
import useIsGamma from '../../../common/hook/useIsGamma'
import PaginationComponent from '../../../components/Pagination'
// import PaginationComponent from '../../../components/Pagination'

export default () => {
  const {
    isGamma
  } = useIsGamma()
  const {
    loading,
    setSelectedClient,
    selectedClient,
    setSelectedPortal,
    handleCreateEdit,
    handleDelete,
    type,
    data,
    setShow,
    show,
    createUpdate,
    clientsFilterData,
    dispatch,
    SABanners,
    isHidden,
    validation,
    setValidation,
    bannerTypes,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    t,
    limit,
    setLimit,
    page,
    setPage,
    lang,
    setLang,
    languages,
    totalPages,
    updateLoading,
    deleteLoading,
    screenType,
    setScreenType,
    bannerKey,
    setBannerKey,
    subBannerKey,
    setSubBannerKey,
    screenTypeOptions
  } = useBannerManagement()

  return (
    <>
      <Row className='mb-4'>
        <Col>
          <h3>{t('bannerManagement')}</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
            {
              !isGamma &&
              (
                <Button
                  variant='outline-success'
                  size='sm'
                  onClick={() => handleCreateEdit('Create', {})}
                  hidden={isHidden({ module: { key: 'BannerManagement', value: 'C' } })}
                >
                  {t('upload')}
                </Button>
              )
            }
          </div>
        </Col>
      </Row>

      <Accordion>
        {loading
          ? <Preloader />
          : SABanners?.rows?.map((item, i) => {
            return (
              <Accordion.Item
                key={`banner-portal-listing-${i}`}
                eventKey={String(i)}
              >
                <Accordion.Header>{item?.['tenant.name']} ({item?.['tenant.domain']})</Accordion.Header>
                <Accordion.Body>
                  <Table bordered striped responsive hover size='sm' className='text-center mt-2'>
                    <thead className='thead-dark'>
                      <tr>
                        {[t('pages'), t('bannerPreview')].map((h) => (
                          <th key={h}>{h}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <div className='d-flex justify-content-end gap-4 align-items-center'>
                            {/* First Language Selector */}
                            <div className='d-flex align-items-center gap-2'>
                              <BForm.Label>{t('selectLanguage')}</BForm.Label>
                              <BForm.Select
                                name='languageCode'
                                value={lang}
                                onChange={(e) => setLang(e.target.value)}
                              >
                                {languages &&
                                  languages
                                    .filter((item) => item.isActive)
                                    .map(({ languageName, code }, index) => (
                                      <option key={index} value={code.toLowerCase()}>
                                        {t(String(languageName).toLowerCase())}
                                      </option>
                                    ))}
                              </BForm.Select>
                            </div>

                            {/* Second Language Selector */}
                            <div className='d-flex align-items-center gap-2'>
                              <BForm.Label>{t('selectScreenType')}</BForm.Label>
                              <BForm.Select
                                name='selectScreenType'
                                value={screenType}
                                onChange={(e) => {
                                  setScreenType(e.target.value)
                                  setBannerKey(screenTypeOptions.find((item) => item.code === e.target.value).key)
                                  setSubBannerKey(screenTypeOptions.find((item) => item.code === e.target.value).subBannerKey)
                                }}
                              >
                                {
                                  screenTypeOptions
                                    .map(({ name, code }, index) => (
                                      <option key={index} value={code}>
                                        {t(name)}
                                      </option>
                                    ))
}
                              </BForm.Select>
                            </div>
                          </div>
                        </td>

                      </tr>
                      {Object.keys(item[bannerKey]).length > 0 && Object.keys(item[bannerKey])?.map((key, indx) => {
                        const ImgObj = item[bannerKey]
                        const typeOf = typeof item[bannerKey][key][lang]
                        return (
                          <TableRow bannerScreenKey={screenType} ObjectOfData={item[bannerKey][key][lang]} key={indx} bannerKey={key} lang={lang} typeOf={typeOf} item={item} isHidden={isHidden} t={t} handleCreateEdit={handleCreateEdit} ImgObj={ImgObj} handleDelete={handleDelete} subBanner={false} />
                        )
                      })}
                      {Object.keys(item[subBannerKey]).length > 0 && Object.keys(item[subBannerKey])?.map((key, indx) => {
                        const ImgObj = item[subBannerKey]
                        const typeOf = typeof item[subBannerKey][key][lang]
                        return (
                          <TableRow bannerScreenKey={screenType} ObjectOfData={item[subBannerKey][key][lang]} key={indx} bannerKey={key} lang={lang} typeOf={typeOf} item={item} isHidden={isHidden} t={t} handleCreateEdit={handleCreateEdit} ImgObj={ImgObj} handleDelete={handleDelete} subBanner description={item.description} />
                        )
                      })}
                      {(Object.keys({ ...item[bannerKey], ...item[subBannerKey] })?.length === 0) && (
                        <tr>
                          <td colSpan={4} className='text-danger text-center'>
                            {t('noDataFound')}
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
      </Accordion>

      {SABanners?.count === 0 && !loading && <div className='text-center text-danger mt-5'>{t('noDataFound')}</div>}

      {SABanners?.count !== 0 && !loading && (
        <PaginationComponent
          page={SABanners?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={SABanners?.count}
        />
      )}
      {
        deleteModalShow

          ? <DeleteConfirmationModal
              handleDeleteYes={handleDeleteYes}
              setDeleteModalShow={setDeleteModalShow}
              deleteModalShow={deleteModalShow}
              loading={deleteLoading}
            />
          : null
      }
      {
        !isGamma &&
        (
          <EditUploadBanner
            lang={lang}
            bannerTypes={bannerTypes}
            type={type}
            data={data}
            show={show}
            setShow={setShow}
            createUpdate={createUpdate}
            clientsFilterData={clientsFilterData}
            dispatch={dispatch}
            SABanners={SABanners}
            validation={validation}
            setValidation={setValidation}
            loading={updateLoading}
          />
        )
      }
    </>
  )
}
