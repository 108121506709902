import * as Yup from 'yup'
import { t } from 'i18next'
/* eslint-disable */
export const uploadBannerSchema = ({ minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight }) => Yup.object().shape({
  file: Yup.mixed()
    .test('fileFormat', t('imageFormatRequired'), value => {

      if (typeof value === 'string') {
        return true
      }
      if (!value) return true // if no file is uploaded, validation passes
      return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(value.type)
    })
    .test('fileSize', t('sizeTooLarge'), value => {
      if (typeof value === 'string') {
        return true
      }
      if (!value) return true // if no file is uploaded, validation passes
      return value.size <= 10485760 // 10MB limit
    })
    .imageDimensionCheck('Banner Required', minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight),
  bannerScreenType: Yup.string(),
  bannerSubType: Yup.string(),
  description: Yup.string().when(['bannerSubType', 'bannerScreenType'], {
    is: (bannerSubType, bannerScreenType) =>
      bannerSubType === 'subBannerImage' && bannerScreenType === 'web',
    then: Yup.string().required(t('descriptionIsRequired')),
    otherwise: Yup.string(), // No additional validation for other cases
  }),
})

const imageWidthAndHeight = (provideFile) => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: null, height: null }

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.readAsDataURL(provideFile)
    reader.onload = function () {
      const img = new Image()
      img.src = reader.result

      img.onload = function () {
        imgDimensions.width = img.width
        imgDimensions.height = img.height

        resolve(imgDimensions)
      }
    }
  })
}

const imageDimensionCheckBanner = Yup.addMethod(Yup.mixed, 'imageDimensionCheckBanner', function (message, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight) {
  return this.test('image-width-height-check', message, async function (value) {
    const { path, createError } = this

    if (!value) {
      return
    }

    const imgDimensions = await imageWidthAndHeight(value)

    if (imgDimensions.width < minRequiredWidth || imgDimensions.width > maxRequiredWidth) {
      return createError({
        path,
        message: `${t('imageWidthBetween')}` + ' ' + `${minRequiredWidth}px - ${maxRequiredWidth}px!`
      })
    }

    if (imgDimensions.height < minRequiredHeight || imgDimensions.height > maxRequiredHeight) {
      return createError({
        path,
        message: `${t('imageHeightBetween')}` + ' ' + `${minRequiredHeight}px - ${maxRequiredHeight}px!`
      })
    }

    return true
  })
})
