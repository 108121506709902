import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBannersStart, uploadBannerStart } from '../../../store/redux-slices/tenantcredentials'
import useCheckPermission from '../../../utils/checkPermission'
import { useTranslation } from 'react-i18next'

const useBannerManagement = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [data, setData] = useState('')
  const { banners, loading, updateLoading } = useSelector(state => state.tenantcredentials)
  const { tenantDetails } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [refetch, setRefetch] = useState(false)
  const [validation, setValidation] = useState({
    minRequiredWidth: '',
    minRequiredHeight: '',
    maxRequiredWidth: '',
    maxRequiredHeight: ''
  })
  const [bannerKey, setBannerKey] = useState('bannerUrl')
  const [subBannerKey, setSubBannerKey] = useState('subBannerUrl')
  const [screenType, setScreenType] = useState('web')
  const [lang, setLang] = useState('en')
  const { languages } = useSelector(state => state.languages)

  useEffect(() => {
    tenantDetails?.tenantId && dispatch(getAllBannersStart())
  }, [tenantDetails?.tenantId, refetch])

  const upload = (item) => {
    const formData = new FormData()
    formData.append('tenantId', Number(tenantDetails?.tenantId))
    formData.append('lang', lang)
    formData.append('bannerType', item?.bannerType)
    formData.append('bannerScreenType', item?.bannerScreenType)
    if (item?.file) {
      formData.append('file', item?.file)
    }
    if (item?.bannerSubType) {
      formData.append('bannerSubType', item?.bannerSubType)
    }
    if (item?.description) {
      formData.append('description', item.description)
    }
    dispatch(uploadBannerStart({
      data: formData,
      setShow,
      setRefetch
    }))
  }

  const screenTypeOptions = [{ name: 'website', code: 'web', key: 'bannerUrl', subBannerKey: 'subBannerUrl' }, { name: 'mobile', code: 'mobile', key: 'bannerMobileUrl', subBannerKey: 'subBannerMobileUrl' }]

  return {
    show,
    setShow,
    upload,
    data,
    setData,
    banners,
    isHidden,
    validation,
    setValidation,
    loading,
    t,
    lang,
    setLang,
    languages,
    updateLoading,
    bannerKey,
    setBannerKey,
    subBannerKey,
    setSubBannerKey,
    screenType,
    setScreenType,
    screenTypeOptions
  }
}

export default useBannerManagement
