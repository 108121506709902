export const checkLabels = (bonusType) => {
  return [{ label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' }]
  // if (['freespins', 'cashfreespins', 'balance', 'match', 'promotion'].includes(bonusType)) {
  //   return [
  //     { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' },
  //     { label: 'Visible In Promotions', value: 'visibleInPromotions', message: 'If true visible in promotion else not' }
  //   ]
  // } else {
  //   return [
  //     { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' }
  //   ]
  // }
}

export const daysLabels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const convertAmountOptions = [
  { label: 'Max Bonus Claimed', value: 'maxBonusThreshold' },
  { label: 'Min Deposit', value: 'minDeposit' },
  { label: 'Max Win Amount', value: 'maxWinAmount' },
  { label: 'Zero Out Threshold', value: 'zeroOutThreshold' },
  { label: 'Min Wallet Balance', value: 'minBalance' },
  { label: 'Max Allowed Balance', value: 'minBalanceCash' }
]

export const convertCurrencyAmountDepositOptions = [
  { label: 'Min Deposit', value: 'minDeposit' },
  { label: 'Max Deposit', value: 'maxDeposit' },
  { label: 'Deposit Amount', value: 'depositAmount' },
  { label: 'KYC Deposit Amount', value: 'kycDepositAmount' }
]

export const convertCurrencyAmountWithdrawalOptions = [
  { label: 'Min Withdraw', value: 'minWithdraw' },
  { label: 'Max Withdraw', value: 'maxWithdraw' }
]
export const BONUSES = {
  hot_time_bonus: {
    isActive: 'HOT TIME BONUS',
    label: 'hotTimeBonus',
    value: 'hot_time_bonus'
  },
  re_occurring_bonus: {
    isActive: 'RE-OCCURING BONUS',
    label: 'reoccuringBonus',
    value: 're_occurring_bonus'
  },
  compensation_bonus: {
    isActive: 'COMPENSATION BONUS',
    label: 'compensationBonus',
    value: 'compensation_bonus'
  },
  unique_number_bonus: {
    isActive: 'UNIQUE NUMBER BONUS',
    label: 'uniqueNumberBonus',
    value: 'unique_number_bonus'
  },
  first_deposit_bonus: {
    isActive: 'FIRST DEPOSIT BONUS',
    label: 'firstDepositBonus',
    value: 'first_deposit_bonus'
  },
  payback_bonus: {
    isActive: 'PAYBACK BONUS',
    label: 'paybackBonus',
    value: 'payback_bonus'
  }
}
export const BONUSESV2 = {
  hot_time_bonus: {
    isActive: 'HOT TIME BONUS',
    label: 'hotTimeBonus',
    value: 'hot_time_bonus'
  },
  compensation_bonus: {
    isActive: 'COMPENSATION BONUS',
    label: 'compensationBonus',
    value: 'compensation_bonus'
  },
  // payback_bonus: {
  //   isActive: 'PAYBACK BONUS',
  //   label: 'paybackBonus',
  //   value: 'payback_bonus'
  // },
  daily_periodic_paybackBonus: {
    isActive: 'DAILY-PERIODIC-PAYBACK-BONUS',
    label: 'DAILY-PERIODIC-PAYBACK-BONUS',
    value: 'daily_periodic_paybackBonus'
  },

  weekly_periodic_paybackBonus: {
    isActive: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
    label: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
    value: 'weekly_periodic_paybackBonus'
  },
  bi_weekly_periodic_payback_bonus: {
    isActive: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
    label: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
    value: 'bi_weekly_periodic_payback_bonus:'
  },
  monthly_periodic_payback_bonus: {
    isActive: 'MONTHLY-PERIODIC-PAYBACK-BONUS',
    label: 'MONTHLY-PERIODIC-PAYBACK-BONUS',
    value: ' monthly_periodic_payback_bonus'
  },
  rackback_bonus: {
    isActive: 'RACKBACK-BONUS',
    label: 'RACKBACK-BONUS',
    value: 'rackback_bonus'
  }
}
export const bonusArrV2 = [
  ...Object.keys(BONUSESV2).map((key, index) => ({
    label: BONUSESV2[key].label,
    value: BONUSESV2[key].value,
    id: index + 1
  }))
]

export const bonusArr = [
  ...Object.keys(BONUSES).map((key, index) => ({
    label: BONUSES[key].label,
    value: BONUSES[key].value,
    id: index + 1
  })),
  {
    label: 'uniqueCodeBonus',
    value: 'unique_code_bonus',
    id: Object.keys(BONUSES).length + 1
  }
]

export const bonusType = bonusArr
export const bonusTypeV2 = bonusArrV2

export const wageringRequirementType = [
  { label: 'BONUS', value: 'bonus', id: 1 },
  { label: 'BONUS+DEPOSIT', value: 'bonusdeposit', id: 2 }
]

export const tabLabels = [
  'general', 'currency', 'payment', 'wageringContribution', 'games', 'loyalty'
]

export const SPORTS_BONUSES = [
  { label: 'freebet_refund', value: 'freebet_refund', id: 1 },
  { label: 'freebet_money', value: 'freebet_money', id: 2 },
  { label: 'freebet_no_risk', value: 'freebet_no_risk', id: 3 },
  {
    id: 4,
    label: 'DAILY-PERIODIC-PAYBACK-BONUS',
    value: 'daily_periodic_paybackBonus'
  },

  {
    id: 5,
    label: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
    value: 'weekly_periodic_paybackBonus'
  },
  {
    id: 6,
    label: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
    value: 'bi_weekly_periodic_payback_bonus'
  },
  {
    id: 7,
    label: 'MONTHLY-PERIODIC-PAYBACK-BONUS',
    value: ' monthly_periodic_payback_bonus'
  }
]
