export const SuperAdminRoutes = {
  SuperAdminSignin: '/super-admin/signin',
  createNewUser: '/super-admin/create-new-user',
  createNotification: '/super-admin/create-notification',
  bonusCreation: '/super-admin/create-bonus',
  uniqueCodeBonus: '/super-admin/unique-code-bonus',
  bonusListing: '/super-admin/bonus-listing',
  bonusDetail: '/super-admin/bonus-detail/:bonusId',
  bonusEdit: '/super-admin/bonus-edit/:bonusId',
  SportsBonusListing: '/super-admin/sports/bonus-listing',
  SportsBonusDetail: '/super-admin/sports/bonus-detail/:bonusId',
  SportsBonusEdit: '/super-admin/sports/bonus-edit/:bonusId',
  SportsBonusCreation: '/super-admin/sports/create-bonus',
  SportsCloneBonus: '/super-admin/bonus/sports/clone/:bonusId',
  createVoucher: '/super-admin/create-voucher',
  createGiftCard: '/super-admin/create-giftcard',
  createUniqueCodeBonus: '/super-admin/create-unique-code-bonus',
  createAffiliate: '/super-admin/create-affiliate',
  RollingCompetetion: '/super-admin/rolling-competion',
  RollingContestReport: '/super-admin/rolling-contest-report',
  RollingDetails: '/super-admin/rolling-details',
  createAgent: '/super-admin/create-agent',
  templateList: '/super-admin/template-list',
  templateDetail: '/super-admin/template-list/:templateId',
  seasonList: '/super-admin/season-list',
  seasonDetail: '/super-admin/season-list/:seasonId/:tenantId',
  bonusTransactions: '/super-admin/bonus-transactions',
  sportsBonusTransactions: '/super-admin/sportsbook/bonus-transactions',
  voucherTransactions: '/super-admin/voucher-transactions',
  ViewNewGames: '/super-admin/view-new-games',
  giftCardTransactions: '/super-admin/gift-card-transactions',
  bonusUsers: '/super-admin/bonus-users/:bonusId/:tenantId',
  voucherUsers: '/super-admin/voucher-users/:voucherId/:tenantId',
  giftcardUsers: '/super-admin/giftcard-users/:voucherId/:tenantId',
  lotteryTickets: '/super-admin/lottery-tickets/:userId',
  bonusGames: '/super-admin/bonus-games/:bonusId',
  voucherGames: '/super-admin/voucher-games/:voucherId',
  Dashboard: '/super-admin/dashboard',
  TenantOwner: '/super-admin/tenants/owners',
  TOManagers: '/super-admin/tenants/managers/:ownerId',
  Tenants: '/super-admin/tenants/:adminId',
  CreateTenant: '/super-admin/tenants/create/:adminId',
  TenantDetails: '/super-admin/tenants/details/:adminId/:tenantId',
  Admins: '/super-admin/admins',
  AdminDetails: '/super-admin/admins/details/:adminId',
  Currencies: '/super-admin/currencies',
  Languages: '/super-admin/languages',
  CreateCurrencies: '/super-admin/create-currencies',
  EditCurrency: '/super-admin/currencies/edit/:currencyId',
  CreateTenantAdmin: '/super-admin/tenants/owners/create',
  EditTenant: '/super-admin/tenants/edit/:adminId/:tenantId',
  EditTheme: '/super-admin/edit-theme/:themeId',
  EditNotification: '/super-admin/edit-notification/:notificationId',
  CreateTheme: '/super-admin/create-theme',
  Themes: '/super-admin/theme',
  Credential: '/super-admin/tenant/:tenantId/credential',
  CMS: '/super-admin/cms',
  Users: '/super-admin/users',
  UsersV2: '/super-admin/users-v2',
  UsersWithKyc: '/super-admin/users-kyc',
  UsersWithRejectedKyc: '/super-admin/users-rejected-kyc',
  UserWithNonKyc: '/super-admin/users-pending-kyc',
  UserDetails: '/super-admin/users/details/:userId',
  UserDetailsV2: '/super-admin/users-v2/details/:userId',
  UserDetailsWithKyc: '/super-admin/users-kyc/details/:userId',
  UserDetailsWithRejectedKyc: '/super-admin/users-rejected-kyc/details/:userId',
  UserDetailsWithNonKyc: '/super-admin/users-pending-kyc/details/:userId',

  CreateCurrency: '/super-admin/create-currency',
  CMSDetail: '/super-admin/cms/details/:cmsPageId',
  CasinoProviders: '/super-admin/casino-providers',
  CasinoGames: '/super-admin/casino-games',
  Countries: '/super-admin/countries',
  CreateSuperAdminUser: '/super-admin/create-superadmin-user',
  EditSuperAdminUser: '/super-admin/admins/edit/:adminId',
  EditTenantAdmin: '/super-admin/tenants/owners/edit/:adminUserId',
  TransactionsBanking: '/super-admin/transactions-banking',
  Aggregators: '/super-admin/casino-aggregators',
  CasinoCategory: '/super-admin/casino-category',
  CasinoSubCategory: '/super-admin/casino-sub-category',
  RestrictedGames: '/super-admin/countries/restricted-games/:countryId',
  RestrictedProviders: '/super-admin/countries/restricted-providers/:countryId',
  RestrictedProviderCountries: '/super-admin/casino-providers/restrict-countries/:itemId',
  RestrictedGameCountries: '/super-admin/casino-games/restrict-countries/:itemId',
  CasinoTransactions: '/super-admin/casino-transactions',
  SportsReporting: '/super-admin/bet-reporting',
  SportsTransactions: '/super-admin/sports-reporting',
  SportsTransactionsBetBy: '/super-admin/sports-betBy-reporting',
  SportsReportingBetBy: '/super-admin/bet-betBy-reporting',
  SportsTransactionsV2: '/super-admin/v2-sports-reporting',
  BetslipDetails: '/super-admin/bet-reporting/details/:betslipId',
  CreateCMS: '/super-admin/cms/create',
  EditCMS: '/super-admin/cms/edit/:cmsPageId',
  FormFields: '/super-admin/form-fields',
  CreateBonus: '/super-admin/bonus/create',
  EditBonus: '/super-admin/bonus/edit/:bonusId',
  EditVoucher: '/super-admin/voucher/edit/:voucherId',
  EditUniqueCodeBonus: '/super-admin/unique-code-bonus/edit/:voucherId',
  EditGiftcard: '/super-admin/giftcard/edit/:voucherId',
  EditAffiliate: '/super-admin/affiliate/edit/:affiliateId',
  EditAgent: '/super-admin/agent/edit/:agentId',
  VoucherDetail: '/super-admin/voucher/details/:voucherId',
  uniqueCodeBonusDetail: '/super-admin/unique-code-bonus/details/:voucherId',
  GiftcardDetail: '/super-admin/giftcard/details/:voucherId',
  AffiliateDetail: '/super-admin/affiliate/details/:affiliateId',
  AgentDetail: '/super-admin/agent/details/:agentId',
  Bonus: '/super-admin/bonus',
  Voucher: '/super-admin/voucher',
  GiftCard: '/super-admin/gift-card',
  Events: '/super-admin/events',
  Affiliates: '/super-admin/affiliates',
  Agents: '/super-admin/agents',
  BonusDetail: '/super-admin/bonus/details/:bonusId',
  CloneBonus: '/super-admin/bonus/clone/:bonusId',
  KYCLabels: '/super-admin/kyc-labels',
  WageringTemplate: '/super-admin/wagering-template',
  WageringEdit: '/super-admin/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/super-admin/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/super-admin/wagering-template/create',
  Loyalty: '/super-admin/loyalty-management',
  EmailTemplate: '/super-admin/email-templates',
  EditEmailTemplate: '/super-admin/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/super-admin/create-email-template',
  Profile: '/super-admin/profile',
  TenantOwnerDetail: 'super-admin/tenants/owners/details/:adminUserId',
  ImageGallery: '/super-admin/image-gallery',
  BannerManagement: '/super-admin/banner-management',
  LanguageManagement: '/super-admin/language-management',
  EditSupportLanguageKeys: '/super-admin/language-management/edit/:keys',
  EditSupportLanguage: '/super-admin/language-management/edit',
  AddLanguage: '/super-admin/language-management/add-language/:lang',
  PaymentAggregators: '/super-admin/cashier-management',
  EditCustomProviders: '/super-admin/cashier-management/edit-provider/:providerId',
  ViewCustomProviders: '/super-admin/cashier-management/view-provider/:providerId',
  BonusReorder: '/super-admin/bonus/reorder',
  ReviewManagement: '/super-admin/review-management',
  ErrLanguageManagement: '/super-admin/err/language-management',
  EditErrSupportLanguageKeys: '/super-admin/err/language-management/edit/:keys',
  EditErrSupportLanguage: '/super-admin/err/language-management/edit',
  AddErrLanguage: '/super-admin/err/language-management/add-language/:lang',
  UserWallet: '/super-admin/wallet',

  UserDeposit: '/super-admin/deposit',
  UserDepositHistory: '/super-admin/deposit-history',

  V2UserDeposit: '/super-admin/v2-deposit',
  V2UserDepositHistory: '/super-admin/v2-deposit-history',

  VaultDepositHistory: '/super-admin/vault-deposit-history',
  VaultWithdrawHistory: '/super-admin/vault-withdraw-history',

  V2VaultDepositHistory: '/super-admin/v2-vault-deposit-history',
  V2VaultWithdrawHistory: '/super-admin/v2-vault-withdraw-history',

  UserWithdrawal: '/super-admin/withdrawal',
  UserWithdrawalHistory: '/super-admin/withdrawal-history',

  V2UserWithdrawal: '/super-admin/v2-withdrawal',
  V2UserWithdrawalHistory: '/super-admin/v2-withdrawal-history',

  SubpaymentDeposit: '/super-admin/subpayment-deposit',
  SubpaymentWithdraw: '/super-admin/subpayment-withdrawal',
  perodicPaybackBonus: '/super-admin/periodic-payback-bonus',
  sportsPeriodicPaybackBonus: '/super-admin/sportys-periodic-payback-bonus',
  reckBackBonus: '/super-admin/rack-back-bonus',
  V2SubpaymentDeposit: '/super-admin/v2-subpayment-deposit',
  V2SubpaymentWithdraw: '/super-admin/v2-subpayment-withdrawal',
  walletLogs: '/super-admin/wallet-logs',
  SeniorWallet: '/super-admin/senior-wallet',
  Referrals: '/super-admin/referral',
  AssociateReferral: '/super-admin/associate-user-referral/:userId',
  CasinoReporting: '/super-admin/casino-reporting',
  UserReporting: '/super-admin/user-reporting',
  UserHistory: '/super-admin/user-history',
  UserRankHistory: '/super-admin/user-rank-history',
  Notification: '/super-admin/notification',
  Sports: '/super-admin/sports',
  SportsCountries: '/super-admin/sports-country',
  Matches: '/super-admin/matches',
  HotCombo: '/super-admin/hot-combo',
  Markets: '/super-admin/markets',
  Leagues: '/super-admin/legaues',
  Teams: '/super-admin/teams',
  ReorderLeagues: '/super-admin/legaues/reorder',
  securityAlerts: '/super-admin/security-alerts',
  CreateHotCombo: '/super-admin/hot-combo/create',
  ViewHotCombo: '/super-admin/hot-combo/view',
  ReorderSports: '/super-admin/sports/reorder',
  DepositAddOnConfig: '/super-admin/deposit-addon-config',
  depositAddonReporting: '/super-admin/deposit-addon-reporting',
  casinoLogs: '/super-admin/casino-logs',
  errorLogs: '/super-admin/error-logs',
  UserMigrationReport: '/super-admin/user-migration-report',
  UserRollTransactions: '/super-admin/roll-transactions',
  sportsPromotionCashConfig: '/super-admin/sports-promotion-cash-config',
  sportsbookProviders: '/super-admin/sportsbook-providers',
  betBySportsLogs: '/super-admin/betby-sports-logs'
}

export const TenantRoutes = {
  ReorderLeagues: '/tenant/legaues/reorder',
  createNotification: '/tenant/create-notification',
  EditNotification: '/tenant/edit-notification/:notificationId',
  Affiliates: '/tenant/affiliates',
  createAffiliate: '/tenant/create-affiliate',
  createAgent: '/tenant/create-agent',
  HotCombo: '/tenant/hot-combo',
  CreateHotCombo: '/tenant/hot-combo/create',
  ViewHotCombo: '/tenant/hot-combo/view',
  AffiliateDetail: '/tenant/affiliate/details/:affiliateId',
  RollingCompetetion: '/tenant/rolling-competion',
  RollingDetails: '/tenant/rolling-details',
  TenantRollingContestReport: '/tenant/rolling-contest-report',
  Agents: '/tenant/agents',
  AgentDetail: '/tenant/agent/details/:agentId',
  EditAgent: '/tenant/agent/edit/:agentId',
  EditAffiliate: '/tenant/affiliate/edit/:affiliateId',
  bonusUsers: '/tenant/bonus-users/:bonusId/:tenantId',
  createNewUser: '/tenant/create-new-user',
  lotteryTickets: '/tenant/lottery-tickets/:userId',
  bonusGames: '/tenant/bonus-games/:bonusId',
  TenantNotification: '/tenant/notification',
  TenantBonusNotification: '/tenant/bonus-notification',
  TenantBonusDetail: '/tenant/bonus-detail/:bonusId',
  VoucherDetail: '/tenant/voucher/details/:voucherId',
  voucherGames: '/tenant/voucher-games/:voucherId',
  TenantCasinoReporting: '/tenant/casino-reporting',
  voucherUsers: '/tenant/voucher-users/:voucherId/:tenantId',
  voucherTransactions: '/tenant/voucher-transactions',
  giftCardTransactions: '/tenant/gift-card-transactions',
  SportsReporting: '/tenant/bet-reporting',
  SportsTransactions: '/tenant/sports-reporting',
  SportsTransactionsV2: '/tenant/v2-sports-reporting',
  TenantUserReporting: '/tenant/user-reporting',
  TenantUserHistory: '/tenant/user-history',
  TenantSignin: '/tenant/signin',
  Dashboard: '/tenant/dashboard',
  CMS: '/tenant/CMS',
  CreateCMS: '/tenant/create-cms',
  CmsDetails: '/tenant/CMS/details/:cmsId',
  EditCms: '/tenant/CMS/edit/:cmsId',
  Voucher: '/tenant/voucher',
  GiftCard: '/tenant/gift-card',
  Events: '/tenant/events',
  Admins: '/tenant/admins',
  AdminUserDetails: '/tenant/admins/details/:adminUserId',
  CreateAdminUser: '/tenant/admins/create',
  Credentials: '/tenant/credentials',
  Languages: '/tenant/languages',
  CreateCredentials: '/tenant/create-credentials',
  UpdateCredentials: '/tenant/update-credentials/:credKey',
  Users: '/tenant/users',
  UsersV2: '/tenant/users-v2',
  UserDetails: '/tenant/users/details/:userId',
  UserDetailsV2: '/tenant/users-v2/details/:userId',
  BetslipDetails: '/tenant/bet-reporting/details/:betslipId',
  CreateUser: '/tenant/create-user',
  UpdateUser: '/tenant/update-user/:userId',
  TenantConfigurations: '/tenant/configurations',
  FormFields: '/tenant/form-fields',
  TenantThemeSettings: '/tenant/settings/themeSettings',
  CasinoCategory: '/tenant/casino-category',
  CasinoSubCategory: '/tenant/casino-sub-category',
  CasinoGames: '/tenant/casino-games',
  AddSubCategoryGames: '/tenant/casino-sub-category/add-games/:tenantGameSubCategoryId',
  EditAdminUser: '/tenant/admins/edit/:tenantAdminUserId',
  ReorderSubCategory: '/tenant/casino-sub-category/reorder',
  ReorderCategory: '/tenant/casino-category/reorder',
  TransactionsBanking: '/tenant/transactions-banking',

  TenantUserWallet: '/tenant/tenant-wallet',
  TenantWallet: '/tenant/tenant-wallet-report',

  UserDeposit: '/tenant/deposit',
  UserDepositHistory: '/tenant/deposit-history',

  VaultDepositHistory: '/tenant/vault-deposit-history',
  VaultWithdrawHistory: '/tenant/vault-withdraw-history',

  UserWithdrawal: '/tenant/withdrawal',
  UserWithdrawalHistory: '/tenant/withdrawal-history',

  V2UserDeposit: '/tenant/v2-deposit',
  V2UserDepositHistory: '/tenant/v2-deposit-history',

  V2UserWithdrawal: '/tenant/v2-withdrawal',
  V2UserWithdrawalHistory: '/tenant/v2-withdrawal-history',

  V2VaultDepositHistory: '/tenant/v2-vault-deposit-history',
  V2VaultWithdrawHistory: '/tenant/v2-vault-withdraw-history',

  CasinoTransactions: '/tenant/casino-transactions',
  WageringTemplate: '/tenant/wagering-template',
  WageringEdit: '/tenant/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/tenant/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/tenant/wagering-template/create',
  CreateBonus: '/tenant/bonus/create',
  EditBonus: '/tenant/bonus/edit/:bonusId',
  Bonus: '/tenant/bonus',
  BonusDetail: '/tenant/bonus/details/:bonusId',
  CloneBonus: '/tenant/bonus/clone/:bonusId',
  Loyalty: '/tenant/loyalty-management',
  EmailTemplate: '/tenant/email-templates',
  EditEmailTemplate: '/tenant/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/tenant/email-templates/create',
  Profile: '/tenant/profile',
  ImageGallery: '/tenant/image-gallery',
  BannerManagement: '/tenant/banner-management',
  GameReorder: '/tenant/casino-games/reorder',
  LanguageManagement: '/tenant/language-management',
  EditSupportLanguageKeys: '/tenant/language-management/edit/:keys',
  EditSupportLanguage: '/tenant/language-management/edit',
  AddLanguage: '/tenant/language-management/add-language/:lang',
  PaymentAggregators: '/tenant/cashier-management',
  BonusReorder: '/tenant/bonus/reorder',
  ReviewManagement: '/tenant/review-management',
  Sports: '/tenant/sports',
  SportsCountries: '/tenant/sports-countries',
  Matches: '/tenant/matches',
  Markets: '/tenant/markets',
  Leagues: '/tenant/leagues',
  Seasons: '/tenant/seasons',
  seasonDetail: '/tenant/seasons-detail/:seasonId/:tenantId',
  ReorderSports: '/tenant/sports/reorder',
  SportsTransactionsBetBy: '/tenant/sports-betBy-reporting',
  SportsReportingBetBy: '/tenant/bet-betBy-reporting'
}

// theme routes
export const CommonRoutes = {
  Home: '/',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/:role/reset-password',
  Lock: '/lock',
  NotFound: '/404',
  ServerError: '/500',
  TenantNotFound: '/406'
}
export const AffiliateRoutes = {
  AffiliateSignin: '/affiliate/signin',
  agentsList: '/affiliate/agents',
  createAgent: '/affiliate/create-agent',
  AgentDetail: '/affiliate/agent/details/:agentId',
  EditAgent: '/affiliate/agent/edit/:agentId',
  Users: '/affiliate/users',
  UserDetails: '/affiliate/users/details/:userId',
  CreateUser: '/affiliate/create-user',
  CasinoReporting: '/affiliate/casino-reporting',
  UserReporting: '/affiliate/user-reporting',
  UserHistory: '/affiliate/user-history',
  bonusTransactions: '/affiliate/bonus-transactions',
  sportsBonusTransactions: '/affiliate/sportsbook/bonus-transactions',
  Profile: '/affiliate/profile',
  Dashboard: '/affiliate/dashboard',
  bonusListing: '/affiliate/bonus-listing',
  bonusDetail: '/affiliate/bonus-detail/:bonusId',
  sportsBookBonusListing: '/affiliate/sports/bonus-listing',
  sportsBookBonusDetail: '/affiliate/sports/bonus-detail/:bonusId'

}
export const AgentRoutes = {
  AgentSignin: '/agent/signin',
  Users: '/agent/users',
  UserDetails: '/agent/users/details/:userId',
  CreateUser: '/agent/create-user',
  CasinoReporting: '/agent/casino-reporting',
  UserReporting: '/agent/user-reporting',
  UserHistory: '/agent/user-history',
  bonusTransactions: '/agent/bonus-transactions',
  sportsBonusTransactions: '/agent/sportsbook/bonus-transactions',
  Profile: '/agent/profile',
  Dashboard: '/agent/dashboard'

}
