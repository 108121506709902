import React from 'react'
import {
  Card,
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from '@themesberg/react-bootstrap'
import Select from 'react-select'

import { Formik, Form, ErrorMessage } from 'formik'
import { tenantSchema, editTenantSchema } from '../schema'
import useTenant from '../hooks/useTenant'
import Preloader from '../../../../components/Preloader'
import { isDomainExist } from '../../../../utils/apiCalls'
import { toast } from '../../../../components/Toast'
import useWithdraw from '../hooks/useWithdraw'
import LogoUpload from './UploadLogo'
import Trigger from '../../../../components/OverlayTrigger'
import { timeUnits } from '../constants'

const TenantForm = ({
  createTenantData,
  setCreateTenantData,
  setSelectedTab,
  setFieldValidation,
  setMyData
}) => {
  const {
    tenantId,
    currencies,
    setCurrencies,
    currOptions,
    navigate,
    loading,
    setAllowedProviders,
    allowedLanguages,
    langOptions,
    allowedProviders,
    providerOptions,
    setAllowedLanguages,
    editTenant,
    resetCurrencies,
    resetLanguages,
    resetProviders,
    tenantDetails,
    sportsbookProviders,
    allowedSportsProviders,
    setAllowedSportsProviders,
    t
  } = useTenant({
    createTenantData,
    setCreateTenantData
  })
  const { loading: withdrawLoading, createTenant } = useWithdraw()
  return (
    <>
      {loading || withdrawLoading
        ? (
          <Preloader />
          )
        : (
          <Card className='p-3'>
            <Card.Body>
              <Formik
                initialValues={{
                  domainName: createTenantData?.name || '',
                  domain: tenantDetails?.domain || '',
                  allowedCurrencies: createTenantData?.allowedCurrencies || [currencies[0]?.value],
                  primaryCurrency: createTenantData?.primaryCurrency || '',
                  logo: createTenantData?.logo || '',
                  allowedLanguages: createTenantData?.allowedLanguages || [allowedLanguages[0]?.value],
                  providerIds: createTenantData?.providerIds?.map((item) => ({
                    label: item?.name,
                    value: item?.masterCasinoProviderId
                  })) || [],
                  sportProviderIds: createTenantData?.sportProviders || [],
                  currency: tenantId ? tenantDetails?.tenantConfig?.withdrawlLimitCurrency : '',
                  withdrawalLimit: tenantId ? tenantDetails?.tenantConfig?.withdrawlLimit : '',
                  withdrawlLimitTime: tenantId ? tenantDetails?.tenantConfig?.withdrawlLimitTime : '',
                  withdrawlLimitTimeUnit: tenantId ? tenantDetails?.tenantConfig?.withdrawlLimitTimeUnit : '',
                  withdrawalRequestTime: tenantId ? tenantDetails?.tenantConfig?.withdrawalRequestTime : '',
                  withdrawalRequestTimeUnit: tenantId ? tenantDetails?.tenantConfig?.withdrawalRequestTimeUnit : '',
                  rollingPercent: tenantId ? tenantDetails?.rollingPercent : '',
                  ipLimit: tenantId ? tenantDetails?.tenantConfig?.ipLimit : '',
                  incorrectPasswordLimit: tenantId ? tenantDetails?.tenantConfig?.incorrectPasswordLimit : '',
                  revenuePercentage: tenantId ? tenantDetails?.tenantConfig?.revenuePercentage : ''

                }}
                validationSchema={tenantId ? editTenantSchema(createTenantData?.logo, t) : tenantSchema(t)}
                onSubmit={(formValues) => {
                  setCreateTenantData({
                    ...createTenantData,
                    ...formValues
                  })

                  const sportsProviderIdsList = allowedSportsProviders.map((o) => o.masterSportProviderId)

                  if (tenantId) {
                    editTenant({
                      createTenantData: {
                        ...createTenantData,
                        ...formValues,
                        providerIds: allowedProviders.map((o) => o.value),
                        sportProviderIds: sportsProviderIdsList
                      },
                      val: false,
                      tenantId
                    })
                    setFieldValidation(true)
                  } else {
                    createTenant({ ...createTenantData, ...formValues, sportProviderIds: sportsProviderIdsList }, setCreateTenantData, setSelectedTab)
                  }
                  if (!tenantId) setSelectedTab('credentials')
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  setSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  setFieldError,
                  resetForm,
                  setFieldTouched
                }) => {
                  return (
                    <Form>
                      <Row>
                        <Col>
                          <label
                            htmlFor='domainName'
                            className={touched.domainName && errors.domainName ? 'text-danger' : ''}
                          >
                            {t('name')}<span className='text-danger'> *</span>
                          </label>
                          <InputGroup
                            className={touched.domainName && errors.domainName ? 'border border-danger' : ''}
                          >
                            <BForm.Control
                              name='domainName'
                              type='text'
                              disabled={tenantId}
                              placeholder={t('enterName')}
                              value={values.domainName}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e)
                                setCreateTenantData({ ...createTenantData, domainName: e.target.value })
                              }}
                            />
                          </InputGroup>
                          <ErrorMessage component='div' name='domainName' className='text-danger' />
                        </Col>

                        <Col>
                          <label htmlFor='domain' className={touched.domain && errors.domain ? 'text-danger' : ''}>
                            {t('domain')}<span className='text-danger'> *</span>
                          </label>
                          <InputGroup className={touched.domain && errors.domain ? 'border border-danger' : ''}>
                            <BForm.Control
                              name='domain'
                              type='url'
                              placeholder={t('enterDomain')}
                              value={values.domain}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e)
                                setCreateTenantData({ ...createTenantData, domain: e.target.value })
                              }}
                            />
                          </InputGroup>
                          <ErrorMessage component='div' name='domain' className='text-danger' />
                        </Col>
                        <Col>
                          <BForm.Group className='mt-1' controlId='formBasicEmail'>
                            <label>
                              {t('logo')}<span className='text-danger'> *</span>
                            </label>

                            <BForm.Text>
                              <LogoUpload
                                createTenantData={createTenantData}
                                setFieldValue={setFieldValue}
                                setCreateTenantData={setCreateTenantData}
                                t={t}
                              />
                            </BForm.Text>
                            <ErrorMessage component='div' name='logo' className='text-danger' />
                          </BForm.Group>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        {/* Col for Allowed Currencies */}
                        <Col>
                          <BForm.Group className='mb-3'>
                            <label
                              className={touched.allowedCurrencies && errors.allowedCurrencies ? 'text-danger' : ''}
                            >
                              {t('allowedCurrencies')}<span className='text-danger'> *</span>
                            </label>

                            <Select
                              isMulti
                              name='allowedCurrencies'
                              value={currencies}
                              options={currOptions}
                              className='basic-multi-select'
                              classNamePrefix='select'
                              onBlur={() => {
                                if (currencies?.length < 1) {
                                  setFieldTouched('allowedCurrencies', true)
                                  setFieldError('allowedCurrencies', 'Required')
                                } else {
                                  setFieldTouched('allowedCurrencies', false)
                                  setFieldError('allowedCurrencies', null)
                                }
                              }}
                              onChange={(option, e) => {
                                setCurrencies(option)
                                setFieldValue('allowedCurrencies', option.map((o) => o.value))
                                if (option?.length < 1) {
                                  setFieldError('allowedCurrencies', 'Required')
                                } else {
                                  setFieldError('allowedCurrencies', null)
                                  setFieldTouched('allowedCurrencies', false)
                                }

                                setCreateTenantData({
                                  ...createTenantData,
                                  allowedCurrencies: option.map((o) => o.value)
                                })
                              }}
                            />

                            <ErrorMessage component='div' name='allowedCurrencies' className='text-danger' />
                          </BForm.Group>
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          {currencies?.length !== 0 && (
                            <BForm.Group className='mb-3'>
                              <label
                                className={
                                      touched.primaryCurrency && errors.primaryCurrency ? 'text-danger' : ''
                                    }
                              >
                                {t('primaryCurrency')}<span className='text-danger'> *</span>
                              </label>

                              <BForm.Select
                                name='primaryCurrency'
                                onChange={(e) => {
                                  setCreateTenantData({ ...createTenantData, primaryCurrency: e.target.value })
                                  handleChange(e)
                                }}
                                placeholder={t('selectPrimaryCurrency')}
                                defaultValue={createTenantData?.primaryCurrency || ''}
                              >
                                <option value='' disabled>{t('selectPrimaryCurrency')}</option>
                                {currencies?.map((currency, index) => (
                                  <option key={index} value={currency?.value}>{currency?.label}</option>
                                ))}
                              </BForm.Select>

                              <ErrorMessage component='div' name='primaryCurrency' className='text-danger' />
                            </BForm.Group>
                          )}

                        </Col>
                        <Col>
                          <BForm.Group className='mb-3'>
                            <label
                              className={touched.allowedLanguages && errors.allowedLanguages ? 'text-danger' : ''}
                            >
                              {t('allowedLanguages')}<span className='text-danger'> *</span>
                            </label>

                            <Select
                              isMulti
                              name='allowedLanguages'
                              value={allowedLanguages}
                              options={langOptions}
                              className='basic-multi-select'
                              classNamePrefix='select'
                              onBlur={() => {
                                if (allowedLanguages?.length < 1) {
                                  setFieldTouched('allowedLanguages', true)
                                  setFieldError('allowedLanguages', 'Required')
                                } else {
                                  setFieldTouched('allowedLanguages', false)
                                  setFieldError('allowedLanguages', null)
                                }
                              }}
                              onChange={(option, e) => {
                                if (e?.removedValue?.value === 'en') {
                                  setFieldValue('allowedLanguages', values.allowedLanguages)
                                  setAllowedLanguages(allowedLanguages)
                                } else {
                                  setAllowedLanguages(option)
                                  setFieldValue('allowedLanguages', option.map((o) => o.value))
                                  if (option?.length < 1) {
                                    setFieldError('allowedLanguages', 'Required')
                                  } else {
                                    setFieldTouched('allowedLanguages', false)
                                    setFieldError('allowedLanguages', null)
                                  }
                                  setCreateTenantData({
                                    ...createTenantData,
                                    allowedLanguages: option.map((o) => o.value)
                                  })
                                }
                              }}
                            />

                            <ErrorMessage component='div' name='allowedLanguages' className='text-danger' />
                          </BForm.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <BForm.Group className='mb-3'>
                            <label className={touched.providerIds && errors.providerIds ? 'text-danger' : ''}>
                              {t('allowedCasinoProviders')}<span className='text-danger'> *</span>
                            </label>

                            <Select
                              isMulti
                              isClearable
                              name='providerIds'
                              value={allowedProviders}
                              options={providerOptions}
                              className='basic-multi-select'
                              classNamePrefix='select'
                              onBlur={() => {
                                if (allowedProviders?.length < 1) {
                                  setFieldTouched('providerIds', true)
                                  setFieldError('providerIds', 'Required')
                                } else {
                                  setFieldTouched('providerIds', false)
                                  setFieldError('providerIds', null)
                                }
                              }}
                              onChange={(option, e) => {
                                setFieldValue('providerIds', option)
                                setAllowedProviders(option)
                                setFieldValue('providerIds', option.map((o) => o.value), false)
                                if (option?.length < 1) {
                                  setFieldError('providerIds', 'Required')
                                }
                              }}
                            />
                            <ErrorMessage component='div' name='providerIds' className='text-danger' />
                          </BForm.Group>
                        </Col>
                        <Col>
                          <BForm.Group className='mb-3'>
                            <label className={touched.providerIds && errors.providerIds ? 'text-danger' : ''}>
                              {t('allowedSportsbookProviders')}
                            </label>

                            <Select
                              allowedCasinoProviders
                              isMulti
                              isClearable
                              name='sportProviderIds'
                              value={allowedSportsProviders}
                              options={sportsbookProviders?.rows || []}
                              getOptionLabel={(option) => option?.name}
                              getOptionValue={(option) => option?.masterSportProviderId}
                              className='basic-multi-select'
                              classNamePrefix='select'
                              onBlur={() => {
                                if (allowedSportsProviders?.length < 1) {
                                  setFieldTouched('sportProviderIds', true)
                                  setFieldError('sportProviderIds', 'Required')
                                } else {
                                  setFieldTouched('sportProviderIds', false)
                                  setFieldError('sportProviderIds', null)
                                }
                              }}
                              onChange={(option, e) => {
                                setFieldValue('sportProviderIds', option)
                                setAllowedSportsProviders(option)
                                setFieldValue('sportProviderIds', option, false)
                                if (option?.length < 1) {
                                  setFieldError('sportProviderIds', 'Required')
                                }
                              }}
                            />
                            <ErrorMessage component='div' name='sportProviderIds' className='text-danger' />
                          </BForm.Group>
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col>
                          <label>
                            {t('withdrawalLimit')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            type='number'
                            name='withdrawalLimit'
                            maxLength={25}
                            placeholder={t('enterWithdrawLimit')}
                            value={values?.withdrawalLimit}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, withdrawalLimit: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage component='div' name='withdrawalLimit' className='text-danger' />
                        </Col>

                        <Col>
                          <label>
                            {t('currency')}<span className='text-danger'> *</span>
                          </label>
                          <BForm.Select
                            size='sm'
                            name='currency'
                            value={values?.currency}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, currency: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            style={{ height: '47px' }}
                          >
                            <option value=''>{t('selectCurrency')}</option>
                            {['KRW'].map((item, index) => (
                              <option key={item} value={item}>₩</option>
                            ))}
                          </BForm.Select>

                          <ErrorMessage component='div' name='currency' className='text-danger' />
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col>
                          <label>
                            {t('withdrawalLimitTime')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            type='number'
                            name='withdrawlLimitTime'
                            maxLength={25}
                            placeholder={t('enterWithdrawalLimitTime')}
                            value={values?.withdrawlLimitTime}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, withdrawlLimitTime: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage component='div' name='withdrawlLimitTime' className='text-danger' />
                        </Col>

                        <Col>
                          <label>
                            {t('withdrawalLimitTimeUnit')}<span className='text-danger'> *</span>
                          </label>
                          <BForm.Select
                            size='sm'
                            name='withdrawlLimitTimeUnit'
                            value={values?.withdrawlLimitTimeUnit}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, withdrawlLimitTimeUnit: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            style={{ height: '47px' }}
                          >
                            <option value=''>{t('selectTimeUnit')}</option>
                            {timeUnits?.map((unit) =>
                              (
                                <option
                                  key={unit.label}
                                  value={unit.value}
                                >
                                  {unit?.label}
                                </option>
                              )
                            )}
                          </BForm.Select>

                          <ErrorMessage component='div' name='withdrawlLimitTimeUnit' className='text-danger' />
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          <label>
                            {t('withdrawalRequestTime')}<span className='text-danger'> *</span>
                          </label>

                          <BForm.Control
                            type='number'
                            name='withdrawalRequestTime'
                            maxLength={25}
                            placeholder={t('enterWithdrawalRequestTime')}
                            value={values?.withdrawalRequestTime}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, withdrawalRequestTime: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage component='div' name='withdrawalRequestTime' className='text-danger' />
                        </Col>

                        <Col>
                          <label>
                            {t('withdrawalRequestTimeUnit')}<span className='text-danger'> *</span>
                          </label>
                          <BForm.Select
                            size='sm'
                            name='withdrawalRequestTimeUnit'
                            value={values?.withdrawalRequestTimeUnit}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, withdrawalRequestTimeUnit: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            style={{ height: '47px' }}
                          >
                            <option value=''>{t('selectTimeUnit')}</option>
                            {timeUnits?.map((unit) =>
                              (
                                <option
                                  key={unit.label}
                                  value={unit.value}
                                >
                                  {unit?.label}
                                </option>
                              )
                            )}
                          </BForm.Select>

                          <ErrorMessage component='div' name='withdrawalRequestTimeUnit' className='text-danger' />
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          <label>
                            {t('rollingPercent')}<span className='text-danger'> *</span>
                          </label>
                          <Trigger message={t('rollingPercentDescription')}>
                            <BForm.Control
                              type='number'
                              name='rollingPercent'
                              maxLength={25}
                              placeholder={t('enterRollingPercent')}
                              value={values?.rollingPercent}
                              onChange={(e) => {
                                setCreateTenantData({ ...createTenantData, rollingPercent: e.target.value })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                            />
                          </Trigger>

                          <ErrorMessage component='div' name='rollingPercent' className='text-danger' />
                        </Col>
                        <Col>
                          <label>
                            {t('ipLimit')}<span className='text-danger'> *</span>
                          </label>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='ipLimitTooltip'>The maximum number of IPs allowed per user to access the site</Tooltip>}
                          >
                            <BForm.Control
                              type='number'
                              name='ipLimit'
                              maxLength={25}
                              placeholder={t('ipLimitPlaceholder')}
                              value={values?.ipLimit}
                              onChange={(e) => {
                                setCreateTenantData({ ...createTenantData, ipLimit: e.target.value })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                            />
                          </OverlayTrigger>

                          <ErrorMessage component='div' name='ipLimit' className='text-danger' />
                        </Col>

                      </Row>
                      <Row className='mt-3'>
                        <Col>
                          <label>
                            {t('incorrectPasswordLimit')}<span className='text-danger'> *</span>
                          </label>
                          <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='incorrectPasswordLimit'>The maximum number of Incorrect password allowed per user to access the site</Tooltip>}
                          >
                            <BForm.Control
                              type='number'
                              name='incorrectPasswordLimit'
                              maxLength={25}
                              placeholder={t('incorrectPasswordLimitPlaceholder')}
                              value={values?.incorrectPasswordLimit}
                              onChange={(e) => {
                                setCreateTenantData({ ...createTenantData, incorrectPasswordLimit: e.target.value })
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                            />
                          </OverlayTrigger>

                          <ErrorMessage component='div' name='incorrectPasswordLimit' className='text-danger' />
                        </Col>
                        <Col>
                          <label>
                            {t('revenuePercentage')}<span className='text-danger'> *</span>
                          </label>
                          <BForm.Control
                            type='number'
                            name='revenuePercentage'
                            maxLength={25}
                            placeholder={t('enterValueInPercent')}
                            value={values?.revenuePercentage}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, revenuePercentage: e.target.value })
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />

                          <ErrorMessage component='div' name='revenuePercentage' className='text-danger' />
                        </Col>

                      </Row>

                      <div className='d-flex justify-content-between mt-3'>
                        <Button
                          onClick={() => {
                            navigate(-1)
                            if (tenantId) {
                              resetForm()
                              resetCurrencies()
                              resetLanguages()
                              resetProviders()
                              setMyData()
                            }
                          }}
                          variant='outline-warning'
                        >
                          {t('cancel')}
                        </Button>

                        {tenantId
                          ? (
                            <Button
                              onClick={() => {
                                if (Object.keys(errors)?.length > 1) {
                                  Object.keys(errors).map((key) => setFieldTouched(key, true))
                                  return
                                }
                                if (createTenantData.domain !== values.domain) {
                                  isDomainExist({ domain: values.domain, tenantId: tenantId || '' })
                                    .then(() => handleSubmit())
                                    .catch((err) => {
                                      setSubmitting(false)
                                      toast(err.response.data.message, 'error')
                                    })
                                } else {
                                  handleSubmit()
                                }
                              }}
                              variant='outline-success'
                            >
                              {tenantId ? t('submit') : t('next')}
                            </Button>
                            )
                          : (
                            <Button onClick={handleSubmit} variant='outline-success'>
                              {t('submit')}
                            </Button>
                            )}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
          )}
    </>
  )
}

export default TenantForm
