import React from 'react'
import { sportsTransactionsTable } from './constant'
import { Table } from '@themesberg/react-bootstrap'
import moment from 'moment'
import PaginationComponent from '../Pagination'
import { formatNumber } from '../../utils/storageUtils'

const SportsTransactionsTable = ({
  tableData,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  loading,
  t,
  sortHandler,
  isV2User,
  isBetBy
}) => {
  return (
    <div>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {sportsTransactionsTable(isV2User, isBetBy).map((h, idx) => (
              <th
                style={{ cursor: ['date', 'amount'].includes(h.label) ? 'pointer' : '' }}
                key={h.label} onClick={() => {
                  if (['date', 'amount'].includes(h.label)) {
                    sortHandler(h.value)
                  }
                }}
              >
                {t(h.label)} &nbsp; {['date', 'amount'].includes(h.label) ? <span style={{ position: 'relative', top: '-1px' }}>&#8597;</span> : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {
            !loading && tableData &&
       tableData?.rows?.map(
         (item, index) => {
           return (
             <tr key={index}>
               <td>{(page - 1) * limit + index + 1}</td>
               <td>
                 {t(item?.username) || t('na')}
               </td>
               {!isBetBy &&
                 <td>
                   {
                   Number(item?.amount)?.toFixed(2) + ' ₩' || t('na')
                 }
                 </td>}
               <td>
                 {item?.journalEntry ? t(item?.journalEntry) : t('na')}
               </td>
               <td>
                 {item?.status ? t(item?.status) : t('na')}
               </td>
               <td>
                 {formatNumber(item?.beforeBalance) + ' ₩' || t('na')}
               </td>
               <td>
                 {formatNumber(item?.currentBalance) + ' ₩' || t('na')}
               </td>
               {!isBetBy &&
                 <td>
                   {formatNumber(item?.nonCashAmount) + ' ₩' || t('na')}
                 </td>}

               {isBetBy &&
                 <>
                   <td>
                     <div className='d-flex flex-column gap-1'>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('promotionCash')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPromotionCashAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('dailyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionDailyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('weeklyPaybachBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionWeeklyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('biWeeklyPayBackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionBiWeeklyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('monthlyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionMonthlyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                     </div>
                   </td>
                   <td>
                     <div className='d-flex flex-column gap-1'>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutPromotionCash')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutPromotionCashAmount || 0) + ' ₩'}</b>
                       </div>
                       {/* <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutBettingBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutBettingBonusAmount || 0) + ' ₩'}</b>
                       </div> */}
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutDailyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutDailyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutWeeklyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutWeeklyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutBiWeeklyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutBiWeeklyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutMonthlyPaybackBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutMonthlyPaybackBonusAmount || 0) + ' ₩'}</b>
                       </div>
                       {/* <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutCompensationBonus')}:
                         </span>
                         <b> {formatNumber(item?.transactionMapping?.transactionPayoutBetCompensationBonusAmount || 0) + ' ₩'}</b>
                       </div> */}
                     </div>
                   </td>
                   <td>
                     {formatNumber(item?.nonWithdrawableAmount) + ' ₩'}
                   </td>
                   <td>
                     {formatNumber(item?.withdrawableAmount) + ' ₩'}
                   </td>
                   <td>
                     {formatNumber(item?.promotionCash) + ' ₩'}
                   </td>
                 </>}

               {(isV2User && !isBetBy) &&
                 <>
                   <td>
                     <div className='d-flex flex-column gap-1'>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('bettingBonus')}:
                         </span>
                         <b title={item?.bonusDetails}> {item?.bonusDetails?.bettingBonus ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('betCompenSationBonus')}:
                         </span>
                         <b title={item?.bonusDetails}> {item?.bonusDetails?.betCompesation ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('mixedMatchBonus')}:
                         </span>
                         <b title={item?.bonusDetails}> {item?.bonusDetails?.mixedMatch ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('winningBonus')}:
                         </span>
                         <b title={item?.bonusDetails}> {item?.bonusDetails?.winningBonus ?? 0} ₩</b>
                       </div>
                     </div>
                   </td>
                   <td>
                     <div className='d-flex flex-column gap-1'>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutBetCompenSationBonus')}:
                         </span>
                         <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutBetCompesation ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutBettingBonus')}:
                         </span>
                         <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutBettingBonus ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutMixedMatchBonus')}:
                         </span>
                         <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutMixedMatch ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutPromotionCash')}:
                         </span>
                         <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutPromotionCash ?? 0} ₩</b>
                       </div>
                       <div className='d-flex align-items-center gap-1'>
                         <span className='flex-title'>
                           {t('payoutWinningBonus')}:
                         </span>
                         <b title={item?.payoutBonusDetails}> {item?.payoutBonusDetails?.payoutWinningBonus ?? 0} ₩</b>
                       </div>
                     </div>
                   </td>
                 </>}
               <td>
                 {t(item?.description) || t('na')}
               </td>
               <td>
                 {moment(item?.createdAt).format('MM-DD-YYYY HH:mm') || t('na')}
               </td>
             </tr>
           )
         }
       )
          }
          {!loading && (tableData?.count === 0 || !tableData)
            ? (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
              )
            : <></>}
        </tbody>
      </Table>
      {!loading && tableData?.count !== 0 &&
            (
              <PaginationComponent
                page={tableData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                totalCount={tableData?.count}
              />
            )}
    </div>
  )
}
export default SportsTransactionsTable
