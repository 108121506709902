import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllTenantsListStart } from '../../../store/redux-slices/tenants'
import { debounce } from '../../../utils/debounce'
import { getItem, setItem } from '../../../utils/storageUtils'
import { resetNewNotification, setIsNewNotification } from '../../../store/redux-slices/bonus'
import { useTranslation } from 'react-i18next'
import { ADMIN_ROLES, NOTIFICATION_TYPE } from '../../../utils/constants'
import { AffiliateRoutes, TenantRoutes, SuperAdminRoutes } from '../../../routes'
import { deleteAdminNotificationStart, getAllAdminNotificationStart, markAdminNotificationReadStart } from '../../../store/redux-slices/headerNotification'

const useHeader = ({ isTenantRoute, isAffiliate, isAgent }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [notificationType, setNotificationType] = useState('')
  const limit = 20
  const [page, setPage] = useState(1)
  const role = getItem('role')
  const { isNewNotification, newNotificationData } = useSelector(state => state.bonus)
  const {
    notification,
    notificationLoading,
    updateLoading,
    deleteLoading
  } = useSelector(state => state.headerNotification)
  const { adminDetails } = useSelector(state => state.admins)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading, tenantsList } = useSelector((state) => state.tenants)
  const [tenantDetails, setTenantDetails] = useState()
  const handleNavigate = (item) => {
    if (item?.type === NOTIFICATION_TYPE.WALLET_WITHDRAW_REQUEST) {
      navigate(SuperAdminRoutes.V2UserWithdrawal)
    } else if (item?.type === NOTIFICATION_TYPE.WALLET_DEPOSIT_REQUEST) {
      navigate(SuperAdminRoutes.V2UserDeposit)
    } else if (item?.type === NOTIFICATION_TYPE.KYC_REQUEST) {
      navigate(`/super-admin/users/details/${item?.userId}`)
    } else if (item?.type === NOTIFICATION_TYPE.INCORRECT_PASSWORD) {
      navigate('/super-admin/security-alerts')
    } else if (item?.type === NOTIFICATION_TYPE.BONUS) {
      if (isAffiliate) {
        navigate(AffiliateRoutes.bonusListing)
      } else if (isTenantRoute) {
        navigate(TenantRoutes.TenantBonusNotification)
      }
    }
    handleCloseNotification()
  }
  const loadMoreData = () => {
    const count = notification?.totalNotification
    const totalPages = Math.ceil(count / limit)
    if (page < Number(totalPages)) {
      setPage(page + 1)
    }
  }
  const handleReset = () => {
    setPage(1)
    setNotificationType('')
  }
  const handleCloseNotification = () => {
    setIsNotificationOpen(false)
    handleReset()
  }
  const fetchNotifications = (page) => {
    dispatch(getAllAdminNotificationStart({ formData: { limit: limit, pageNo: page, type: notificationType || '' } }))
  }

  const handleCheckNotification = () => {
    setIsNotificationOpen(true)
    dispatch(setIsNewNotification(false))
    fetchNotifications(1)
  }
  const handleMarkReadNotification = (data) => {
    dispatch(markAdminNotificationReadStart({
      formData: { ...data },
      onSuccess: () => {
        fetchNotifications(1)
      }
    }))
  }
  const handleDeleteNotification = (data) => {
    dispatch(deleteAdminNotificationStart({
      formData: { ...data },
      onSuccess: () => {
        fetchNotifications(1)
        dispatch(resetNewNotification())
      }
    }))
  }
  const handleMarkReadSingleNotification = (data) => {
    dispatch(markAdminNotificationReadStart({
      formData: { ...data },
      isSingle: true
    }))
  }
  const handleSingleDeleteNotification = (data) => {
    dispatch(deleteAdminNotificationStart({
      formData: { ...data },
      isSingle: true
    }))
  }
  const tenantChangeHandler = (id) => {
    const details = tenantsList?.rows?.filter((item) => item.tenantId === Number(id))
    setItem('tenant-id', id)
    setTenantDetails(details[0])
  }

  useEffect(() => {
    if (tenantsList && tenantsList.rows && tenantsList.rows.length > 0) {
      tenantChangeHandler(getItem('tenant-id'))
    } else {
      setTenantDetails()
    }
  }, [tenantsList])

  useEffect(() => {
    if (tenantsList === null) {
      if (isTenantRoute) { debounce(dispatch(getAllTenantsListStart({})), 1000) }
    }
  }, [])
  useEffect(() => {
    if (role === ADMIN_ROLES?.SUPERADMIN) {
      fetchNotifications(page)
    }
  }, [page, notificationType])

  return {
    handleMarkReadNotification,
    handleDeleteNotification,
    handleNavigate,
    isNewNotification,
    navigate,
    loading,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    newNotificationData,
    handleCheckNotification,
    t,
    role,
    adminDetails,
    isNotificationOpen,
    notification,
    notificationLoading,
    handleMarkReadSingleNotification,
    handleSingleDeleteNotification,
    updateLoading,
    deleteLoading,
    handleCloseNotification,
    loadMoreData,
    page,
    limit,
    notificationType,
    setNotificationType,
    setPage

  }
}

export default useHeader
