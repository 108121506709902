import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  ListGroup,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faEye,
  faRedoAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useTenantUserListing from './hooks/useTenantUserListing'
import Trigger from '../../../components/OverlayTrigger'
import Reason from '../UserDetails/components/Reason'
import useCheckPermission from '../../../utils/checkPermission'
import { countryFilter } from '../../../utils/countryFilter'
import { kycLabels } from '../../Super-Admin/Users/userConstant'
import moment from 'moment'
import { AffiliateRoutes, AgentRoutes, TenantRoutes } from '../../../routes'
import NoteModal from '../../../components/NoteModal'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import useIsGamma from '../../../common/hook/useIsGamma'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import { formatNumber } from '../../../utils/storageUtils'
import { isAnonymousFilter, tableHeaders, tableHeadersV2 } from './constant'

export default ({ affiliateId, isAffiliate, isAgent, isUserV2 }) => {
  const {
    navigate,
    limit,
    page,
    search,
    showModal,
    status,
    handleClose,
    setLimit,
    setPage,
    setSearch,
    setActiveFilter,
    setShowModal,
    setAffiliateSearch,
    allUsers,
    totalPages,
    setKycOptions,
    updateTenantUserStatus,
    loading,
    name,
    setState,
    setUserId,
    regState,
    setRegState,
    setIsInternal,
    setOrderBy,
    setSortBy,
    countries,
    setSelectedCountry,
    selectedCountry,
    // tableHeaders,
    setRedFlagFilter,
    flagFilter,
    kycOptions,
    initialDate,
    t,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleNote,
    kycSeniorOptions,
    setKycSeniorOptions,
    agent,
    setAgent,
    affiliate,
    setAffiliate,
    totalPlayerAmount,
    isAnonymous,
    setIsAnonymous
  } = useTenantUserListing({ affiliateId, isAffiliate, isAgent, isUserV2 })
  const { isHidden } = useCheckPermission()
  const {
    isGamma
  } = useIsGamma()
  const tableHeader = isUserV2 ? tableHeadersV2 : tableHeaders
  return (
    <div className='p-3'>
      <div className='d-flex justify-content-between gap-2 align-items-center'>
        <h3>
          <FontAwesomeIcon icon={faUsers} />
          &nbsp; {t('players')}
        </h3>
        {!isGamma && (
          <Button
            onClick={() => {
              isAgent
                ? navigate(AgentRoutes.CreateUser)
                : isAffiliate
                  ? navigate(AffiliateRoutes.CreateUser)
                  : navigate(TenantRoutes.createNewUser)
            }}
            variant='outline-success'
          >
            {t('addNewPlayer')}
          </Button>
        )}
      </div>

      <Row xs='auto'>
        <AffiliateAgentFilter
          agent={agent}
          setAgent={setAgent}
          affiliate={affiliate}
          setAffiliate={setAffiliate}
        />
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('status')}
          </Form.Label>
          <Form.Select
            onChange={(e) => {
              setRedFlagFilter(e.target.value)
              setPage(1)
            }}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              fontSize: '13px'
            }}
            value={flagFilter}
          >
            <option value=''>{t('all')}</option>
            <option value='RedFlag'>{t('redFlag')}</option>
            <option value='BlackList'>{t('blackList')}</option>
            <option value='Normal'>{t('normal')}</option>
          </Form.Select>
        </Col>

        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('kycStatus')}
          </Form.Label>
          <Form.Select
            onChange={(e) => {
              setKycOptions(e.target.value)
              setPage(1)
            }}
            value={kycOptions}
            style={{ width: 'auto', marginRight: '10px', height: '36px' }}
            size='sm'
          >
            {kycLabels.map((item) => {
              return (
                <option key={`kycLabels${item.value}`} value={item.value}>
                  {t(item.label)}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('kycStatusSenior')}
          </Form.Label>
          <Form.Select
            onChange={(e) => {
              setKycSeniorOptions(e.target.value)
              setPage(1)
            }}
            value={kycSeniorOptions}
            style={{ width: 'auto', marginRight: '10px', height: '36px' }}
            size='sm'
          >
            {kycLabels.map((item) => {
              return (
                <option key={`kycLabels${item.value}`} value={item.value}>
                  {t(item.label)}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>

          <Form.Control
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              fontSize: '13px'
            }}
            value={search}
            onChange={(event) =>
              setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
          />
        </Col>
        <Col className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('registrationDateStart')}
            value={regState[0].startDate}
            onChange={(val) => {
              setRegState([{ ...regState[0], startDate: val }])
            }}
          />
        </Col>
        <Col className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('registrationDateEnd')}
            value={regState[0].endDate}
            onChange={(val) => {
              setRegState([{ ...regState[0], endDate: val }])
            }}
          />
        </Col>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            className='flex-shrink-0'
            style={{
              width: 'auto',
              marginRight: '15px',
              fontSize: '13px',
              marginTop: '5px'
            }}
          >
            {t('anonymousUser')}
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setIsAnonymous(e.target.value)
            }}
            value={isAnonymous}
            style={{ width: 'auto', marginRight: '10px' }}
            size='sm'
          >
            {isAnonymousFilter.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {t(item.label)}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        {countries && Array.isArray(countries) && (
          <Col className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                marginBottom: '0',
                marginTop: '5px',
                minWidth: '70px',
                fontSize: '13px'
              }}
            >
              {t('country')}
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setSelectedCountry(e.target.value)
                setPage(1)
              }}
              value={selectedCountry || ''}
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
              size='sm'
            >
              <option value=''>{t('selectCountry')}</option>
              {countries?.map((item, idx) => {
                return (
                  <option key={`countryNames${idx}`} value={item.code}>
                    {t(item.name)}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        )}

        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setAffiliateSearch('')
                setUserId('')
                setLimit(15)
                setPage(1)
                setOrderBy('userId')
                setIsInternal(false)
                setSortBy('desc')
                setSelectedCountry('')
                setKycOptions('')
                setKycSeniorOptions('')
                setState([
                  {
                    startDate: new Date('01 July 1923'),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setRegState(initialDate)
                setIsInternal(false)
                setActiveFilter('')
                setRedFlagFilter('')
                setAgent('')
                setAffiliate('')
                setIsAnonymous(false)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <ListGroup.Item>
        <Table
          bordered
          striped
          responsive
          hover
          size='sm'
          className='text-center mt-4'
        >
          <thead className='thead-dark'>
            <tr>
              {tableHeader?.map((h, idx) => (
                <th key={idx}>{t(h.label)} </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading &&
              Boolean(allUsers) &&
              allUsers?.rows?.map((item, index) => {
                const { countryImage, countryName } = countryFilter(
                  item?.countryCode
                )

                return (
                  <tr key={item?.userId}>
                    <td>{index + 1 + limit * (page - 1)}</td>
                    <td>{item?.username || 'NA'} </td>
                    <td>{item?.['affiliate.username'] || '-'} </td>
                    <td>{item?.['agent.username'] || '-'} </td>
                    <td>
                      {item?.createdAt
                        ? moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss')
                        : 'NA'}
                    </td>

                    <td>
                      {!countryName
                        ? (
                            'Other'
                          )
                        : (
                          <Trigger message={countryName}>
                            <img
                              width='25px'
                              src={countryImage}
                              alt={countryImage}
                            />
                          </Trigger>
                          )}
                    </td>
                    <td>{item?.kycStatus || 'NA'} </td>
                    <td>{item?.kycStatusSenior || 'NA'} </td>
                    <td>
                      {formatNumber(item?.['userWallet.vaultAmount']) + ' ₩'}
                    </td>
                    {!isUserV2 && (
                      <td>
                        {(item?.isV2User
                          ? 0
                          : formatNumber(item?.['userWallet.amount'])) +
                          ' ₩'}{' '}
                      </td>
                    )}
                    <td>
                      {(item?.isV2User
                        ? formatNumber(item?.['userWallet.withdrawableAmount'])
                        : 0) + ' ₩'}{' '}
                    </td>
                    <td>
                      {(item?.isV2User
                        ? formatNumber(
                            item?.['userWallet.nonWithdrawableAmount']
                          )
                        : 0) + ' ₩'}{' '}
                    </td>
                    {!isUserV2 && (
                      <td>
                        {(item?.isV2User
                          ? 0
                          : formatNumber(item?.['userWallet.nonCashAmount'])) +
                          ' ₩'}{' '}
                      </td>
                    )}
                    {!isUserV2 && (
                      <td>
                        {(item?.isV2User
                          ? formatNumber(
                              item?.['userWallet.totalPromotionCash']
                            )
                          : 0) + ' ₩'}{' '}
                      </td>
                    )}
                    {!isUserV2 && (
                      <td>
                        {formatNumber(
                          item?.['userBonusWallet.totalBonusAmount'] +
                            item?.['userWallet.sportsBonusWalletAmount'] ?? 0
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    {!isUserV2 && (
                      <td>
                        {formatNumber(
                          (item?.['userCasinoPayout.totalCasinoPayoutAmount'] ??
                            0) +
                            (item?.[
                              'userSportsPayout.totalSportsPayoutAmount'
                            ] ?? 0)
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {(item?.isV2User
                          ? formatNumber(
                              item?.['userWallet.casinoPromotionCash']
                            )
                          : 0) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {(item?.isV2User
                          ? formatNumber(
                              item?.['userWallet.sportsPromotionCash']
                            )
                          : 0) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {formatNumber(
                          item?.['userBonusWallet.totalBonusAmount'] ?? 0
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {formatNumber(
                          item?.['userWallet.sportsBonusWalletAmount'] ?? 0
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {formatNumber(
                          item?.['userCasinoPayout.totalCasinoPayoutAmount'] ??
                            0
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    {isUserV2 && (
                      <td>
                        {formatNumber(
                          item?.['userSportsPayout.totalSportsPayoutAmount'] ??
                            0
                        ) + ' ₩'}{' '}
                      </td>
                    )}
                    <td>
                      <Trigger message={item?.superadminNotes}>
                        <div>{item?.superadminNotes ?? '-'}</div>
                      </Trigger>
                    </td>
                    <td>
                      <Trigger message={item.seniorNotes}>
                        <div>{item?.seniorNotes ?? '-'}</div>
                      </Trigger>
                    </td>
                    <td>
                      {formatNumber(
                        item?.withdrawlLimit
                          ? item?.levelWithdrawlLimit
                              ? Number(item?.withdrawlLimit) +
                              Number(item?.levelWithdrawlLimit)
                              : item?.withdrawlLimit
                          : Number(
                            item?.['tenant.tenantConfig.withdrawlLimit'] ?? 0
                          ) + Number(item?.levelWithdrawlLimit ?? 0) || ''
                      )}{' '}
                      {item?.withdrawlLimitCurrency || ' ₩'}
                    </td>
                    <td>{item?.currentIPAddress || item?.signInIp}</td>
                    <td>{item?.signInIp || '-'}</td>
                    <td
                      className={
                        item?.isFlaggedRed ? 'table-danger' : 'table-success'
                      }
                    >
                      {item?.isFlaggedRed ? t('yes') : t('no') || 'NA'}
                    </td>
                    <td
                      className={
                        item?.isActive ? 'table-success' : 'table-danger'
                      }
                    >
                      {item?.isActive ? 'Normal' : 'Blacklist' || 'NA'}
                    </td>
                    <td>
                      <ButtonGroup>
                        <Trigger message={t('viewDetails')}>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({
                              module: { key: 'Users', value: 'R' }
                            })}
                            onClick={() =>
                              isAgent
                                ? navigate(
                                    `/agent/users/details/${item?.userId}`
                                  )
                                : isAffiliate
                                  ? navigate(
                                    `/affiliate/users/details/${item?.userId}`
                                    )
                                  : navigate(
                                    `/tenant/${
                                      isUserV2 ? 'users-v2' : 'users'
                                    }/details/${item?.userId}`
                                  )}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>

                        {!isAffiliate && !isAgent && (
                          <Trigger message={t('addNote')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() => {
                                setAddNoteModal(true)
                                setIndividualUserData(
                                  item?.userId,
                                  item?.username
                                )
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>
                        )}
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              })}

            {allUsers?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ListGroup.Item>
      {addNoteModal
        ? (
          <NoteModal
            addNoteModal={addNoteModal}
            setAddNoteModal={setAddNoteModal}
            individualUserData={individualUserData}
            handleSubmit={handleNote}
          />
          )
        : (
          <></>
          )}

      {totalPlayerAmount && (
        <>
          <div className='d-flex gap-4 align-items-center py-4 justify-content-end'>
            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalAmount) + ' ₩'}
                </div>
              </div>
            </div>
            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalWithdrawableAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalWithdrawable) + ' ₩'}
                </div>
              </div>
            </div>
            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalNonWithdrawableAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalNonWithdrawable) + ' ₩'}
                </div>
              </div>
            </div>
            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalPromotionCash')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalPromotionCash) + ' ₩'}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-4 align-items-center py-4 justify-content-end'>
            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalPayoutAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalPayout) + ' ₩'}
                </div>
              </div>
            </div>

            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalBonusWalletAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalBonusWalletAmount) +
                    ' ₩'}
                </div>
              </div>
            </div>

            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalNonCashAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalNonCashAmount) + ' ₩'}
                </div>
              </div>
            </div>

            <div className=''>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalVaultAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>
                  {formatNumber(totalPlayerAmount?.totalVaultAmount) + ' ₩'}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {allUsers?.count !== 0 && !loading && (
        <PaginationComponent
          page={allUsers?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={allUsers?.count}
        />
      )}

      {showModal &&
        (!status
          ? (
            <Reason
              show={showModal}
              handleClose={handleClose}
              markUserStatusInactive={updateTenantUserStatus}
              name={name}
            />
            )
          : (
            <ConfirmationModal
              show={showModal}
              setShow={setShowModal}
              handleYes={() => {
                updateTenantUserStatus()
                setShowModal(false)
              }}
              active={status}
              name={name}
            />
            ))}
    </div>
  )
}
