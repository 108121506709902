import React from 'react'
import { Row, Col } from '@themesberg/react-bootstrap'
import { Button, Form, Table } from 'react-bootstrap'
import ProvidersFilter from '../../../../../components/ProviderFilter'
import Trigger from '../../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faWallet } from '@fortawesome/free-solid-svg-icons'
import InputDatePicker from '../../../../../components/FormElements/InputDatePicker/InputDatePicker'
import { formatNumber } from '../../../../../utils/storageUtils'

export default ({
  isUserSenior,
  title,
  providerDataStat,
  tableHeaders,
  selectedProvider,
  setSelectedProvider,
  selectedCategory,
  setSelectedCategory,
  gameCategory,
  state,
  setState,
  initialDate,
  t,
  setTransactionType,
  transactionType,
  result,
  tableHeaderSeniorUser,
  isAffiliate,
  isAgent
}) => {
  const wagerType = {
    WIN: 'totalWinAmount',
    BET: 'totalBetAmount',
    REFUND: 'totalRefundAmount',
    CANCEL: 'totalCancelAmount',
    LOST: 'totalLostAmount',
    'JACKPOT-WIN': 'totalJackpotAmount',
    TIP: 'totalTipAmount'
  }
  const transactionTypeheader = [
    { label: 'request', value: 'REQUEST' },
    { label: 'nonRequest', value: 'NON-REQUEST' }
  ]
  return (
    <div className='px-2'>
      <Row>
        <Col xs='auto'>
          <Col xs='auto' className='mb-3'>
            <h3><FontAwesomeIcon icon={faWallet} />&nbsp;{isUserSenior ? t('seniorTransactionStats') : t('transactionStats')}</h3>
          </Col>
        </Col>
      </Row>
      <Row>
        {!isUserSenior
          ? (
            <Col className='d-flex align-items-center'>
              <ProvidersFilter
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
                t={t}
                isTenantRoute
                isAffiliate={isAffiliate}
                isAgent={isAgent}
              />
              <Form.Label
                column='sm'
                style={{
                  width: 'auto',
                  marginBottom: '0',
                  marginRight: '15px',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                {t('category')}
              </Form.Label>
              <Form.Select
                onChange={(e) => {
                  setSelectedCategory(e.target.value)
                }}
                value={selectedCategory}
                size='sm'
                style={{
                  marginBottom: '0',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                <option value=''>{t('all')}</option>
                {gameCategory && gameCategory?.rows?.map((category) => {
                  return <option key={category?.categoryid} value={category?.categoryid}>{category?.categoryname}</option>
                })}
              </Form.Select>

            </Col>
            )
          : <></>}
        <Col className='d-flex align-items-center'>
          <InputDatePicker
            isFilter
            label={t('from')}
            value={state[0].startDate}
            onChange={(val) => {
              setState([{ ...state[0], startDate: val }])
            }}
          />
        </Col>
        <Col className='d-flex align-items-center'>
          <InputDatePicker
            isFilter
            label={t('to')}
            value={state[0].endDate}
            onChange={(val) => {
              setState([{ ...state[0], endDate: val }])
            }}
          />
        </Col>
        {
        isUserSenior
          ? (
            <Col className='d-flex align-items-center'>
              <Form.Label
                column='sm'
                style={{
                  width: 'auto',
                  marginBottom: '0',
                  marginRight: '15px',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                {t('transactionType')}
              </Form.Label>
              <Form.Select
                onChange={(e) => {
                  setTransactionType(e.target.value)
                }}
                value={transactionType}
                size='sm'
                style={{
                  marginBottom: '0',
                  marginTop: '5px',
                  fontSize: '13px'
                }}
              >
                <option value=''>{t('all')}</option>
                {transactionTypeheader.map((item) => {
                  return <option key={item} value={item.value}>{t(item.label)}</option>
                })}
              </Form.Select>
            </Col>
            )
          : (<></>)
       }
        <Col className='d-flex align-items-center'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSelectedProvider('')
                setSelectedCategory('')
                setState(initialDate)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {(isUserSenior ? tableHeaderSeniorUser : tableHeaders).map((h, idx) => (
              <th key={idx}>
                {t(h.label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          {!isUserSenior
            ? (
                providerDataStat && providerDataStat?.providerTransactions?.length !== 0
                  ? (
                      providerDataStat?.providerTransactions?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{item?.actionType || '-'}</td>
                            <td>{item?.totalCount ? formatNumber(item?.totalCount) : '-'}</td>
                            <td>{item[wagerType[item?.actionType]] ? formatNumber(item[wagerType[item?.actionType]]) : '-'}</td>
                          </tr>
                        )
                      })
                    )
                  : (
                    <tr>
                      <td colSpan={8} className='text-danger text-center'>
                        {t('noDataFound')}
                      </td>
                    </tr>
                    )
              )
            : (
                result && result?.length !== 0
                  ? (
                      result?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.role || '-'}</td>
                            <td>{formatNumber(item?.TotalDepositAmount) || '-'}</td>
                            <td>{formatNumber(item?.TotalDepositCount) || '-'}</td>
                            <td>{formatNumber(item?.TotalWithdrawlAmount) || '-'}</td>
                            <td>{formatNumber(item?.TotalWithdrawalCount) || '-'}</td>
                            <td>{formatNumber(Number(item?.TotalDepositAmount) - Number(item?.TotalWithdrawlAmount)) || '-'}</td>
                          </tr>
                        )
                      })
                    )
                  : (
                    <tr>
                      <td colSpan={8} className='text-danger text-center'>
                        {t('noDataFound')}
                      </td>
                    </tr>
                    )
              )}

        </tbody>
      </Table>
    </div>
  )
}
