import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { TenantRoutes } from '../../../routes'
import Cms from '../../Tenant/CMS'
import Admin from '../../Tenant/Admins'
import CreateAdminUser from '../../Tenant/Admins/components/CreateAdminUser'
import TenantCredentials from '../../Tenant/TenantCredentials'
import CreateCredentials from '../../Tenant/TenantCredentials/components/CreateCredentials'
import EditCredentials from '../../Tenant/TenantCredentials/components/EditCredentials'
import Users from '../../Tenant/Users'
import UserDetails from '../../Tenant/UserDetails'
import CreateTenantUser from '../../Tenant/Users/components/CreateTenantUser'
import TenantConfigurations from '../../Tenant/TenantConfigurations'
import FormFields from '../../Tenant/FormFields'
import EditTenantUser from '../../Tenant/Users/components/EditTenantUser'
import TenantThemeSettings from '../../Tenant/TenantThemeSettings'
import CreateCms from '../../Tenant/CMS/components/CreateCms'
import CmsDetails from '../../Tenant/CMS/components/CmsDetails'
import EditCms from '../../Tenant/CMS/components/EditCms'
import CasinoCategory from '../../Tenant/CasinoCategory'
import CasinoSubCategory from '../../Tenant/CasinoSubCategory'
import AdminUserDetails from '../../Tenant/AdminUserDetails'
import EditAdminUser from '../../Tenant/Admins/components/EditAdminUser'
import Wallet from '../../../components/Sidebar/components/Wallet'
import TransactionsBanking from '../../Tenant/TransactionsBanking'
import CasinoGames from '../../Tenant/CasinoGames'
import AddSubCategoryGames from '../../Tenant/AddSubCategoryGames'
import ReorderSubCategory from '../../Tenant/CasinoSubCategory/components/ReorderSubCategory'
import ReorderCategory from '../../Tenant/CasinoCategory/components/ReorderCategory'
// import CasinoTransactions from '../../Tenant/CasinoTransactions'
import CreateVoucher from '../../Super-Admin/Voucher/CreateVoucher/CreateVoucher'
import Dashboard from '../../Tenant/Dashboard'
import BonusDetail from '../../Tenant/Bonus/BonusDetail'
import CreateBonus from '../../Tenant/Bonus/components/CreateBonus'
import EditBonus from '../../Tenant/Bonus/components/EditBonus'
import CloneBonus from '../../Tenant/Bonus/components/CloneBonus'
import WageringTemplate from '../../Tenant/WageringTemplate'
import EditWageringTemplate from '../../Tenant/WageringTemplate/components/EditWageringTemplate'
import WageringTepltateDetails from '../../Tenant/WageringTemplate/components/WageringTeplateDetails'
import CreateWageringTemplate from '../../Tenant/WageringTemplate/components/CreateWageringTemplate'
import LoyaltyPoints from '../../../components/LoyaltyPoints'
import EditEmailTemplate from '../../Tenant/EmailTemplate/editEmailTemplate'
import EmailTemplates from '../../Tenant/EmailTemplate'
import ProfilePage from '../../../components/ProfilePage'
import Languages from '../../Tenant/TenantConfigurations/Languages'
import ImageGallery from '../../Tenant/ImageGallery'
import BannerManagement from '../../Tenant/BannerManagement'
import GameReorder from '../../Tenant/GameReorder'
import LanguageManagement from '../../Tenant/Languages/LanguageManagement'
import LanguageWise from '../../../components/LanguageMgmt/components/LanguageWise'
import KeyWise from '../../../components/LanguageMgmt/components/KeyWise'
import AddLangauge from '../../../components/LanguageMgmt/components/AddLanguage'
import CashierManagement from '../../Tenant/CashierManagement'
import ReorderBonus from '../../../components/ReorderBonus'
import ReviewManagement from '../../Tenant/ReviewManagement'
import TenantUserWallet from '../../Tenant/TenantUserWallet'
import TenantCasinoReporting from '../../Tenant/CasinoReporting'
import SportsReporting from '../../../components/SportsReporting'
import SportsTransactions from '../../../components/SportsTransactions'
import TenantUserReporting from '../../Tenant/UserReporting'
import TenantUserHistory from '../../Tenant/UserHistory'
import TenantNotification from '../../Tenant/Notification'
import BonusUsers from '../../Super-Admin/BonusUsers'
import UniqueTickets from '../../Super-Admin/UniqueTickets'
import BonusGames from '../../Super-Admin/BonusGames'
import Sports from '../../Tenant/Sports'
import VoucherManagement from '../../Super-Admin/Voucher'
import CreateUser from '../../../common/CreateUser/CreateUser'
import TenantWallet from '../../Tenant/TenantWallet'
import VoucherUsers from '../../Super-Admin/Voucher/VoucherUsers'
import VoucherGames from '../../Super-Admin/Voucher/VoucherGames'
import Seasons from '../../Tenant/Seasons/Seasons'
import ViewSeason from '../../Tenant/Seasons/ViewSeasons'
import VoucherTransactions from '../../../components/VoucherTransactions'
import Affiliates from '../../../components/Affiliates'
import CreateAffiliate from '../../../components/Affiliates/CreateAffiliate/CreateAffiliate'
import CreateAgent from '../../../components/Agent/CreateAgent/CreateAgent'
import Agents from '../../../components/Agent'
import NotFound from '../../NotFound'
import RollingCompetetion from '../../Super-Admin/RollingCompetetion/RollingCompetetion'
import RollingDetails from '../../Super-Admin/RollingCompetetion/RollingDetails'
import TenantRollingContestReport from '../../Tenant/RollingContestReportTenant'
import BetslipDetails from '../../../components/SportsReporting/BetslipDetails'
import ReorderLeagues from '../../../components/SportsBook/ReorderLeagues'
import ReorderSports from '../../../components/SportsBook/ReorderSports'
import Events from '../../Super-Admin/Events/Events'
import Bonus from '../../../common/NewBonus/Bonus'
import ViewBonusDetail from '../../../common/NewBonus/ViewBonusDetail'
import HotCombo from '../../../components/SportsBook/HotCombo'
import CreateHotCombo from '../../../components/SportsBook/CreateHotCombo'
import CreateNotification from '../../../components/CreateNotification'

const TenantPages = () => (
  <>

    <Route
      path={TenantRoutes.Wallet}
      element={
        <PrivateRoute isTenantRoute>
          <Wallet />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.createNotification}
      element={
        <PrivateRoute isTenantRoute>
          <CreateNotification isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditNotification}
      element={
        <PrivateRoute isTenantRoute>
          <CreateNotification isTenant isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.createNewUser}
      element={
        <PrivateRoute isTenantRoute>
          <CreateUser isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Dashboard}
      element={
        <PrivateRoute isTenantRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CMS}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'R' }}>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Admins}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>

          <Admin />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateAdminUser}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'C' }}>
          <CreateAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReviewManagement}
      element={
        <PrivateRoute isTenantRoute module={{ Reviews: 'R' }}>
          <ReviewManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantThemeSettings}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>

          <TenantThemeSettings />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Credentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>

          <TenantCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Languages}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <Languages />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.RollingCompetetion}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <RollingCompetetion isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.Events}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <Events isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.RollingDetails}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <RollingDetails isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantRollingContestReport}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <TenantRollingContestReport isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CreateCredentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'C' }}>
          <CreateCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.UpdateCredentials}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'U' }}>
          <EditCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.UsersV2}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <Users isUserV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.UserDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Users: 'R' }}>
          <UserDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.UserDetailsV2}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Users: 'R' }}>
          <UserDetails isUserV2 />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateUser}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'C' }}>
          <CreateTenantUser />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantConfigurations}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <TenantConfigurations />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.FormFields}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <FormFields />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.UpdateUser}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'U' }}>
          <EditTenantUser />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateCMS}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'C' }}>
          <CreateCms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CmsDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ CMS: 'R' }}>
          <CmsDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditCms}
      element={
        <PrivateRoute isTenantRoute module={{ CMS: 'U' }} isWithoutHeader>
          <EditCms />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReorderLeagues}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <ReorderLeagues />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReorderSports}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <ReorderSports />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoSubCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.voucherGames}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <VoucherGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CasinoGames}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <CasinoGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddSubCategoryGames}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <AddSubCategoryGames />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AdminUserDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Admins: 'R' }}>
          <AdminUserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditAdminUser}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'U' }}>
          <EditAdminUser />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TransactionsBanking}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />
    {/* <Route
      path={TenantRoutes.TenantUserWallet}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet />
        </PrivateRoute>
      }
    /> */}
    <Route
      path={TenantRoutes.UserDeposit}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='Deposit' />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2UserDeposit}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2UserDeposit' isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.UserWithdrawal}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='Withdraw' isWithdrawal />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2UserWithdrawal}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2UserWithdrawal' isWithdrawal isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.UserDepositHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='DepositHistory' isHistory />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2UserDepositHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2UserDepositHistory' isV2 isHistory />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.UserWithdrawalHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='WithdrawalHistory' isHistory isWithdrawal />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2UserWithdrawalHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2UserWithdrawalHistory' isHistory isWithdrawal isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.VaultDepositHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='VaultDepositHistory' isHistory isVault />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2VaultDepositHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2VaultDepositHistory' isHistory isVault isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.VaultWithdrawHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='VaultWithdrawHistory' isHistory isWithdrawal isVault />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.V2VaultWithdrawHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantUserWallet key='V2VaultWithdrawHistory' isHistory isWithdrawal isVault isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantWallet}
      element={
        <PrivateRoute isTenantRoute module={{ Transactions: 'R' }}>
          <TenantWallet />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.ReorderSubCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <ReorderSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ReorderCategory}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'U' }}>
          <ReorderCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BonusDetail}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.bonusUsers}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Bonus: 'R' }}>
          <BonusUsers isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.lotteryTickets}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Bonus: 'R' }}>
          <UniqueTickets isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.bonusGames}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ Bonus: 'R' }}>
          <BonusGames isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CreateBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CloneBonus}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.WageringTemplate}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'R' }}>
          <WageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.WageringEdit}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'U' }}>
          <EditWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.WageringDetails}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'R' }}>
          <WageringTepltateDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateWagering}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader module={{ WageringTemplate: 'C' }}>
          <CreateWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.CreateEmailTemplate}
      element={
        <PrivateRoute isWithoutHeader isTenantRoute module={{ EmailTemplate: 'C' }}>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EmailTemplate}
      element={
        <PrivateRoute isTenantRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplates />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditEmailTemplate}
      element={
        <PrivateRoute isWithoutHeader isTenantRoute module={{ EmailTemplate: 'U' }}>
          <EditEmailTemplate isEdit />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Profile}
      element={
        <PrivateRoute isTenantRoute isWithoutHeader>
          <ProfilePage isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Loyalty}
      element={
        <PrivateRoute isTenantRoute module={{ LoyaltyManagement: 'R' }}>
          <LoyaltyPoints isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.ImageGallery}
      element={
        <PrivateRoute isTenantRoute module={{ ImageGallery: 'R' }}>
          <ImageGallery isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.GameReorder}
      element={
        <PrivateRoute isTenantRoute isWithoutCard module={{ CasinoManagement: 'U' }}>
          <GameReorder />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BannerManagement}
      element={
        <PrivateRoute isTenantRoute module={{ CasinoManagement: 'R' }}>
          <BannerManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.LanguageManagement}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'R' }}>
          <LanguageManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditSupportLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.AddLanguage}
      element={
        <PrivateRoute isTenantRoute module={{ MultiLanguage: 'U' }}>
          <AddLangauge isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.PaymentAggregators}
      element={
        <PrivateRoute isTenantRoute module={{ TenantSettings: 'R' }}>
          <CashierManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BonusReorder}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <ReorderBonus isTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Voucher}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <VoucherManagement isTenantRoute />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.GiftCard}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <VoucherManagement isTenantRoute isGiftCard />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Affiliates}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <Affiliates isTenantRoute />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Agents}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <Agents isTenantRoute />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.AffiliateDetail}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <CreateAffiliate isView />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.AgentDetail}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <CreateAgent isView />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditAffiliate}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <CreateAffiliate isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.EditAgent}
      element={
        <PrivateRoute isTenantRoute module={{ Bonus: 'U' }}>
          <CreateAgent isEdit />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.voucherUsers}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <VoucherUsers />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.VoucherDetail}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <CreateVoucher isView />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.createAffiliate}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <CreateAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.createAgent}
      element={
        <PrivateRoute isTenantRoute module={{ Admins: 'R' }}>
          <CreateAgent />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantCasinoReporting}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <TenantCasinoReporting isV2 />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.SportsReporting}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <SportsReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.HotCombo}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <HotCombo />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.CreateHotCombo}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <CreateHotCombo />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.ViewHotCombo}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <CreateHotCombo isView />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.SportsTransactions}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <SportsTransactions userData={{}} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.SportsReportingBetBy}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <SportsReporting isBetBy isV2User />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.SportsTransactionsBetBy}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <SportsTransactions isBetBy isV2User userData={{}} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.SportsTransactionsV2}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <SportsTransactions isV2User userData={{}} />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.BetslipDetails}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <BetslipDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.TenantUserReporting}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <TenantUserReporting />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantUserHistory}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <TenantUserHistory />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.voucherTransactions}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <VoucherTransactions />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.giftCardTransactions}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <VoucherTransactions isGiftCard />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantNotification}
      element={
        <PrivateRoute isTenantRoute module={{ Reviews: 'R' }}>
          <TenantNotification />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantBonusNotification}
      element={
        <PrivateRoute isTenantRoute module={{ Reviews: 'R' }}>
          <Bonus isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.TenantBonusDetail}
      element={
        <PrivateRoute isTenantRoute module={{ Reviews: 'R' }}>
          <ViewBonusDetail isTenant />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.Sports}
      element={
        <PrivateRoute isTenantRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Sports />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.SportsCountries}
      element={
        <PrivateRoute isTenantRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Countries />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.Matches}
      element={
        <PrivateRoute isTenantRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Matches />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.Markets}
      element={
        <PrivateRoute isTenantRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Markets />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.Leagues}
      element={
        <PrivateRoute isTenantRoute module={{ SportsBookManagement: 'R' }}>
          <Sports Leagues />
        </PrivateRoute>
      }
    />

    <Route
      path={TenantRoutes.Seasons}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <Seasons />
        </PrivateRoute>
      }
    />
    <Route
      path={TenantRoutes.seasonDetail}
      element={
        <PrivateRoute isTenantRoute module={{ Users: 'R' }}>
          <ViewSeason />
        </PrivateRoute>
      }
    />
    <Route
      path='*'
      element={<NotFound />}
    />
  </>
)

export default TenantPages
