import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllTenants,
  createTenant,
  createTenantAdmin,
  getTenant,
  editTenant,
  superAdminViewToggleStatus,
  getAllCredentials,
  createTenantCredentials,
  updateTenantCredentials,
  updateTenantAdmin,
  addDepositToOther,
  getAllClients,
  getAllPortals,
  getTenantList,
  getOwnerPermissions,
  getOwnerManagers,
  getTenantCasinoReportingData,
  getTenantUserReportingData,
  getTenantAllUserHistory,
  getAllTenantNotification,
  getAllTenantReadReciept,
  createTenantNotification,
  updateTenantNotificationStatus,
  updateUserPasswordRequest,
  tenantUpdatePassword,
  getTenantSeasonList,
  getTenantSeasonDetails,
  getRollingList
} from '../../utils/apiCalls'

import {
  getAllTenantsStart,
  getAllTenantsSuccess,
  getAllTenantsFailure,
  createTenantStart,
  createTenantSuccess,
  createTenantFailure,
  createTenantAdminStart,
  createTenantAdminSuccess,
  createTenantAdminFailure,
  getTenantStart,
  getTenantSuccess,
  getTenantFailure,
  editTenantStart,
  editTenantSuccess,
  editTenantFailure,
  updateTenantStatusStart,
  updateTenantStatusSuccess,
  updateTenantStatusFailure,
  getAllCredentialsStart,
  getAllCredentialsSuccess,
  getAllCredentialsFailure,
  createCredentialsStart,
  createCredentialsSuccess,
  createCredentialsFailure,
  updateCredentialsStart,
  updateCredentialsSuccess,
  updateCredentialsFailure,
  updateTenantAdminStart,
  updateTenantAdminComplete,
  addDepositToOtherStart,
  addDepositToOtherCompleted,
  getAllClientsStart,
  getAllClientsSuccess,
  getAllClientsFailure,
  getAllPortalsStart,
  getAllPortalsSuccess,
  getAllPortalsFailure,
  getAllTenantsListStart,
  getAllTenantsListFailure,
  getAllTenantsListSuccess,
  getOwnerPermissionStart,
  getOwnerPermissionSuccess,
  getOwnerPermissionFailure,
  getOwnerManagersStart,
  getOwnerManagersSuccess,
  getOwnerManagersFailure,
  getTenantCasinoReportingDataStart,
  getTenantCasinoReportingDataSuccess,
  getTenantCasinoReportingDataFailure,
  getTenantUserReportingDataStart,
  getTenantUserReportingDataSuccess,
  getTenantUserReportingDataFailure,
  getTenantAllUserHistoryStart,
  getTenantAllUserHistorySuccess,
  getTenantAllUserHistoryFailure,
  getAllTenantSpecificNotificationStart,
  getAllTenantSpecificNotificationSuccess,
  getAllTenantSpecificNotificationFailure,
  getTenantAllReadRecieptStart,
  getTenantAllReadRecieptSuccess,
  getTenantAllReadRecieptFailure,
  createTenantNotificationStart,
  createTenantNotificationSuccess,
  createTenantNotificationFailure,
  updateTenantNotificationStatusStart,
  updateTenantNotificationStatusSuccess,
  updateTenantNotificationStatusFailure,
  updateTenantUserPasswordStart,
  updateTenantUserPasswordSuccess,
  updateTenantUserPasswordFailure,
  tenantUpdateUserPasswordStart,
  tenantUpdateUserPasswordSuccess,
  tenantUpdateUserPasswordFailure,
  getAllSeasonsStart,
  getAllSeasonsDetailsStart,
  getAllSeasonsDetailsSuccess,
  getAllSeasonsDetailsFailure,
  getRollingReportingTAListStart,
  getRollingReportingTAListFailure,
  getRollingReportingTAListSuccess
} from '../redux-slices/tenants'

import { toast } from '../../components/Toast'
import { SuperAdminRoutes } from '../../routes'
import { objectToFormData } from '../../utils/objectToFormdata'
import { getTenantUserDetailsStart } from '../redux-slices/tenantUsers'
import { getUserStart } from '../redux-slices/fetchData'
import { serialize } from 'object-to-formdata'

export default function * tenantsWatcher () {
  yield takeLatest(updateTenantUserPasswordStart.type, updateTenantUserPasswordWorker)
  yield takeLatest(getAllTenantsStart.type, getAllTenantsWorker)
  yield takeLatest(createTenantStart.type, createTenantWorker)
  yield takeLatest(createTenantAdminStart.type, createTenantAdminWorker)
  yield takeLatest(getTenantStart.type, getTenantWorker)
  yield takeLatest(editTenantStart.type, editTenantWorker)
  yield takeLatest(updateTenantStatusStart.type, updateTenantStatusWorker)
  yield takeLatest(getAllCredentialsStart.type, getAllCredentialsWorker)
  yield takeLatest(createCredentialsStart.type, createTenantCredentialsWorker)
  yield takeLatest(updateCredentialsStart.type, updateTenantCredentialsWorker)
  yield takeLatest(updateTenantAdminStart.type, updateTenantAdminWorker)
  yield takeLatest(addDepositToOtherStart.type, AddDepositWorker)
  yield takeLatest(getAllClientsStart.type, getClientsWorker)
  yield takeLatest(getAllPortalsStart.type, getPortalsWorker)
  yield takeLatest(getAllTenantsListStart.type, getTenantsListWorker)
  yield takeLatest(getOwnerPermissionStart.type, getOwnerPermissionWorker)
  yield takeLatest(getOwnerManagersStart.type, getOwnerManagersWorker)
  yield takeLatest(getTenantCasinoReportingDataStart.type, getTenantCasinoReportingDataWorker)
  yield takeLatest(getTenantUserReportingDataStart.type, getTenantUserReportingDataWorker)
  yield takeLatest(getTenantAllUserHistoryStart.type, getTenantAllUserHistoryWorker)
  yield takeLatest(getAllTenantSpecificNotificationStart.type, getAllTenantSpecificNotificationWorker)
  yield takeLatest(getTenantAllReadRecieptStart.type, getTenantAllReadRecieptWorker)
  yield takeLatest(createTenantNotificationStart.type, createTenantNotificationWorker)
  yield takeLatest(updateTenantNotificationStatusStart.type, updateTenantNotificationStatusWorker)
  yield takeLatest(tenantUpdateUserPasswordStart.type, tenantUpdateUserPasswordWorker)
  yield takeLatest(getAllSeasonsStart.type, getTenantSeasonsWorker)
  yield takeLatest(getAllSeasonsDetailsStart.type, getTenantSeasonsDetailsWorker)
  yield takeLatest(getRollingReportingTAListStart.type, getRollingReportingTAListWorker)
}

function * getRollingReportingTAListWorker (action) {
  try {
    const { data } = yield getRollingList(action?.payload)
    yield put(getRollingReportingTAListSuccess(data?.data?.userCompetitionLeaderBoard))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRollingReportingTAListFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateTenantUserPasswordWorker (action) {
  try {
    const {
      dataForm, isTenant, isAffiliate, isAgent, handleClose
    } = action && action.payload
    const {
      data
    } = yield updateUserPasswordRequest(
      dataForm.action === 'REJECT'
        ? {
            ...dataForm,
            newPassword: btoa(dataForm.newPassword),
            isTenant: isTenant,
            isAffiliate,
            isAgent,
            withdrawalPassword: btoa(dataForm.withdrawalNewPassword)
          }
        : {
            ...dataForm,
            newPassword: btoa(dataForm.newPassword),
            isAffiliate,
            isTenant,
            isAgent,
            withdrawalPassword: btoa(dataForm.withdrawalNewPassword)
          })
    yield handleClose()
    yield put(updateTenantUserPasswordSuccess(data?.data?.message))
    toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTenantUserPasswordFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * tenantUpdateUserPasswordWorker (action) {
  try {
    const {
      dataForm,
      isAffiliate,
      isAgent
    } = action && action.payload
    const {
      data
    } = yield tenantUpdatePassword(
      dataForm?.action === 'REJECT'
        ? { ...dataForm, isAffiliate, isAgent }
        : {
            ...dataForm,
            newPassword: btoa(dataForm.newPassword),
            isAffiliate,
            isAgent,
            withdrawalPassword: btoa(dataForm.withdrawalNewPassword)
          })
    yield put(tenantUpdateUserPasswordSuccess(data?.data?.message))
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(tenantUpdateUserPasswordFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantAllUserHistoryWorker (action) {
  try {
    const {
      form,
      isAffiliate,
      isAgent
    } = action && action.payload
    const { data } = yield getTenantAllUserHistory({ form, isAffiliate, isAgent })
    yield put(getTenantAllUserHistorySuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, e?.response?.data?.errors[0]?.description)
    yield put(getTenantAllUserHistoryFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTenantUserReportingDataWorker (action) {
  try {
    const {
      form,
      isAffiliate,
      isAgent
    } = action && action.payload
    const { data } = yield getTenantUserReportingData({ form, isAffiliate, isAgent })
    yield put(getTenantUserReportingDataSuccess(data?.data?.walletTransactions))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantUserReportingDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTenantCasinoReportingDataWorker (action) {
  try {
    const {
      form,
      isAffiliate,
      isAgent
    } = action && action.payload
    const { data } = yield getTenantCasinoReportingData({ form, isAffiliate, isAgent })
    yield put(getTenantCasinoReportingDataSuccess(data?.data?.casinoTransactionDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantCasinoReportingDataFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAllTenantsWorker (action) {
  try {
    const { limit, pageNo, search, adminId, status } = action && action.payload

    const { data } = yield getAllTenants({ limit, pageNo, search, adminId, status })

    yield put(getAllTenantsSuccess(data?.data?.tenants))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTenantsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantSeasonsWorker (action) {
  try {
    const { data } = yield getTenantSeasonList(action.payload)

    yield put(getAllSeasonsDetailsSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllSeasonsDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantSeasonsDetailsWorker (action) {
  try {
    const { data } = yield getTenantSeasonDetails(action.payload)

    yield put(getAllSeasonsDetailsSuccess(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAllSeasonsDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createTenantWorker (action) {
  try {
    const { createTenantData, navigate } = action && action.payload
    createTenantData.depositMethods = JSON.stringify(createTenantData.depositMethods)
    createTenantData.withdrawMethods = JSON.stringify(createTenantData.withdrawMethods)
    createTenantData.sportProviderIds = JSON.stringify(createTenantData?.sportProviderIds)
    const data = serialize(createTenantData)

    yield createTenant(data)

    yield put(createTenantSuccess())

    yield toast('Tenant Created Successfully', 'success')

    navigate(`/super-admin/tenants/${createTenantData?.adminId}`)
  } catch (e) {
    const { setSelectedTab } = action && action.payload
    // setCreateTenantData({
    //   ...createTenantData,
    //   depositMethods: [],
    //   withdrawMethods: []
    // })
    setSelectedTab('tenant')
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createTenantFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createTenantAdminWorker (action) {
  try {
    const { createTenantAdminData, navigate } = action && action.payload

    yield createTenantAdmin(createTenantAdminData)

    yield put(createTenantAdminSuccess())

    yield toast('Tenant Admin Created Successfully', 'success')

    navigate(SuperAdminRoutes.TenantOwner)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createTenantAdminFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantWorker (action) {
  try {
    const { tenantId } = action && action.payload

    const { data } = yield getTenant({ tenantId })

    yield put(getTenantSuccess(data?.data?.tenantDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTenantFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * editTenantWorker (action) {
  try {
    const { createTenantData, tenantId } =
    action && action.payload

    createTenantData.depositMethods = JSON.stringify(createTenantData.depositMethods)
    createTenantData.withdrawMethods = JSON.stringify(createTenantData.withdrawMethods)
    createTenantData.providerIds = JSON.stringify(createTenantData?.providerIds)
    createTenantData.sportProviderIds = JSON.stringify(createTenantData?.sportProviderIds)

    yield editTenant(objectToFormData(createTenantData))

    yield put(editTenantSuccess())

    yield put(getTenantStart({ tenantId }))

    yield toast('Tenant Edited Successfully', 'success')

    // val && navigate(-1)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(editTenantFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantStatusWorker (action) {
  try {
    const { tenantId, status, limit, page, search, adminId, isActive = '' } =
      action && action.payload

    yield superAdminViewToggleStatus({ tenantId, status, code: 'TENANT' })

    yield put(updateTenantStatusSuccess())

    yield put(getAllTenantsStart({ limit, pageNo: page, search, adminId, status: isActive }))

    yield toast('Tenant Status Updated Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllCredentialsWorker () {
  try {
    const { data } = yield getAllCredentials()

    yield put(getAllCredentialsSuccess(data?.data?.credentialsKeys))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCredentialsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createTenantCredentialsWorker (action) {
  try {
    const { tenantId, adminId, key, value, description, navigate } =
      action && action.payload

    yield createTenantCredentials({ tenantId, key, value, description })

    yield put(createCredentialsSuccess())
    yield toast('Credentials Created Successfully', 'success')
    navigate(`/super-admin/tenants/details/${adminId}/${tenantId}`)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createCredentialsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantCredentialsWorker (action) {
  try {
    const { tenantId, adminId, key, value, description, navigate } =
      action && action.payload

    yield updateTenantCredentials({ tenantId, key, value, description })

    yield put(updateCredentialsSuccess())
    yield toast('Credentials updated Successfully', 'success')
    navigate(`/super-admin/tenants/details/${adminId}/${tenantId}`)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateCredentialsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantAdminWorker (action) {
  try {
    const { createTenantAdminData, adminUserId, navigate } = action && action.payload
    createTenantAdminData.adminUserId = adminUserId

    yield updateTenantAdmin(createTenantAdminData)

    yield put(updateTenantAdminComplete())

    yield toast('Tenant Admin Updated Successfully', 'success')
    navigate(SuperAdminRoutes.TenantOwner)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTenantAdminComplete())
  }
}

function * AddDepositWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield addDepositToOther({ data, isTenant })

    yield put(addDepositToOtherCompleted())

    isTenant
      ? yield put(getTenantUserDetailsStart({ userId: data.userId }))
      : yield put(getUserStart({ userId: data?.userId }))

    data?.addAmount > 0
      ? yield toast('Deposit Successful', 'success')
      : yield toast('Amount Removed from Wallet Successful', 'success')
  } catch (e) {
    yield put(addDepositToOtherCompleted())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getClientsWorker (action) {
  try {
    const { orderBy, limit, pageNo, sort, search, status = '' } = action && action.payload

    const { data } = yield getAllClients({ orderBy, limit, pageNo, sort, search, status })

    yield put(getAllClientsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllClientsFailure())
  }
}

function * getPortalsWorker (action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAllPortals({ adminId })

    yield put(getAllPortalsSuccess(data?.data?.tenantDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllPortalsFailure())
  }
}

function * getTenantsListWorker () {
  try {
    const { data } = yield getTenantList()

    yield put(getAllTenantsListSuccess(data?.data?.tenantDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTenantsListFailure())
  }
}

function * getOwnerPermissionWorker () {
  try {
    const { data } = yield getOwnerPermissions()

    yield put(getOwnerPermissionSuccess(data?.data?.permissions))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getOwnerPermissionFailure())
  }
}

function * getOwnerManagersWorker (action) {
  try {
    const { ownerId, limit, pageNo } = action && action.payload

    const { data } = yield getOwnerManagers({ ownerId, limit, pageNo })

    yield put(getOwnerManagersSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getOwnerManagersFailure())
  }
}
function * getAllTenantSpecificNotificationWorker (action) {
  try {
    const {
      referenceType,
      status,
      limit,
      pageNo,
      affiliateId,
      agentId
    } = action && action.payload
    const { data } = yield getAllTenantNotification({
      limit,
      pageNo,
      referenceType,
      status,
      affiliateId,
      agentId
    })
    yield put(getAllTenantSpecificNotificationSuccess(data?.data?.notifications))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTenantSpecificNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantAllReadRecieptWorker (action) {
  try {
    const {
      notificationId,
      limit,
      pageNo,
      isRead
    } = action && action.payload
    const { data } = yield getAllTenantReadReciept(
      notificationId,
      limit,
      pageNo,
      isRead
    )
    yield put(getTenantAllReadRecieptSuccess(data?.data?.results))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTenantAllReadRecieptFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createTenantNotificationWorker (action) {
  try {
    const {
      formData
    } = action && action.payload
    const { data } = yield createTenantNotification(formData)
    yield put(createTenantNotificationSuccess(data?.data?.notifications))
    yield toast('Notification Created Successfully', 'success')
    yield put(getAllTenantSpecificNotificationStart(formData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createTenantNotificationFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateTenantNotificationStatusWorker (action) {
  try {
    const {
      formData,
      setShowConfirmActiveNotificationModal

    } = action && action.payload
    const {
      data
    } = yield updateTenantNotificationStatus(formData)
    yield put(updateTenantNotificationStatusSuccess(data?.data))
    yield toast('Notification Created', 'success')
    yield put(getAllTenantSpecificNotificationStart(formData))
    setShowConfirmActiveNotificationModal(false)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantNotificationStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
