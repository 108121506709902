/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faEdit,
  faEye,
  faRedoAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useAllUserListing from './useAllUserListing'
import Trigger from '../../../components/OverlayTrigger'
import ClientFilter from '../../../components/ClientFilter'
import {
  tableHeaders,
  isAnonymousFilter,
  tableHeadersV2
} from './userConstant'
import { countryFilter } from '../../../utils/countryFilter'
import useIsGamma from '../../../common/hook/useIsGamma'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { SuperAdminRoutes } from '../../../routes'
import { ChangeInspectionStatus } from '../../../components/ConfirmationModal'
import NoteModal from '../../../components/NoteModal'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import AffiliateAgentFilter from '../../../common/AffiliateAgentFilter'
import { formatNumber } from '../../../utils/storageUtils'
export default ({
  adminId,
  isCreateBonus,
  selectedPlayers,
  setSelectedPlayers,
  isUserV2 = '',
  kycStatus = ''
}) => {
  const { t } = useTranslation()
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    userData,
    totalPages,
    navigate,
    setKycSeniorOptions,
    selectedClient,
    setSelectedPortal,
    setSelectedClient,
    loading,
    setKycOptions,
    setState,
    regState,
    setRegState,
    countries,
    setSelectedCountry,
    selectedCountry,
    flagFilter,
    setRedFlagFilter,
    initialDate,
    handleMarkAsInspection,
    setInspectData,
    show,
    setShow,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleNote,
    // affiliateAndAgentsList,
    agent,
    affiliate,
    setAgent,
    setAffiliate,
    totalPlayerAmount,
    isAnonymous, setIsAnonymous,
    userDetailsRouteEndpoint
  } = useAllUserListing({
    adminId,
    isUserV2,
    kycStatus
  })
  const {
    isGamma
  } = useIsGamma()
  const isChecked = (id) => {
    return selectedPlayers?.find(e => Number(e.userId) === Number(id))
  }
  const handleSelectedPlayers = (e, item) => {
    if (!isChecked(e.target.value)) {
      setSelectedPlayers(prev => [...prev, item])
    } else {
      setSelectedPlayers(selectedPlayers.filter(t => Number(t.userId) !== Number(e.target.value)))
    }
  }

  const tableHeader = isUserV2 ? tableHeadersV2 : tableHeaders

  return (
    <div className='p-3'>
      <div className='d-flex justify-content-between gap-2 align-items-center'>
        <h3><FontAwesomeIcon icon={faUsers} />&nbsp; {t('players')}</h3>
        {
          (!isCreateBonus && !isGamma) && (isUserV2 === true)
            ? (
              <Button
                onClick={() => {
                  navigate(SuperAdminRoutes.createNewUser)
                }} variant='outline-success'
              >{t('addNewPlayer')}
              </Button>
              )
            : (
              <></>
              )
        }
      </div>

      <Row xs='auto'>
        <Col className='d-flex align-self-center mt-2'>
          {!isCreateBonus && (
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
          )}
        </Col>
        {
            selectedClient && (
              <AffiliateAgentFilter
                selectedClient={selectedClient}
                agent={agent}
                setAgent={setAgent}
                affiliate={affiliate}
                setAffiliate={setAffiliate}
              />
            )
          }
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('search')}
          </Form.Label>

          <Form.Control
            type='search'
            placeholder={t('searchUsername')}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '15px',
              fontSize: '13px'
            }}
            value={search}
            onChange={(event) =>
              setSearch(
                event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
              )}
          />
        </Col>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              marginTop: '5px',
              fontSize: '13px'
            }}
          >
            {t('status')}
          </Form.Label>
          <Form.Select
            onChange={(e) => setRedFlagFilter(e.target.value)}
            size='sm'
            style={{
              width: '230px',
              marginRight: '10px',
              fontSize: '13px'
            }}
            value={flagFilter}
          >
            <option value=''>{t('all')}</option>
            <option value='RedFlag'>{t('redFlag')}</option>
            <option value='BlackList'>{t('blacklist')}</option>
            <option value='Normal'>{t('normal')}</option>
            <option value='Inspection'>{t('inspection')}</option>
          </Form.Select>
        </Col>
        <Col className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('registrationDateStart')}
            value={regState[0].startDate}
            onChange={(val) => {
              setRegState([{ ...regState[0], startDate: val }])
            }}
          />
        </Col>
        <Col className='d-flex mt-2'>
          <InputDatePicker
            isFilter
            label={t('registrationDateEnd')}
            value={regState[0].endDate}
            onChange={(val) => {
              setRegState([{ ...regState[0], endDate: val }])
            }}
          />
        </Col>
        {(countries && Array.isArray(countries)) &&
          <Col className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                marginBottom: '0',
                marginTop: '5px',
                minWidth: '70px',
                fontSize: '13px'
              }}
            >
              {t('country')}
            </Form.Label>
            <Form.Select
              onChange={(e) => setSelectedCountry(e.target.value)}
              value={selectedCountry || ''}
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
              size='sm'
            >
              <option value=''>{t('selectCountry')}</option>
              {countries?.map((item, idx) => {
                return (
                  <option
                    key={`countryNames2_${idx}`}
                    value={item.code}
                  >
                    {item.name}
                  </option>
                )
              })}

            </Form.Select>
          </Col>}
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label
            className='flex-shrink-0'
            style={{
              width: 'auto',
              marginRight: '15px',
              fontSize: '13px',
              marginTop: '5px'
            }}
          >
            {t('anonymousUser')}
          </Form.Label>

          <Form.Select
            onChange={(e) => { setIsAnonymous(e.target.value) }}
            value={isAnonymous}
            style={{ width: 'auto', marginRight: '10px' }}
            size='sm'
          >
            {isAnonymousFilter.map((item, index) => {
              return (
                <option
                  key={index}
                  value={item.value}
                >{t(item.label)}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col className='d-flex mt-2'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setSelectedClient('')
                setLimit(15)
                setPage(1)
                setKycOptions('')
                setKycSeniorOptions('')
                setSelectedCountry('')
                setState(initialDate)
                setRegState(initialDate)
                setRedFlagFilter('')
                setAgent('')
                setAffiliate('')
                setIsAnonymous('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeader.map((h, idx) => (
              <th key={idx}>
                {t(h.label)}{' '}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(userData) &&
            userData?.rows?.map(
              (item, index) => {
                const { countryImage, countryName } = countryFilter(item?.countryCode)
                const serialNumber = (userData.count - (index + limit * (page - 1)))
                return (
                  <tr key={item?.userId} className={item?.isUnderInspection ? 'under-inspection' : ''}>
                    <td>

                      <div className='text-center d-flex align-items-center justify-content-center '>
                        {isCreateBonus && (
                          <label className='me-2 d-inline-flex' htmlFor={item?.userId}>
                            <input
                              onChange={(e) =>
                                handleSelectedPlayers(e, { userId: item.userId, username: item?.username })}
                              style={{ width: 20, height: 20 }}
                              checked={!!isChecked(item?.userId)}
                              value={item?.userId}
                              type='checkbox'
                              id={item?.userId}
                            />
                          </label>
                        )}
                        {serialNumber}
                      </div>

                    </td>
                    <td>{item?.username || 'NA'} </td>
                    <td>{item?.['affiliate.username'] || '-'} </td>
                    <td>{item?.['agent.username'] || '-'} </td>
                    <td>{item?.createdAt
                      ? moment(item?.createdAt).format('MM-DD-YYYY HH:mm:ss')
                      : 'NA'}
                    </td>

                    <td>
                      {
                        !countryName
                          ? 'Other'
                          : (
                            <Trigger message={countryName}>
                              <img width='25px' src={countryImage} alt={countryImage} />
                            </Trigger>
                            )
                      }
                    </td>
                    <td>{item?.kycStatus || 'NA'} </td>
                    <td>{item?.kycStatusSenior || 'NA'} </td>
                    <td>{formatNumber(item?.['userWallet.vaultAmount']) + ' ₩'}</td>
                    {!isUserV2 && <td>{(item?.isV2User ? 0 : formatNumber(item?.['userWallet.amount'])) + ' ₩'} </td>}
                    <td>{(item?.isV2User ? formatNumber(item?.['userWallet.withdrawableAmount']) : 0) + ' ₩'} </td>
                    <td>{(item?.isV2User ? formatNumber(item?.['userWallet.nonWithdrawableAmount']) : 0) + ' ₩'} </td>
                    {!isUserV2 && <td>{(item?.isV2User ? 0 : formatNumber(item?.['userWallet.nonCashAmount'])) + ' ₩'} </td>}
                    {!isUserV2 && <td>{(item?.isV2User ? formatNumber(item?.['userWallet.totalPromotionCash']) : 0) + ' ₩'} </td>}
                    {!isUserV2 && <td>{formatNumber(item?.['userBonusWallet.totalBonusAmount'] + item?.['userWallet.sportsBonusWalletAmount'] ?? 0) + ' ₩'} </td>}
                    {!isUserV2 && <td>{formatNumber((item?.['userCasinoPayout.totalCasinoPayoutAmount'] ?? 0) + (item?.['userSportsPayout.totalSportsPayoutAmount'] ?? 0)) + ' ₩'} </td>}
                    {isUserV2 && <td>{(item?.isV2User ? formatNumber(item?.['userWallet.casinoPromotionCash']) : 0) + ' ₩'} </td>}
                    {isUserV2 && <td>{(item?.isV2User ? formatNumber(item?.['userWallet.sportsPromotionCash']) : 0) + ' ₩'} </td>}
                    {isUserV2 && <td>{formatNumber(item?.['userBonusWallet.totalBonusAmount'] ?? 0) + ' ₩'} </td>}
                    {isUserV2 && <td>{formatNumber(item?.['userWallet.sportsBonusWalletAmount'] ?? 0) + ' ₩'} </td>}
                    {isUserV2 && <td>{formatNumber((item?.['userCasinoPayout.totalCasinoPayoutAmount'] ?? 0)) + ' ₩'} </td>}
                    {isUserV2 && <td>{formatNumber((item?.['userSportsPayout.totalSportsPayoutAmount'] ?? 0)) + ' ₩'} </td>}
                    <td>
                      <Trigger message={item?.superadminNotes}>
                        <div>
                          {item?.superadminNotes ?? '-'}
                        </div>
                      </Trigger>
                    </td>
                    <td>
                      <Trigger message={item.seniorNotes}>
                        <div>
                          {item?.seniorNotes ?? '-'}
                        </div>
                      </Trigger>
                    </td>
                    <td>{
                    formatNumber(item?.withdrawlLimit ? item?.levelWithdrawlLimit ? Number(item?.withdrawlLimit) + Number(item?.levelWithdrawlLimit) : item?.withdrawlLimit : Number(item?.['tenant.tenantConfig.withdrawlLimit'] ?? 0) + Number(item?.levelWithdrawlLimit ?? 0) || '')
                    }
                      {' '} {item?.withdrawlLimitCurrency || ' ₩'}
                    </td>
                    <td>
                      {item?.currentIPAddress || item?.signInIp}
                    </td>
                    <td>
                      {item?.signInIp || '-'}
                    </td>
                    <td className={item?.isFlaggedRed ? 'table-danger' : 'table-success'}>
                      {item?.isFlaggedRed ? t('yes') : t('no') || 'NA'}
                    </td>
                    <td className={item?.isActive ? 'table-success' : 'table-danger'}>
                      {item?.isActive ? 'Normal' : 'Blacklist' || 'NA'}
                    </td>
                    {
                    !isCreateBonus
                      ? (
                        <td>
                          <Trigger message={t('viewDetails')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() => {
                                navigate(
                              `/super-admin/${userDetailsRouteEndpoint()}/details/${item?.userId}`
                                )
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>
                          <Trigger message={t('markedAsInspection')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() => {
                                setShow(true)
                                setInspectData({ userId: item.userId, flag: !item?.isUnderInspection })
                              }}
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </Button>
                          </Trigger>
                          <Trigger message={t('addNote')}>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() => {
                                setAddNoteModal(true)
                                setIndividualUserData(item)
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>
                        </td>
                        )
                      : (
                        <td>
                          '-'
                        </td>
                        )
                   }
                  </tr>
                )
              }
            )}

          {userData?.count === 0 &&
            (
              <tr>
                <td colSpan={13} className='text-danger text-center'>
                  {t('noDataFound')}
                </td>
              </tr>
            )}

        </tbody>
      </Table>
      <ChangeInspectionStatus show={show} setShow={setShow} onClick={handleMarkAsInspection} />
      {
        addNoteModal
          ? <NoteModal
              addNoteModal={addNoteModal}
              setAddNoteModal={setAddNoteModal}
              individualUserData={individualUserData}
              handleSubmit={handleNote}
            />
          : <></>
      }
      {totalPlayerAmount &&
        <>

          <div className='d-flex gap-4 align-items-center py-4 justify-content-end'>
            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalAmount) + ' ₩'}</div>
              </div>
            </div>
            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalWithdrawableAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalWithdrawable) + ' ₩'}</div>
              </div>
            </div>
            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalNonWithdrawableAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalNonWithdrawable) + ' ₩'}</div>
              </div>
            </div>
            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalPromotionCash')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalPromotionCash) + ' ₩'}</div>
              </div>
            </div>

          </div>
          <div className='d-flex gap-4 align-items-center py-4 justify-content-end'>
            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalPayoutAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalPayout) + ' ₩'}</div>
              </div>
            </div>

            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalBonusWalletAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalBonusWalletAmount) + ' ₩'}</div>
              </div>
            </div>

            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalNonCashAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalNonCashAmount) + ' ₩'}</div>
              </div>
            </div>

            <div className=''>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                <span style={{ marginBottom: '0', marginRight: '15px' }}>
                  {t('totalVaultAmount')}
                </span>
                <div className='px-4 py-2 rounded-2 bg-light border-1'>{formatNumber(totalPlayerAmount?.totalVaultAmount) + ' ₩'}</div>
              </div>
            </div>
          </div>

        </>}
      {userData?.count !== 0 && !loading && (
        <PaginationComponent
          page={userData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={userData?.count}
        />
      )}
    </div>
  )
}
