import * as Yup from 'yup'

export const perodicBonusSchema = (t) => {
  return Yup.object().shape({
    maxBonusLimit: Yup.number()
      .max(9999999, t('amountLessThan8digit'))
      .typeError(t('mustBeNumber'))
      .positive(t('mustBeGreaterThanZero'))
      .required(t('fieldRequired')),
    bonusPercent: Yup
      .number()
      .positive()
      .min(0, t('minimumValue0'))
      .max(100, t('maximumValue100'))
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired')),
    rollingPercent: Yup
      .number()
      .positive()
      .min(0, t('minimumValue0'))
      .max(999, t('maximumValue999'))
      .transform((value) => Number.isNaN(value) ? null : value)
      .required(t('fieldRequired')),
    bonusText: Yup.string().required(t('fieldRequired'))
  })
}
