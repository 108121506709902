import { useDispatch } from 'react-redux'
import { depositTenantAmountStart, tenantWalletRequestStart, withdrawalTenantAmountStart } from '../../../../store/redux-slices/tenantTransactions'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const useAccountTransaction = (isTenant, isDeposit, isV2User) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const initialValues = isTenant
    ? {
        currency: '',
        amount: '',
        action: isDeposit ? 'DEPOSIT' : 'WITHDRAW'
      }
    : (isV2User
        ? {
            currency: '',
            amount: '',
            actionSource: '',
            rollingPercentage: ''
          }
        : {
            currency: '',
            amount: '',
            actionSource: ''
          }
      )
  const [formDataValues, setFormData] = useState(initialValues)
  const [confirmTransactionModal, setConfirmTransactionModal] = useState(false)
  const handleDeposit = () => {
    const payload = {
      ...formDataValues,
      actionSource: isV2User ? formDataValues?.actionSource : isDeposit ? 'DEPOSIT' : 'WITHDRAW',
      userId: userId
    }

    isTenant
      ? (
          dispatch(tenantWalletRequestStart({
            formData: payload,
            setFormData: () => setFormData(initialValues)
          }))

        )
      : isDeposit
        ? dispatch(depositTenantAmountStart({ data: payload, setFormData: () => setFormData(initialValues) }))
        : dispatch(withdrawalTenantAmountStart({ data: payload, setFormData: () => setFormData(initialValues) }))
  }

  return {
    handleDeposit,
    setFormData,
    formDataValues,
    setConfirmTransactionModal,
    confirmTransactionModal
  }
}

export default useAccountTransaction
