import { Button, Col, Modal, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { uploadBannerSchema } from './schema.js'
import { getItem } from '../../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { returnValidation } from '../../../utils/constants.js'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'

const EditUploadBanner = ({
  type,
  data,
  show,
  setShow,
  createUpdate,
  clientsFilterData,
  dispatch,
  SABanners,
  validation,
  setValidation,
  bannerTypes,
  lang,
  loading,
  screenType,
  setScreenType
}) => {
  const [isImageUpdated, setIsImageUpdated] = useState(true)
  const { t } = useTranslation()
  const { languages } = useSelector(state => state.languages)
  const screenTypeOptions = ['web', 'mobile']
  const bannerSubTypeOptions = ['bannerImage', 'subBannerImage']
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t(type)} {t('banner')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={{
              languageCode: lang,
              file: '',
              tenantId: type !== 'Create' ? data?.tenantId : '',
              bannerType: type !== 'Create' ? data?.bannerType : '',
              description: type !== 'Create' ? data?.description : '',
              bannerScreenType: type !== 'Create' ? data?.bannerScreenType : '',
              bannerSubType: type !== 'Create' ? data?.bannerSubType : ''
            }}
            validationSchema={uploadBannerSchema(
              {
                type: type,
                ...validation,
              language:getItem('language')

              }, t)}
            onSubmit={(formValues) => {
              createUpdate(formValues)
            }}
          >
            {({
              values,
              errors,
              handleSubmit,
              handleBlur,
              setFieldValue,
              handleChange
            }) => {
              return (
                <Form>
                  <Row className='mt-3'>
                    <Col className='d-flex align-items-center'>
                      <Col>
                        <BForm.Label>{t('owner')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>

                      <Col xs={9}>
                        <BForm.Select
                          type='select'
                          name='tenantId'
                          value={values?.tenantId}
                          disabled={type === 'Update'}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          <option key='' value=''>{t('selectOwner')}</option>
                          {clientsFilterData?.rows?.map(
                            ({ adminUserId, firstName, lastName }) => (
                              <option key={adminUserId} value={adminUserId}>
                                {`${firstName} ${lastName}`}
                              </option>
                            )
                          )}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='tenantId'
                          className='text-danger'
                        />
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col className='d-flex align-items-center'>
                      <Col>
                        <BForm.Label>{t('type')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>
                      <Col xs={9}>
                        <BForm.Select
                          type='select'
                          name='bannerType'
                          value={values?.bannerType}
                          disabled={type === 'Update'}
                          onChange={(e) => {
                            handleChange(e)
                            setValidation(returnValidation(e.target.value,values.bannerScreenType,values.bannerSubType))
                            // setBannerTypeTemp(e.target.value)
                          }}
                          onBlur={handleBlur}
                        >
                          <option key='' value=''>{t('select')}</option>

                          {bannerTypes?.filter(item => !['popupBanner', 'casinoEntryModel'].includes(item))?.map((type, i) => {
                            return (
                              <option key={i} value={type}>{t(type)}</option>
                            )
                          })}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='bannerType'
                          className='text-danger'
                        />
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col className='d-flex align-items-center'>
                      <Col>
                        <BForm.Label>{t('screenType')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>
                      <Col xs={9}>
                        <BForm.Select
                          type='select'
                          name='bannerScreenType'
                          value={values?.bannerScreenType}
                          disabled={type === 'Update'}
                          onChange={(e) => {
                            handleChange(e)
                            setValidation(returnValidation(values.bannerType,e.target.value,values.bannerSubType))
                          }}
                          onBlur={handleBlur}
                        >
                          <option key='' value=''>{t('select')}</option>

                          {screenTypeOptions.map((type, i) => {
                            return (
                              <option key={i} value={type}>{t(type)}</option>
                            )
                          })}
                        </BForm.Select>

                        <ErrorMessage
                          component='div'
                          name='bannerScreenType'
                          className='text-danger'
                        />
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col className=''>
                      <BForm.Label>{t('selectLanguage')}  <span className='text-danger'> *</span></BForm.Label>

                    </Col>
                    <Col xs={9}>
                      <BForm.Select
                        name='languageCode'
                        value={values.languageCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={type !== 'Create'}
                      >
                        {languages && languages?.filter(item => item.isActive).map(({
                          languageName,
                          code
                        }, index) => {
                          return (
                            <option
                              key={index}
                              value={code.toLowerCase()}
                            >
                              {t(String(languageName).toLowerCase())}
                            </option>
                          )
                        })}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='languageCode'
                        className='text-danger'
                      />
                    </Col>
                  </Row>

                  {
                    values?.bannerType === 'rackbackBonusBanner' || values?.bannerType === 'periodicPaybackBonusBanner'
                      ?
                      <Row className='mt-3'>
                        <Col className='d-flex align-items-center'>
                          <Col>
                            <BForm.Label>{t('bannerSubType')}
                              <span className='text-danger'> *</span>
                            </BForm.Label>
                          </Col>
                          <Col xs={9}>
                            <BForm.Select
                              type='select'
                              name='bannerSubType'
                              value={values?.bannerSubType}
                              disabled={type === 'Update'}
                              onChange={(e) => {
                                handleChange(e)
                                setValidation(returnValidation(values.bannerType,values.bannerScreenType,e.target.value))
                              }}
                              onBlur={handleBlur}
                            >
                              <option key='' value=''>{t('select')}</option>

                              {bannerSubTypeOptions.map((type, i) => {
                                return (
                                  <option key={i} value={type}>{t(type)}</option>
                                )
                              })}
                            </BForm.Select>

                            <ErrorMessage
                              component='div'
                              name='bannerSubType'
                              className='text-danger'
                            />
                          </Col>
                        </Col>
                      </Row>
                      :
                      <></>
                  }

                  {
                    values?.bannerSubType === 'subBannerImage' && values?.bannerScreenType === 'web'
                      ?
                      <Row className='mt-3'>
                        <Col className='d-flex align-items-center'>
                          <Col>
                            <BForm.Label> {t('description')}
                              <span className='text-danger'> *</span>
                            </BForm.Label>
                          </Col>

                          <Col xs={9}>
                            <BForm.Control
                              as="textarea"
                              name="description"
                              placeholder={t('enterDescription')}
                              value={values.description}
                              onChange={(event) => {
                                setFieldValue('description', event.target.value);
                                setIsImageUpdated(false);
                              }}
                              onBlur={handleBlur}
                            />

                            <ErrorMessage
                              component='div'
                              name='description'
                              className='text-danger'
                            />
                          </Col>
                        </Col>
                      </Row>
                      :
                      <></>
                  }

                  <Row className='mt-3'>
                    <Col className='d-flex align-items-center'>
                      <Col>
                        <BForm.Label> {t('banner')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                      </Col>

                      <Col xs={9}>
                        <BForm.Control
                          type='file'
                          name='file'
                          onChange={(event) => {
                            setFieldValue('file', event.target.files[0])
                            setValidation(returnValidation(values.bannerType,values.bannerScreenType,values.bannerSubType))
                            if (type !== 'Create') {
                              setIsImageUpdated(false)
                            }
                          }}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='file'
                          className='text-danger'
                        />
                      </Col>
                    </Col>
                  </Row>

                  {!errors?.file &&
                    (type === 'Create'
                      ? values?.file &&
                      <Row className='text-center'>
                        <Col>
                          <img
                            alt='not found'
                            className='mt-2'
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                            src={
                              values?.file &&
                              URL.createObjectURL(values?.file)
                            }
                          />
                        </Col>
                      </Row>
                      : (
                        <Row className='text-center'>
                          <Col>
                            <img
                              alt='bannerfile'
                              className='mt-2'
                              style={{ maxWidth: '300px', maxHeight: '300px' }}
                              src={
                                values?.file
                                  ? URL.createObjectURL(values?.file)
                                  : data?.file
                              }
                            />
                          </Col>
                        </Row>))}

                  <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <Button
                      type='button'
                      variant='outline-warning'
                      onClick={() => setShow(false)}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='outline-success'
                      className='ml-2'
                      disabled={type !== 'Create' ? isImageUpdated : false}
                    >
                      {type === 'Create' ? t('submit') : t('update')} {loading && <Spinner className='ms-1' size='sm' />}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditUploadBanner
