import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createCmsStart } from '../../../../store/redux-slices/tenantsFetchData'
import { useEffect, useState } from 'react'
import { deleteCMSLanguageStart, getCmsCategoryStart, updateSuperAdminCMSStart } from '../../../../store/redux-slices/fetchData'
import { getLanguagesStart } from '../../../../store/redux-slices/languages'
import { EditorState } from 'draft-js'
import { SuperAdminRoutes } from '../../../../routes'
import { useTranslation } from 'react-i18next'
import { getItem } from '../../../../utils/storageUtils'

const useCreateCms = (cmsData) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cmsPageId } = useParams()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [text, setText] = useState()
  const [selectedClient, setSelectedClient] = useState(cmsData ? cmsData?.adminUserId : '')
  const [selectedPortal, setSelectedPortal] = useState(cmsData ? cmsData?.tenantId : '')
  const [err, setErr] = useState('')
  const [selectedTab, setSelectedTab] = useState(getItem('language').toLocaleUpperCase())
  const [template, setTemplate] = useState('')
  const [galleryModal, setGalleryModal] = useState('')
  const [isTouched, setIsTouched] = useState({ client: false, portal: true })
  const { loading } = useSelector((state) => state.tenantsFetch)
  const { cmsKeys, cmsCategory } = useSelector((state) => state.fetch)
  const getUppercaseCodes = (data) => {
    return data?.map(item => item.code.toUpperCase())
  }
  const { languages } = useSelector((state) => state.languages)
  const uppercaseCodes = getUppercaseCodes(languages)

  useEffect(() => {
    dispatch(getCmsCategoryStart())
  }, [])

  useEffect(() => {
    if (!uppercaseCodes?.length || !uppercaseCodes.includes(selectedTab)) {
      setSelectedTab('EN')
    }
  }, [languages])

  useEffect(() => {
    dispatch(getLanguagesStart())
  }, [selectedClient, selectedPortal])

  const createCms = (data) => dispatch(createCmsStart({
    cmsData: data,
    navigate
  }))

  const editCms = (data) => {
    dispatch(updateSuperAdminCMSStart(data))
    navigate(SuperAdminRoutes.CMS)
  }

  const deleteCms = (data) =>
    dispatch(deleteCMSLanguageStart({ isTenant: false, data }))

  return {
    navigate,
    t,
    loading,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    isTouched,
    setIsTouched,
    err,
    setErr,
    editCms,
    cmsPageId,
    template,
    setTemplate,
    galleryModal,
    setGalleryModal,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages: uppercaseCodes,
    deleteCms,
    editorState,
    setEditorState,
    text,
    setText,
    cmsCategory
  }
}

export default useCreateCms
