import React from 'react'
import { Button, Col, Form, Modal, Row, Table } from '@themesberg/react-bootstrap'
import { useTranslation } from 'react-i18next'
import Trigger from '../../../../components/OverlayTrigger'
import useInspectionDetails from '../hooks/useInspectionDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { tableInspectionHeaders } from '../constants'
import Preloader from '../../../../components/Preloader'

const InspectionDetails = ({
  userData
}) => {
  const { t } = useTranslation()

  const {
    initialDate,
    contentFilter,
    setContentFilter,
    setState,
    gameCategory,
    gameSubCategory,
    casinoProvidersData,
    initialState,
    state,
    userInpectedData,
    loading,
    show,
    setShow
  } = useInspectionDetails(userData)
  const wagerType = {
    WIN: 'totalWinAmount',
    BET: 'totalBetAmount',
    REFUND: 'totalRefundAmount',
    CANCEL: 'totalCancelAmount',
    LOST: 'totalLostAmount',
    TIP: 'totalTipAmount',
    'JACKPOT-WIN': 'totalJackpotAmount'
  }

  return (
    <>
      <Row className='search-section mt-5'>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('category')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, categoryId: e.target.value })
            }}
            value={contentFilter.categoryId}
          >
            <option value=''>{t('selectCategory')}</option>
            {gameCategory?.rows?.map(
              ({
                name,
                mastergamecategoryid
              }) => (
                <option key={mastergamecategoryid} value={mastergamecategoryid}>
                  {name.EN}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('subCategory')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, subCategoryId: e.target.value })
            }}
            value={contentFilter.subCategoryId}
          >
            <option value=''>{t('selectSubCategory')}</option>
            {gameSubCategory?.rows?.map(
              ({
                name,
                masterGameSubCategoryId
              }) => (
                <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                  {name.EN}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {t('provider')}
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              setContentFilter({ ...contentFilter, providerId: e.target.value })
            }}
            value={contentFilter.providerId}
          >
            <option value=''>{t('selectProvider')}</option>
            {casinoProvidersData?.rows?.map(
              ({
                name,
                masterCasinoProviderId
              }) => (
                <option key={masterCasinoProviderId} value={masterCasinoProviderId}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              fontSize: '13px'
            }}
          >
            {t('startDate')}
          </Form.Label>
          <Form.Control
            type='datetime-local'
            placeholder={t('selectStartDate')}
            value={state[0].startDate}
            onChange={(e) => {
              setState([{ ...state[0], startDate: e.target.value }])
            }}
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mt-2'>
          <Form.Label
            style={{
              width: 'auto',
              marginBottom: '0',
              marginRight: '15px',
              fontSize: '13px'
            }}
          >
            {t('endDate')}
          </Form.Label>
          <Form.Control
            type='datetime-local'
            placeholder={t('selectEndDate')}
            value={state[0].endDate}
            onChange={(e) => {
              setState([{ ...state[0], endDate: e.target.value }])
            }}
            style={{
              width: '230px',
              marginRight: '10px',
              maxHeight: '32px',
              fontSize: '13px'
            }}
          />
        </Col>
        <Col xs='auto' className='d-flex mb-3 gap-3'>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setState(initialDate)
                setContentFilter(initialState)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
          <Trigger message={t('viewInspectedData')}>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setShow(true)
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      {
      loading
        ? (
          <Preloader />
          )
        : (
          <Row className='d-flex py-2 gap-4'>
            <Col>
              <Table bordered striped responsive hover size='sm' className='text-center'>
                <thead className='thead-dark'>
                  <tr>
                    {tableInspectionHeaders.map((h, idx) => (
                      <th key={idx}>
                        {t(h.label)}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody id={loading ? 'cover-spin' : ''}>
                  {userInpectedData.userTransactions &&
                    userInpectedData.userTransactions?.map(
                      (
                        item,
                        index
                      ) => {
                        return (
                          <tr key={index}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{item?.actionType || '-'}</td>
                            <td>{item?.totalCount || '-'}</td>
                            <td>{item[wagerType[item?.actionType]] || '-'}</td>
                          </tr>

                        )
                      }
                    )}
                  {userInpectedData.userTransactions?.length === 0 && !loading &&
                        (
                          <tr>
                            <td
                              colSpan={8}
                              className='text-danger text-center'
                            >
                              {t('noDataFound')}
                            </td>
                          </tr>
                        )}
                </tbody>
              </Table>
            </Col>
          </Row>

          )
     }
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header className='d-flex justify-content-center' closeButton>
          <Modal.Title>{t('inspectedData')}</Modal.Title> {/* Translate the title */}
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex p-2 shadow-sm justify-content-between  gap-4'>
            <span style={{ fontSize: '14px' }}>{t('fullName')}</span>
            <span><b>{userData?.legalName}</b></span>
          </div>
          <div className='d-flex p-2 shadow-sm justify-content-between gap-4'>
            <span style={{ fontSize: '14px' }}>{t('totalDepositAmount')}</span>
            <span>
              <b>
                {userInpectedData?.inspectedData?.totalDepositAmount + ' ' + userInpectedData?.inspectedData?.currencyCode ??
                0 + ' ' + userInpectedData?.inspectedData?.currencyCode}
              </b>
            </span>
          </div>
          <div className='d-flex p-2 shadow-sm justify-content-between gap-4'>
            <span style={{ fontSize: '14px' }}>{t('totalWithDrawlAmount')}</span>
            <span>
              <b>
                {userInpectedData?.inspectedData?.totalWithDrawlAmount + ' ' + userInpectedData?.inspectedData?.currencyCode ??
                 0 + ' ' + userInpectedData?.inspectedData?.currencyCode}
              </b>
            </span>
          </div>
          <div className='d-flex p-2 shadow-sm justify-content-between gap-4'>
            <span style={{ fontSize: '14px' }}>{t('totalNonCashAmount')}</span>
            <span>
              <b>
                {userInpectedData?.inspectedData?.totalNonCashAmount + ' ' + userInpectedData?.inspectedData?.currencyCode ??
                0 + ' ' + userInpectedData?.inspectedData?.currencyCode}
              </b>
            </span>
          </div>
          <div className='d-flex p-2 shadow-sm justify-content-between gap-4'>
            <span style={{ fontSize: '14px' }}>{t('inspectedAmount')}</span>
            <span>
              <b>
                {userInpectedData?.inspectedData?.inspectedAmount ?? 0 + ' ' + userInpectedData?.inspectedData?.currencyCode}
              </b>
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InspectionDetails
