import { faEdit, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import Trigger from '../../../components/OverlayTrigger'

const TableRow = ({ bannerKey: key, ObjectOfData, typeOf, item, isHidden, t, lang, handleCreateEdit, ImgObj, handleDelete, bannerScreenKey, subBanner, description }) => {
  let noData = true
  if (typeOf === 'object') {
    if (Object.keys(ObjectOfData || {}).length < 1) {
      noData = false
    }
  } else {
    if (!ObjectOfData) {
      noData = false
    }
  }
  if (!noData) {
    return (
      <>
        {
          !['popupBanner', 'casinoEntryModel'].includes(key) &&
            <tr>
              <td>
                {t(key)}
              </td>
              <td>
                <div className='py-2'>
                  -
                </div>
              </td>
            </tr>
        }

      </>
    )
  }
  return (
    <>{
      !['popupBanner', 'casinoEntryModel'].includes(key) &&
        <tr>
          <td>
            {subBanner ? t(`${key}sub`) : t(key)}
          </td>
          <td>
            {typeOf === 'string'
              ? (
                <div
                  key={1}
                  className='d-flex
          mt-2
          align-items-center
          justify-content-center
          p-2'
                >
                  <span
                    style={{
                      cursor: 'pointer',
                      marginRight: 10
                    }}
                    className='text-link'
                    onClick={() => window.open(item?.bannerUrl[key][lang])}
                  >
                    {t('bannerPreview')}
                  </span>
                  {isHidden({ module: { key: 'BannerManagement', value: 'U' } })
                    ? '-'
                    : (
                      <div className='d-flex gap-4'>
                        <Trigger message='Update Banner'>
                          <Button
                            className='ml-2'
                            size='sm'
                            variant='warning'
                            onClick={() => {
                              handleCreateEdit('Update',
                                {
                                  tenantId: item?.tenantId,
                                  bannerType: key,
                                  file: item?.bannerUrl[key][lang]
                                })
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                        <Trigger message='Delete Banner'>
                          <Button
                            className='ml-2'
                            size='sm'
                            variant='danger'
                            onClick={() => {
                              handleDelete('Delete',
                                {
                                  tenantId: item?.tenantId,
                                  bannerType: key,
                                  file: item?.bannerUrl[key][lang]

                                })
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </Button>
                        </Trigger>
                      </div>
                      )}
                </div>
                )
              : (
                <>
                  {
                  Object.keys(ImgObj[key][lang] || {}).map((imgKey) => (
                    <div
                      key={imgKey}
                      className='d-flex
                mt-2
                align-items-center
                justify-content-center
                p-2'
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                          marginRight: 10
                        }}
                        className='text-link'
                        onClick={() => window.open(ImgObj[key][lang][imgKey])}
                      >
                        {t('bannerPreview')}
                      </span>
                      {isHidden({ module: { key: 'BannerManagement', value: 'U' } })
                        ? '-'
                        : (
                          <div className='d-flex gap-4'>
                            <Trigger message='Update Banner'>
                              <Button
                                className='ml-6'
                                size='sm'
                                variant='warning'
                                onClick={() => {
                                  handleCreateEdit('Update',
                                    {
                                      tenantId: item?.tenantId,
                                      bannerType: key,
                                      file: ImgObj[key][lang][imgKey],
                                      imageKey: imgKey,
                                      ...(description && { description: description[key][lang] }),
                                      bannerScreenType: bannerScreenKey,
                                      bannerSubType: subBanner ? 'subBannerImage' : 'bannerImage'
                                    })
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>

                            <Trigger message='Delete Banner'>
                              <Button
                                className='ml-2'
                                size='sm'
                                variant='danger'
                                onClick={() => {
                                  handleDelete('Delete',
                                    {
                                      tenantId: item?.tenantId,
                                      bannerType: key,
                                      imageKey: imgKey,
                                      bannerScreenType: bannerScreenKey,
                                      bannerSubType: subBanner ? 'subBannerImage' : 'bannerImage'
                                    })
                                }}
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </Button>
                            </Trigger>
                          </div>
                          )}
                    </div>
                  ))
                }

                </>
                )}
          </td>

        </tr>
    }
    </>
  )
}

export default TableRow
