/* eslint-disable */
import * as Yup from 'yup'
import { t } from 'i18next'
// eslint-disable-next-line no-unused-vars
const imageDimensionCheckSportsbook = Yup.addMethod(Yup.mixed, 'imageDimensionCheckSportsbook', function (message, minRequiredWidth, minRequiredHeight, maxRequiredWidth, maxRequiredHeight) {
  return this.test('image-width-height-check', message, async function (value) {
    const { path, createError } = this

    if (!value) {
      return
    }

    const imgDimensions = await imageWidthAndHeight(value)
    if (imgDimensions.width !== minRequiredWidth) {
      return createError({
        path,
        message: `${t('imageWidthBetween')}` + ' ' + `${minRequiredWidth}px - ${maxRequiredWidth}px!`
      })
    }

    if (imgDimensions.height !== minRequiredHeight) {
      return createError({
        path,
        message: `${t('imageHeightBetween')}` + ' ' + `${minRequiredHeight}px - ${maxRequiredHeight}px!`
      })
    }

    return true
  })
})
export const updateLogoSchemaNew = (t, {
  minRequiredWidth,
  minRequiredHeight,
  maxRequiredWidth,
  maxRequiredHeight
}) => {
  return Yup.object().shape({
    file: Yup.mixed()
      .required(t('fileRequired'))
      .test('fileFormat', t('imageFormatRequired'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(value.type)
      })
      .test('fileSize', t('sizeTooLarge'), value => {
        if (typeof value === 'string') {
          return true
        }
        if (!value) return true // if no file is uploaded, validation passes
        return value.size <= 10485760 // 10MB limit
      })
      .imageDimensionCheckSportsbook('Banner Required',
        minRequiredWidth,
        minRequiredHeight,
        maxRequiredWidth,
        maxRequiredHeight)

  })
}

const imageWidthAndHeight = (provideFile) => {
  const imgDimensions = { width: null, height: null }
  if (typeof provideFile === 'string') {
    return {
      width: 100,
      height: 100
    }
  }

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.readAsDataURL(provideFile)
    reader.onload = function () {
      const img = new Image()
      img.src = reader.result

      img.onload = function () {
        imgDimensions.width = img.width
        imgDimensions.height = img.height

        resolve(imgDimensions)
      }
    }
  })
}
