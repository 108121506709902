export const kycLabels = [
  { value: '', label: 'all' },
  { value: 'APPROVED', label: 'approved' },
  { value: 'PENDING', label: 'pending' },
  { value: 'REJECTED', label: 'rejected' }
]

export const isAnonymousFilter = [
  { value: '', label: 'all' },
  { value: false, label: 'known' },
  { value: true, label: 'anonymous' }

]
export const tableHeaders = [
  { label: 'sNo', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'affiliate', value: 'affiliate' },
  { label: 'agent', value: 'agent' },
  { label: 'registrationDate', value: 'regDate' },
  { label: 'country', value: 'country' },
  { label: 'kycStatus', value: 'kycStatus' },
  { label: 'kycSeniorStatus', value: 'kycSeniorStatus' },
  { label: 'vaultAmount', value: 'vaultAmount' },
  { label: 'amount', value: 'amount' },
  { label: 'withdrawable', value: 'withdrawableAmount' },
  { label: 'nonWithdrawable', value: 'nonWithdrawableAmount' },
  { label: 'nonCashAmount', value: 'nonCashAmount' },
  { label: 'promotionCash', value: 'promotionCash' },
  { label: 'bonusAmount', value: 'bonusAmount' },
  { label: 'payoutAmount', value: 'payoutAmount' },
  { label: 'superadminNotes', value: 'superadminNotes' },
  { label: 'seniorNotes', value: 'seniorNotes' },
  { label: 'withdrwalLimit', value: 'withdrwalLimit' },
  { label: 'currentIPAddress', value: 'currentIPAddress' },
  { label: 'PrevIpAddress', value: 'PrevIpAddress' },
  { label: 'redFlag', value: 'redFlagStatus' },
  { label: 'status', value: 'status' },
  { label: 'action', value: 'action' }
]

export const tableHeadersV2 = [
  { label: 'sNo', value: '' },
  { label: 'userName', value: 'username' },
  { label: 'affiliate', value: 'affiliate' },
  { label: 'agent', value: 'agent' },
  { label: 'registrationDate', value: 'regDate' },
  { label: 'country', value: 'country' },
  { label: 'kycStatus', value: 'kycStatus' },
  { label: 'kycSeniorStatus', value: 'kycSeniorStatus' },
  { label: 'vaultAmount', value: 'vaultAmount' },
  { label: 'withdrawable', value: 'withdrawableAmount' },
  { label: 'nonWithdrawable', value: 'nonWithdrawableAmount' },
  { label: 'casinoPromotionCash', value: 'casinoPromotionCash' },
  { label: 'sportsPromotionCash', value: 'sportsPromotionCash' },
  { label: 'casinoBonusAmount', value: 'casinoBonusAmount' },
  { label: 'sportsBonusAmount', value: 'sportsBonusAmount' },
  { label: 'casinoPayoutAmount', value: 'casinoPayoutAmount' },
  { label: 'sportsPayoutAmount', value: 'sportsPayoutAmount' },
  { label: 'superadminNotes', value: 'superadminNotes' },
  { label: 'seniorNotes', value: 'seniorNotes' },
  { label: 'withdrwalLimit', value: 'withdrwalLimit' },
  { label: 'currentIPAddress', value: 'currentIPAddress' },
  { label: 'PrevIpAddress', value: 'PrevIpAddress' },
  { label: 'redFlag', value: 'redFlagStatus' },
  { label: 'status', value: 'status' },
  { label: 'action', value: 'action' }
]

export const playerTypes = [
  { value: 'all', label: 'all' },
  { value: 'allDepositors', label: 'depositors' },
  { value: 'newDepositors', label: 'firstDepositors' },
  { value: 'nonDepositors', label: 'nonDepositors' }
]

export const playerCashierHeaders = [
  { label: 'id', value: 'userId' },
  { label: 'userName', value: 'username' },
  { label: 'playerName', value: 'playerName' }
]

export const playerRestrictTransactionType = [
  { label: 'none', value: 'none' },
  { label: 'deposit', value: 'deposit' },
  { label: 'withdraw', value: 'withdraw' }
]
