import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

function HtmlTextEditor (props) {
  const {
    value,
    setValue,
    touched,
    error,
    editorStyle = { border: '1px solid #c9c3c3', height: '38vh' },
    toolbarStyle = { border: '1px solid #c9c3c3' },
    readOnly
  } = props

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (newState) => {
    setEditorState(newState)
  }

  useEffect(() => {
    if (value?.length !== null) {
      const blocksFromHTML = convertFromHTML(value)
      const defaultContentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(defaultContentState))
    }
  }, [])

  useEffect(() => {
    const contentState = editorState.getCurrentContent()
    const htmlContent = draftToHtml(convertToRaw(contentState))
    setValue(htmlContent)
  }, [editorState])

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
        readOnly={readOnly}
      />
      {touched && error && <span className='error-msg'>{error}</span>}
    </div>
  )
}

export default HtmlTextEditor
