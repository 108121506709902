import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { returnLangName } from '../../../utils/storageUtils'

const RollingDetails = () => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='p-3'>
      <div
        role='button'
        onClick={() => navigate(-1)}
        className='d-flex align-items-center gap-2 mb-4'
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <h3 className='mb-0'>{t('details')}</h3>
      </div>

      <div className='details-container'>
        <DetailRow label={t('name')} value={returnLangName(state?.name)} />
        <DetailRow label={t('description')} value={state?.eventDescriptions} />
        <DetailRow
          label={t('startDate')}
          value={moment(state?.validFrom).format('MM-DD-YYYY  hh:mm a')}
        />
        <DetailRow
          label={t('endDate')}
          value={moment(state?.validTo).format('MM-DD-YYYY  hh:mm a')}
        />
        <DetailRow
          label={t('status')}
          value={
            <span className={state?.isActive ? 'text-success' : 'text-danger'}>
              {state?.isActive ? t('active') : t('inActive')}
            </span>
          }
        />

        {state?.isEvent && (
          <>
            <DetailRow label={t('imageText')} value={returnLangName(state?.imageText ?? '-')} />
            <DetailRow label={t('imageDescription')} value={returnLangName(state?.imageDescription ?? '-')} />
          </>
        )}

        <DetailRow
          label={t('desktopBanner')}
          value={
            <img
              style={{ width: 300, objectFit: 'contain' }}
              src={state?.thumbUrl}
              alt={t('desktopBanner')}
            />
          }
        />

        {state?.isEvent && (
          <DetailRow
            label={t('mobileBanner')}
            value={
              <img
                style={{ width: 300, objectFit: 'contain' }}
                src={state?.mobileThumbUrl ?? ''}
                alt={t('mobileBanner')}
              />
            }
          />
        )}
      </div>
    </div>
  )
}

const DetailRow = ({ label, value }) => (
  <div className='d-flex align-items-center gap-2 mb-3'>
    <b style={{ minWidth: 200 }}>{label}</b>
    <span>{value}</span>
  </div>
)

export default RollingDetails
