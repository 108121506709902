import { takeLatest, put, select } from 'redux-saga/effects'
import {
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  updateSuperadminTransactionStatusStart,
  updateSuperadminTransactionStatusSuccess,
  updateSuperadminTransactionStatusFailure,
  getSuperAdminAllTransactionsStart,
  getSuperAdminAllTransactionsSuccess,
  getSuperAdminAllTransactionsFailure,
  getBannedGamesDetailsStart,
  getBannedGamesDetailsSuccess,
  getBannedGamesDetailsFailure,
  getAllWalletTransactionsStart,
  getAllWalletTransactionsSuccess,
  getAllWalletTransactionsFailure,

  depositSuperAdminAmountStart,
  depositSuperAdminAmountSuccess,
  depositSuperAdminAmountFailure,
  updateUserWalletLimitStart,
  updateUserWalletLimitSuccess,
  updateUserWalletLimitFailure,
  withdrawalSuperAdminAmountStart,
  withdrawalSuperAdminAmountSuccess,
  withdrawalSuperAdminAmountFailure,
  getSuperAdminBonusTransactionsStart,
  getSuperAdminBonusTransactionsSuccess,
  getSuperAdminBonusTransactionsFailure,
  getSeniorWalletListStart,
  getSeniorWalletListSuccess,
  getSeniorWalletListFailure,
  nonCashWalletSuperAdminStart,
  nonCashWalletSuperAdminSuccess,
  nonCashWalletSuperAdminFailure,
  updateSeniorTransactionActionStart,
  updateSeniorTransactionActionSuccess,
  updateSeniorTransactionActionFailure,
  getSuperAdminSportsTransactionStart,
  getSuperAdminSportsTransactionSuccess,
  getSuperAdminSportsTransactionFailure,
  getSportsBookTransactionStart,
  getSportsBookTransactionSuccess,
  getSportsBookTransactionFailure,
  cancelBetSlipFailure,
  cancelBetSlipSuccess,
  cancelBetSlipStart,
  updateUserTransactionActionStart,
  updateUserTransactionActionSuccess,
  updateUserTransactionActionFailure,
  getAddonConfigDropDownStart,
  getAddonConfigDropDownSuccess,
  getAddonConfigDropDownFailure
} from '../redux-slices/superAdminTransactions'
import {
  getAllSuperAdminTransactions,
  getAllWalletTransactions,
  getBannedGamesDetails,
  getSuperAdminAllTransactions,
  getSuperAdminCasinoTransactions,
  updateTransactionStatus,
  updateUserTransactionSuperAdmin,
  depositSuperAdminAmount,
  updateUserWalletLimitSuperAdmin,
  withdrawalSuperAdminAmount,
  getSuperAdminBonusTransactions,
  getSeniorWalletTransactions,
  nonCashWalletSuperAdmin,
  updateSeniorTransactionSuperAdmin,
  getSportsTransactions,
  getSportsBookTransactions,
  cancelBetslip,
  getAddonDropDown
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { getAllTenantTransactionsStart } from '../redux-slices/tenantTransactions'
import { getUserStart, getUserSuccess } from '../redux-slices/fetchData'
import { getAllClientsStart } from '../redux-slices/tenants'
const getUserData = state => state.fetch
export default function * SuperAdminTransactionsWatcher () {
  yield takeLatest(getAllSuperAdminTransactionsStart.type, getAllSuperAdminTransactionsWorker)
  yield takeLatest(getSuperAdminBonusTransactionsStart.type, getSuperAdminBonusTransactionsWorker)
  yield takeLatest(getSuperAdminCasinoTransactionsStart.type, getSuperAdminCasinoTransactionsWorker)
  yield takeLatest(updateSuperadminTransactionStatusStart.type, updateSuperadminTransactionStatusWorker)
  yield takeLatest(getSuperAdminAllTransactionsStart.type, getSuperAdminAllTransactionsWorker)
  yield takeLatest(getBannedGamesDetailsStart.type, getBannedGamesDetailsWorker)
  yield takeLatest(getAllWalletTransactionsStart.type, getAllWalletTransactionsWorker)
  yield takeLatest(updateUserTransactionActionStart.type, updateUserTransactionActionWorker)
  yield takeLatest(depositSuperAdminAmountStart.type, depositSuperAdminAmountWorker)
  yield takeLatest(withdrawalSuperAdminAmountStart.type, withdrawalSuperAdminAmountWorker)
  yield takeLatest(updateUserWalletLimitStart.type, updateUserWalletLimitWorker)
  yield takeLatest(getSeniorWalletListStart.type, getSeniorWalletTransactionsWorker)
  yield takeLatest(nonCashWalletSuperAdminStart.type, nonCashWalletSuperAdminWorker)
  yield takeLatest(updateSeniorTransactionActionStart.type, updateSeniorTransactionActionWorker)
  yield takeLatest(getSuperAdminSportsTransactionStart.type, getSuperAdminSportsTransactionWorker)
  yield takeLatest(getSportsBookTransactionStart.type, getSportsBookTransactionWorker)
  yield takeLatest(cancelBetSlipStart.type, cancelBetslipWorker)
  yield takeLatest(getAddonConfigDropDownStart.type, getAddonConfigDropDownWorker)
}
function * getAddonConfigDropDownWorker (action) {
  try {
    const {
      tenantId,
      adminId,
      affiliateId,
      agentId,
      createdAt,
      amount,
      userId
    } = action && action.payload
    const { data } = yield getAddonDropDown({
      tenantId,
      adminId,
      affiliateId,
      agentId,
      createdAt,
      amount,
      userId
    })
    yield put(getAddonConfigDropDownSuccess(data?.data?.tenantAddonConfigs))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'erorr')
    yield getAddonConfigDropDownFailure()
  }
}
function * updateSeniorTransactionActionWorker (action) {
  try {
    const {
      status,
      transactionId,
      seniorId,
      seniorWalletRequestId,
      form
    } = action && action.payload
    const { data } = yield updateSeniorTransactionSuperAdmin({
      status,
      transactionId,
      seniorId,
      seniorWalletRequestId,
      form
    })
    yield updateSeniorTransactionActionSuccess(data?.data?.message)
    status === 'ACCEPT'
      ? yield toast('Transaction request accepted', 'success')
      : yield toast('Transaction request rejected', 'success')
    yield put(getSeniorWalletListStart({
      form: {
        ...form,
        status: ''
      }
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'erorr')
    yield updateSeniorTransactionActionFailure()
  }
}
function * nonCashWalletSuperAdminWorker (action) {
  try {
    const {
      actionType,
      amount,
      currency,
      seniorId,
      tenantId,
      setShow
    } = action && action.payload
    const { data } = yield nonCashWalletSuperAdmin({
      data: {
        action: actionType,
        amount,
        currency,
        seniorId,
        tenantId
      }
    })
    yield nonCashWalletSuperAdminSuccess(data?.data?.message)
    yield toast('Transaction Successfull', 'success')
    setShow(false)
    yield put(getAllClientsStart({
      limit: 15,
      pageNo: 1
    }))
  } catch (e) {
    yield nonCashWalletSuperAdminFailure()
    yield toast(e?.response?.data?.errors[0].description, 'eror')
  }
}

function * getSeniorWalletTransactionsWorker (action) {
  try {
    const {
      form
    } =
      action && action.payload
    const { data } = yield getSeniorWalletTransactions(form)
    yield put(getSeniorWalletListSuccess(data?.data))
  } catch (e) {
    yield put(getSeniorWalletListFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * updateUserWalletLimitWorker (action) {
  try {
    const {
      userId,
      amount,
      currency
    } = action && action.payload
    const { data } = yield updateUserWalletLimitSuperAdmin({
      userId,
      withdrawlLimit: amount,
      withdrawlLimitCurrency: currency
    })
    yield updateUserWalletLimitSuccess(data?.data?.message)
    yield toast('Limit Updated', 'success')
    const { userData } = yield select(getUserData)
    yield put(getUserSuccess({
      ...userData,
      withdrawlLimit: amount,
      withdrawlLimitCurrency: currency
    }))
  } catch (e) {
    yield updateUserWalletLimitFailure()
    yield toast(e?.response?.data?.errors[0].description, 'eror')
  }
}

function * updateUserTransactionActionWorker (action) {
  try {
    const {
      actionType,
      actionSource,
      transactionId,
      handleClose,
      tenantId,
      addOnConfigData,
      tenantConfiguration
    } = action && action.payload
    const { data } = yield updateUserTransactionSuperAdmin({
      actionType,
      actionSource,
      transactionId,
      tenantId,
      addOnConfigData,
      tenantConfiguration
    })
    yield put(updateUserTransactionActionSuccess(data?.data?.message))
    yield toast(data?.data?.message, 'success')
    yield handleClose()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'erorr')
    yield put(updateUserTransactionActionFailure())
  }
}

function * getAllWalletTransactionsWorker (action) {
  try {
    const {
      form
    } =
      action && action.payload
    const { data } = yield getAllWalletTransactions(form)
    yield put(getAllWalletTransactionsSuccess(data?.data))
  } catch (e) {
    yield put(getAllWalletTransactionsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * getAllSuperAdminTransactionsWorker (action) {
  try {
    const {
      limit, pageNo, search, startDate, endDate, currencyId, transactionType, tenantId, adminId, paymentProvider, isUserDetail,
      userId, status, countryCode, transactionId, createdAtStartDate, createdAtEndDate, paymentMethod, check
    } =
      action && action.payload

    const { data } = yield getAllSuperAdminTransactions({
      limit,
      pageNo,
      search,
      startDate,
      endDate,
      currencyId,
      transactionType,
      tenantId,
      adminId,
      paymentProvider,
      isUserDetail,
      userId,
      status,
      countryCode,
      transactionId,
      createdAtEndDate,
      createdAtStartDate,
      paymentMethod,
      check
    })

    yield put(getAllSuperAdminTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getAllSuperAdminTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminCasinoTransactionsWorker (action) {
  try {
    const {
      status,
      email,
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      adminId,
      userId,
      contentFilter,
      isUserV2,
      nonWithdrawableRolled,
      bonusRolled,
      promotionRolled,
      casinoTransactionType
    } =
      action && action.payload

    const { data } = yield getSuperAdminCasinoTransactions({
      limit,
      pageNo,
      startDate,
      endDate,
      currencyCode,
      transactionType,
      tenantId,
      status,
      email,
      adminId,
      userId,
      isUserV2,
      nonWithdrawableRolled,
      bonusRolled,
      promotionRolled,
      casinoTransactionType,
      ...contentFilter
    })

    yield put(getSuperAdminCasinoTransactionsSuccess(data?.data?.transactionDetail))
  } catch (e) {
    yield put(getSuperAdminCasinoTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * getSuperAdminBonusTransactionsWorker (action) {
  const { data: formData, isReporting } = action.payload
  try {
    const { data } = yield getSuperAdminBonusTransactions(formData, isReporting)
    yield put(getSuperAdminBonusTransactionsSuccess(data?.data?.casinoTransactionDetails))
  } catch (e) {
    yield put(getSuperAdminBonusTransactionsFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSuperadminTransactionStatusWorker (action) {
  try {
    const {
      userId,
      transactionId,
      statusTransactionType,
      paymentTransactionId,
      statusAction,
      playerId,
      comment,
      isTenant,
      myTransactionId
    } = action && action.payload

    yield updateTransactionStatus({ userId, transactionId, paymentTransactionId, status: statusAction, transactionType: statusTransactionType, comment, isTenant })

    yield put(updateSuperadminTransactionStatusSuccess())
    yield toast('Record updated successfully', 'success')

    isTenant
      ? yield put(getAllTenantTransactionsStart({ ...action.payload, transactionId: myTransactionId, userId: playerId, isUserDetail: !!playerId }))
      : yield put(getAllSuperAdminTransactionsStart({ ...action.payload, transactionId: myTransactionId, userId: playerId, isUserDetail: !!playerId }))
  } catch (e) {
    yield put(updateSuperadminTransactionStatusFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminAllTransactionsWorker (action) {
  try {
    const { data } = yield getSuperAdminAllTransactions(action.payload)

    yield put(getSuperAdminAllTransactionsSuccess(data?.data?.allTransactions))
  } catch (e) {
    yield put(getSuperAdminAllTransactionsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getBannedGamesDetailsWorker (action) {
  try {
    const { data } = yield getBannedGamesDetails(action.payload)

    yield put(getBannedGamesDetailsSuccess(data?.data))
  } catch (e) {
    yield put(getBannedGamesDetailsFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * depositSuperAdminAmountWorker (action) {
  try {
    const { data } = yield depositSuperAdminAmount(action.payload)
    yield put(depositSuperAdminAmountSuccess(data?.data))
    yield toast(data?.data?.message, 'success')
    yield put(getUserStart({ userId: action.payload.userId }))
  } catch (e) {
    yield put(depositSuperAdminAmountFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
function * withdrawalSuperAdminAmountWorker (action) {
  try {
    const { data } = yield withdrawalSuperAdminAmount(action.payload)
    yield put(withdrawalSuperAdminAmountSuccess(data?.data))
    yield toast(data?.data?.message, 'success')
    yield put(getUserStart({ userId: action.payload.userId }))
  } catch (e) {
    yield put(withdrawalSuperAdminAmountFailure())
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminSportsTransactionWorker (action) {
  const { isTenant, ...rest } = action && action.payload
  try {
    const { data } = yield getSportsTransactions(isTenant, rest)
    yield put(getSuperAdminSportsTransactionSuccess(data?.data?.sbBetHistoryReport))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getSuperAdminSportsTransactionFailure(e.response.data.message))
  }
}

function * getSportsBookTransactionWorker (action) {
  try {
    const { data } = yield getSportsBookTransactions(action.payload)
    yield put(getSportsBookTransactionSuccess(data?.data?.sbTransactionsReport))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getSportsBookTransactionFailure(e.response.data.message))
  }
}

function * cancelBetslipWorker (action) {
  try {
    const { betslipId, isTenant, onSuccess } = action && action.payload
    const { data } = yield cancelBetslip({ betslipId, isTenant })
    yield put(cancelBetSlipSuccess({}))
    yield onSuccess()
    yield toast(data?.data?.message, 'success')
  } catch (e) {
    yield put(cancelBetSlipFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
