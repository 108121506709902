import React from 'react'
import { Row, Col, Table, Form as BForm } from '@themesberg/react-bootstrap'
import useBannerManagement from './useBannerManagement'
import UploadBanner from './UploadBanner'
// import { bannerType } from './constants'
import TableRow from './TableRow'

export default () => {
  const {
    show,
    setShow,
    upload,
    data,
    setData,
    banners,
    isHidden,
    validation,
    setValidation,
    loading,
    t,
    lang,
    setLang,
    languages,
    updateLoading,
    bannerKey,
    setBannerKey,
    subBannerKey,
    setSubBannerKey,
    screenType,
    setScreenType,
    screenTypeOptions
  } = useBannerManagement()

  return (
    <>
      <Row>
        <Col>
          <h3>{t('bannerManagement')}</h3>
        </Col>
        <Col className='d-flex'>
          <div className='d-flex justify-content-end gap-2 align-items-center'>
            <div className=''>
              <BForm.Label>{t('selectLanguage')}</BForm.Label>
            </div>
            <div>
              <BForm.Select
                name='languageCode'
                value={lang}
                onChange={(e) => {
                  setLang(e.target.value)
                }}
              >
                {languages && languages?.map(({
                  languageName,
                  code
                }, index) => {
                  return (
                    <option
                      key={index}
                      value={code.toLowerCase()}
                    >
                      {t(String(languageName).toLowerCase())}
                    </option>
                  )
                })}
              </BForm.Select>
            </div>

          </div>

          <div className='d-flex justify-content-end gap-2 ms-3 align-items-center'>
            <div className=''>
              <BForm.Label>{t('selectScreenType')}</BForm.Label>
            </div>
            <div>
              <BForm.Select
                name='selectScreenType'
                value={screenType}
                onChange={(e) => {
                  setScreenType(e.target.value)
                  const a = screenTypeOptions.find((item) => {
                    return item.code === e.target.value
                  })
                  setBannerKey(a.key)
                  setSubBannerKey(a.subBannerKey)
                }}
              >
                {
                  screenTypeOptions
                    .map(({ name, code }, index) => (
                      <option key={index} value={code}>
                        {t(name)}
                      </option>
                    ))
}
              </BForm.Select>
            </div>
          </div>
        </Col>
      </Row>
      <Table bordered striped responsive hover size='sm' className='mt-3 text-center'>
        <thead className='thead-dark'>
          <tr>
            <th>{t('pages')}</th>
            <th>{t('bannerUrl')}</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>

          {!loading && banners &&
            Object.keys(banners[bannerKey]).map((banner, index) => {
              const ImgObj = banners[bannerKey]
              const typeOf = typeof banners[bannerKey][banner][lang]
              return (
                <TableRow bannerScreenKey={screenType} key={banner} bannerKey={banner} lang={lang} ObjectOfData={banners[bannerKey][banner][lang]} typeOf={typeOf} banner={banner} banners={banners} isHidden={isHidden} t={t} setData={setData} setShow={setShow} ImgObj={ImgObj} subBanner={false} />
              )
            })}

          {!loading && banners &&
            Object.keys(banners[subBannerKey]).map((banner, index) => {
              const ImgObj = banners[subBannerKey]
              const typeOf = typeof banners[subBannerKey][banner][lang]
              const descriptionObject = banners?.description[banner][lang]

              return (
                <TableRow bannerScreenKey={screenType} bannerKey={banner} key={banner} lang={lang} ObjectOfData={banners[subBannerKey][banner][lang]} typeOf={typeOf} banner={banner} banners={banners} isHidden={isHidden} t={t} setData={setData} setShow={setShow} ImgObj={ImgObj} subBanner description={descriptionObject} />
              )
            })}

          {!loading && (!banners || Object.keys(banners)?.length < 1) &&
            (
              <tr>
                <td
                  colSpan={3}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>

      {show &&
        <UploadBanner
          loading={updateLoading}
          show={show}
          setShow={setShow}
          upload={upload}
          data={data}
          validation={validation}
          setValidation={setValidation}
        />}
    </>
  )
}
