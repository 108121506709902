import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllUsersStart, clearAllUsers, getCountriesStart, getTotalAmountUserStart } from '../../../store/redux-slices/fetchData'
import { formatDateYMD } from '../../../utils/dateFormatter'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import moment from 'moment'
import { markUnderInspectionStart } from '../../../store/redux-slices/superAdminSettings'
import { getAffiliateAndAgentsListStart, updateSuperAdminUserNoteStart } from '../../../store/redux-slices/adminUser'
const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const useAllUserListing = ({
  adminId, isUserV2,
  kycStatus
}) => {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()
  const [flagFilter, setRedFlagFilter] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [affiliateSearch, setAffiliateSearch] = useState('')
  const [showIDModal, setShowIDModal] = useState(false)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [kycOptions, setKycOptions] = useState('')
  const [kycSeniorOptions, setKycSeniorOptions] = useState('')
  const [isInternal, setIsInternal] = useState(false)
  const [userId, setUserId] = useState('')
  const [orderBy, setOrderBy] = useState('userId')
  const [sortBy, setSortBy] = useState('desc')
  const [over, setOver] = useState(false)
  const { countries } = useSelector((state) => state.fetch)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [regPlayersType, setRegPlayersType] = useState('all')
  const [transactionType, setTransactionType] = useState('none')
  const [state, setState] = useState(initialDate)
  const [depositRestrictPlayers, setRestrictDeposit] = useState([])
  const [withdrawRestrictPlayers, setRestrictWithdrawal] = useState([])
  const [regState, setRegState] = useState(initialDate)
  const [regPlayerDate, setRegPlayerDate] = useState(initialDate)
  const { userData, totalPlayerAmount, loading } = useSelector((state) => state.fetch)
  const { affiliateAndAgentsList } = useSelector((state) => state.adminUser)
  const totalPages = Math.ceil(userData?.count / limit)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const [show, setShow] = useState(false)
  const [isAnonymous, setIsAnonymous] = useState('')
  const [inspectData, setInspectData] = useState(null)
  const handleMarkAsInspection = () => {
    dispatch(markUnderInspectionStart({ formData: inspectData, fetchData, setShow, setInspectData }))
  }
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [individualUserData, setIndividualUserData] = useState(null)
  const selected = (h) =>
    orderBy === h.value &&
  (h.value === 'email' || h.value === 'userId' || h.value === 'username')


  const userDetailsRouteEndpoint = () => {
    if(kycStatus === 'PENDING'){
      return 'users-pending-kyc'
    }else if(kycStatus === 'APPROVED'){
      return 'users-kyc'
    }else if(kycStatus === 'REJECTED'){
      return 'users-rejected-kyc'
    }else if(isUserV2 === true){
      return 'users-v2'
    }else {
      return 'users'
    }
  }

  const handleIDModal = (data, type, select) => {
    if (['deposit', 'both'].includes(type)) {
      if (select) setRestrictDeposit([...depositRestrictPlayers, ...data])
      else {
        setRestrictDeposit(depositRestrictPlayers?.filter(id => !data?.includes(String(id))))
      }
    }
    if (['withdraw', 'both'].includes(type)) {
      if (select) setRestrictWithdrawal([...withdrawRestrictPlayers, ...data])
      else {
        setRestrictWithdrawal(withdrawRestrictPlayers?.filter(id => !data?.includes(String(id))))
      }
    }
  }
  const handleNote = (data) => {
    dispatch(updateSuperAdminUserNoteStart({
      userId: individualUserData.userId,
      note: data.description,
      fetchData
    }))
  }
  const fetchData = () => {
    dispatch(
      getAllUsersStart({
        limit,
        pageNo: page,
        tenantId: selectedPortal,
        adminId: adminId || selectedClient,
        search: search.replace('+', '%2B'),
        redFlag: flagFilter,
        isUserV2: isUserV2,
        kycStatus,
        affiliateSearch,
        dobStart: moment(state[0].startDate).toISOString() || '',
        dobEnd: moment(state[0].endDate).toISOString() || '',
        userId,
        orderBy,
        sortBy,
        registrationDateStart: moment(regState[0].startDate).toISOString() || '',
        registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
        isInternal,
        country: selectedCountry,
        ftdTo: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.endDate)),
        ftdFrom: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.startDate)),
        usersType: regPlayersType,
        affiliateId: affiliate,
        agentId: agent,
        isAnonymous
      })
    )
    dispatch((getTotalAmountUserStart({
      forEntity: 'superadmin',
      tenantId: selectedPortal,
      adminId: adminId || selectedClient,
      search: search.replace('+', '%2B'),
      redFlag: flagFilter,
      isUserV2: isUserV2,
      kycStatus,
      affiliateSearch,
      registrationDateStart: moment(regState[0].startDate).toISOString() || '',
      registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
      affiliateId: affiliate,
      agentId: agent,
      countryCode: selectedCountry

    })))
  }
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllUsersStart({
              limit,
              pageNo: page,
              tenantId: selectedPortal,
              adminId: adminId || selectedClient,
              search: search.replace('+', '%2B'),
              isUserV2: isUserV2,
              kycStatus,
              affiliateSearch,
              dobStart: moment(state[0].startDate).toISOString() || '',
              dobEnd: moment(state[0].endDate).toISOString() || '',
              userId,
              orderBy,
              sortBy,
              registrationDateStart: moment(regState[0].startDate).toISOString() || '',
              registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
              isInternal,
              country: selectedCountry,
              ftdTo: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.endDate)),
              ftdFrom: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.startDate)),
              usersType: regPlayersType,
              redFlag: flagFilter,
              affiliateId: affiliate,
              agentId: agent,
              isAnonymous
            })
          )
          dispatch((getTotalAmountUserStart({
            forEntity: 'superadmin',
            tenantId: selectedPortal,
            adminId: adminId || selectedClient,
            search: search.replace('+', '%2B'),
            redFlag: flagFilter,
            isUserV2: isUserV2,
            kycStatus,
            affiliateSearch,
            registrationDateStart: moment(regState[0].startDate).toISOString() || '',
            registrationDateEnd: moment(regState[0].endDate).toISOString() || '',
            affiliateId: affiliate,
            agentId: agent,
            countryCode: selectedCountry
          })))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, affiliateSearch, userId])

  useEffect(() => {
    !isInitialRender && fetchData()

    return () => dispatch(clearAllUsers())
  }, [page])

  useEffect(() => {
    setPage(1)
    fetchData()
    return () => dispatch(clearAllUsers())
  }, [limit, adminId, isAnonymous, flagFilter, selectedPortal, kycOptions, kycSeniorOptions, selectedClient, state, orderBy, sortBy, regState, isInternal, selectedCountry, regPlayerDate, regPlayersType, affiliate,
    agent, kycStatus, isUserV2])

  useEffect(() => { dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' })) }, [])
  useEffect(() => {
    if (selectedClient) { dispatch(getAffiliateAndAgentsListStart({ adminUserId: selectedClient })) }
  }, [selectedClient])
  return {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    userData,
    totalPages,
    navigate,
    affiliateSearch,
    setAffiliateSearch,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    kycOptions,
    setKycOptions,
    state,
    setState,
    userId,
    setUserId,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy,
    over,
    setOver,
    selected,
    regState,
    setRegState,
    isInternal,
    setIsInternal,
    countries,
    setSelectedCountry,
    selectedCountry,
    regPlayerDate,
    setRegPlayerDate,
    regPlayersType,
    setRegPlayersType,
    transactionType,
    setTransactionType,
    depositRestrictPlayers,
    setRestrictDeposit,
    withdrawRestrictPlayers,
    setRestrictWithdrawal,
    showIDModal,
    setShowIDModal,
    handleIDModal,
    flagFilter,
    setRedFlagFilter,
    inputRef,
    initialDate,
    handleMarkAsInspection,
    inspectData,
    setInspectData,
    show,
    setShow,
    addNoteModal,
    setAddNoteModal,
    individualUserData,
    setIndividualUserData,
    handleNote,
    kycSeniorOptions,
    setKycSeniorOptions,
    affiliateAndAgentsList,
    agent,
    affiliate,
    setAgent,
    setAffiliate,
    isAnonymous,
    setIsAnonymous,
    totalPlayerAmount: totalPlayerAmount?.[0] ?? totalPlayerAmount,
    userDetailsRouteEndpoint
  }
}

export default useAllUserListing
