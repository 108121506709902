import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTenantWalletTransactionsStart, updateTenantTransactionActionStart } from '../../../../store/redux-slices/tenantTransactions'
import { debounce } from 'lodash'
import { downloadFile } from '../../../../utils/fileDownloader'
import { getLoginToken } from '../../../../utils/storageUtils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const initialDate = [
  {
    startDate: '',
    endDate: '',
    key: 'selection'
  }
]
const UseTenantUserWallet = ({ isWithdrawal, isHistory, isVault, isV2 }) => {
  const initalWalletState = {
    currency: '',
    country: '',
    actionSource: isVault ? isWithdrawal ? 'VAULT-WITHDRAW' : 'VAULT-DEPOSIT' : isWithdrawal ? 'WITHDRAW' : 'DEPOSIT',
    search: '',
    actionType: isHistory ? 'NOT_PENDING' : 'PENDING',
    transactionType: 'REQUEST'
  }
  const { t } = useTranslation()
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [agent, setAgent] = useState('')
  const [affiliate, setAffiliate] = useState('')
  const { allTenantCurrencies } = useSelector(state => state.currencies)
  const {
    tenantWalletTransactions,
    tenantWalletTransactionsLoading
  } = useSelector(state => state.tenantTransactions)
  const { tenantCountries } = useSelector((state) => state.fetch)
  const [formData, setFormData] = useState({ ...initalWalletState, limit, pageNo: page })
  const [actionSource, setActionSource] = useState(null)
  const [actionType, setActionType] = useState(null)
  const [transactionId, setTransactionId] = useState(null)
  const [showWalletRequestAction, setShowWalletRequestAction] = useState(false)
  const [walletRequestAction, setAction] = useState('')
  const [state, setState] = useState(initialDate)
  const totalPages = Math.ceil(tenantWalletTransactions?.walletTranactionDetails?.count / limit)
  const [addOnConfigData, setAddOnConfigData] = useState(null)
  const [tenantConfiguration, setTenantConfiguration] = useState(null)

  const handleShowModal = (action, actionSource, actionType, transactionId, TenantConfiguration, addOnSelect) => {
    setActionSource(actionSource)
    setActionType(actionType)
    setAction(action)
    setShowWalletRequestAction(true)
    setTransactionId(transactionId)
    if (isV2 && action === 'accept') {
      setAddOnConfigData(addOnSelect)
      setTenantConfiguration(TenantConfiguration)
    }
  }
  const handleConfirmActionRequest = () => {
    dispatch(updateTenantTransactionActionStart({
      actionType,
      actionSource,
      transactionId,
      addOnConfigData,
      tenantConfiguration,
      handleClose: () => {
        setShowWalletRequestAction(false)
        setFormData(initalWalletState)
      }
    }))
  }

  useEffect(() => {
    dispatch(getAllTenantWalletTransactionsStart({
      form: {
        ...formData,
        limit,
        pageNo: page,
        from: moment(state[0].startDate).toISOString() || '',
        to: moment(state[0].endDate).toISOString() || '',
        agentId: agent,
        affiliateId: affiliate,
        isV2
      }
    }))
  }, [formData, limit, page, state, agent, affiliate])

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), [])

  function handleDebounceFn (event) {
    setFormData(prev => ({ ...prev, search: event.target.value }))
  }

  const handleCsvDownload = () => {
    downloadFile(`${process.env.REACT_APP_API_URL}/api/tenant/wallet-transactions?currency=${formData.currency || ''}&limit=${limit || 1}&country=${formData.country || ''}&actionSource=${formData.actionSource || ''}&search=${formData.search || ''}&actionType=${formData.actionType || ''}&transactionId=${formData.transactionId || ''}&pageNo=${page || 1}&transactionType=${formData.transactionType || ''}&from=${moment(state[0].startDate).toISOString() || ''}&to=${moment((state[0].endDate)).toISOString() || ''}&csvDownload=true&token=${getLoginToken()}&isV2=${isV2 || ''}`)
  }
  return {
    formData,
    setFormData,
    allTenantCurrencies,
    handleShowModal,
    setShowWalletRequestAction,
    showWalletRequestAction,
    tenantCountries,
    handleConfirmActionRequest,
    walletRequestAction,
    tenantWalletTransactions,
    tenantWalletTransactionsLoading,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    debounceFn,
    initalWalletState,
    inputRef,
    handleCsvDownload,
    initialDate,
    state,
    setState,
    t,
    agent,
    setAgent,
    affiliate,
    setAffiliate
  }
}

export default UseTenantUserWallet
