import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import useLevelSeason, { levelTableHeaders } from './hooks/useLevelSeason'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, Table } from 'react-bootstrap'
import { format } from 'date-fns'
import Trigger from '../../../components/OverlayTrigger'
import InputDatePicker from '../../../components/FormElements/InputDatePicker/InputDatePicker'
import { returnLangName } from '../../../utils/storageUtils'

const Seasons = () => {
  const {
    t,
    loading = false,
    navigate,
    isActive,
    setIsActive,
    seasonList,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    debounceFn,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setSearch,
    searchRef
  } = useLevelSeason()
  return (
    <>
      <div className='p-3'>
        <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
          <h3>{t('listOfSeasons')}</h3>
        </div>
        <div className='mb-2 d-flex align-items-center flex-wrap gap-2'>

          <div className='d-flex gap-2  align-items-center '>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('status')}
            </Form.Label>
            <Form.Select
              onChange={(e) => { setIsActive(e.target.value) }}
              value={isActive}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              <option value='1'>{t('active')}</option>
              <option value='0'>{t('inActive')}</option>
            </Form.Select>
          </div>
          <div className='d-flex align-self-center mt-2'>
            <Form.Label
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>

            <Form.Control
              ref={searchRef}
              type='search'
              placeholder={t('searchByTemplateAndSeason')}
              size='sm'
              style={{
                width: '230px',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(e) => {
                debounceFn(e)
                setPageNo(1)
              }}
            />
          </div>

          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('startDate')}
              value={startDate}
              onChange={(value) => {
                setStartDate(value)
                setPageNo(1)
              }}
            />
          </div>
          <div className='d-flex gap-2  align-items-center mt-2 '>
            <InputDatePicker
              isFilter
              label={t('endDate')}
              value={endDate}
              onChange={(value) => {
                setEndDate(value)
                setPageNo(1)
              }}
            />

          </div>
          <div className='d-flex mt-2'>
            <Trigger message={t('resetFilters')}>
              <Button
                variant='outline-success'
                className='align-self-center'
                size='sm'
                onClick={() => {
                  setSearch('')
                  setPageNo(1)
                  setStartDate('')
                  setEndDate('')
                  searchRef.current.value = ''
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>
          </div>
        </div>
        <div className='list'>
          <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
            <thead className='thead-dark'>
              <tr>
                {levelTableHeaders.map((h, idx) => (
                  <th key={idx}>
                    {t(h)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>

              {seasonList?.seasons?.count > 0 && !loading
                ? (
                    seasonList?.seasons?.rows?.map((season, index) => {
                      return (
                        <tr key={index}>
                          <td> {(index + 1) + (limit * (pageNo - 1))}</td>
                          <td>{returnLangName(season?.seasonName)}</td>
                          <td>{returnLangName(season?.template?.templateName)}</td>
                          <td>
                            <img style={{ width: 60, height: 60, objectFit: 'contain' }} src={season?.seasonUrl} alt='' />
                          </td>
                          <td>{format(new Date(season?.startDate), 'MM-dd-yyyy HH:mm')}</td>
                          <td>{format(new Date(season?.endDate), 'MM-dd-yyyy HH:mm')}</td>
                          <td className={season?.status === 1 ? 'table-success' : 'table-danger'}>{season?.status === 0 ? t('inActive') : t('active')}</td>
                          <td>
                            <Trigger message={t('viewDetails')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() => {
                                  navigate(
                                `/tenant/seasons-detail/${season.seasonId}/${season.tenantId}`
                                  )
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Trigger>
                          </td>
                        </tr>
                      )
                    })
                  )
                : (
                  <tr>
                    <td colSpan={8} className='text-danger text-center'>
                      {t('noDataFound')}
                    </td>
                  </tr>
                  )}

            </tbody>
          </Table>
          {seasonList?.seasons?.count > 0 && !loading && (
            <PaginationComponent
              page={seasonList?.seasons?.count < pageNo ? setPageNo(1) : pageNo}
              totalPages={Math.ceil(seasonList?.seasons?.count / limit)}
              setPage={setPageNo}
              limit={limit}
              setLimit={setLimit}
              totalCount={seasonList?.seasons?.count}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Seasons
