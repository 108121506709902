import { Button, Form as BForm, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { creatRollingCompetetionSchema } from '../schema'
// import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import useCreateEvent from './useCreateAndEditEvents'
import InputDatePicker from '../../../../components/FormElements/InputDatePicker/InputDatePicker'
import { Spinner } from 'react-bootstrap'
import { getCurrentLang, returnLangName } from '../../../../utils/storageUtils'
const CreateAndEditEvent = ({
  isTenant,
  tenantDetails,
  type,
  eventData,
  setRefetch,
  show,
  handleClose
}) => {
  const {
    clientsFilterData,
    languages,
    t,
    handleCreateAndUpdateRolling,
    createLoading
  } = useCreateEvent({
    eventData
  })
  return (
    <>
      <Formik
        enableReinitialize={eventData}
        initialValues={
          {
            tenantId: eventData ? eventData.tenantId : '',
            languageCode: getCurrentLang(),
            eventName: eventData ? returnLangName(eventData?.name) : '',
            eventDescriptions: eventData ? eventData.eventDescriptions : '',
            validFrom: eventData
              ? moment(eventData.validFrom).toDate()
              : moment().toDate(),
            validTo: eventData
              ? moment(eventData.validTo).toDate()
              : moment().add(1, 'day').toDate(),
            imageText: eventData ? returnLangName(eventData?.imageText) : '',
            imageDescription: eventData ? returnLangName(eventData?.imageDescription) : '',
            desktopFile: eventData ? eventData?.thumbUrl : null,
            mobileFile: eventData ? eventData?.mobileThumbUrl : null
          }
        }
        validationSchema={creatRollingCompetetionSchema(t)}
        onSubmit={(formValues, formActions) => {
          handleCreateAndUpdateRolling(formValues, handleClose, setRefetch, formActions)
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          handleReset,
          setFieldError
        }) => {
          return (
            <Form>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered
              >
                <Modal.Header closeButton>
                  <h4 className='d-flex align-items-center'>
                    {t(type)}
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  <div className='d-flex flex-column gap-2'>
                    {!isTenant && (
                      <div>
                        <BForm.Label>{t('owner')}
                          <span className='text-danger'> *</span>
                        </BForm.Label>
                        <div className='d-flex flex-column gap-1'>
                          <BForm.Select
                            type='select'
                            name='tenantId'
                            disabled={eventData}
                            value={values?.tenantId}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          >
                            <option key='' value=''>{eventData ? '' : t('selectOwner')}</option>
                            {clientsFilterData?.rows?.map(
                              ({ adminUserId, firstName, lastName, Tenants }) => (
                                <option key={adminUserId} value={Number(Tenants[0]?.tenantId)}>
                                  {`${firstName} ${lastName}`}
                                </option>
                              )
                            )}
                          </BForm.Select>
                          <ErrorMessage
                            component='div'
                            name='tenantId'
                            className='text-danger'
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <BForm.Label>{t('selectLanguage')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Select
                          name='languageCode'
                          value={values.languageCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {languages && languages?.filter(item => item.isActive).map(({
                            languageName,
                            code
                          }, index) => {
                            return (
                              <option
                                key={index}
                                value={code.toLowerCase()}
                              >
                                {t(String(languageName).toLowerCase())}
                              </option>
                            )
                          })}
                        </BForm.Select>
                        <ErrorMessage
                          component='div'
                          name='languageCode'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('name')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          type='text'
                          name='eventName'
                          placeholder={t('enterName')}
                          value={values.eventName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='eventName'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('eventDescription')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          rows={4}
                          as='textarea'
                          name='eventDescriptions'
                          placeholder={t('enterDescription')}
                          value={values.eventDescriptions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='eventDescriptions'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                      <InputDatePicker
                        isLabelSm
                        disabled={eventData}
                        isRequired
                        isForm
                        label={t('startDate')}
                        value={values.validFrom}
                        onChange={(val) => {
                          setFieldValue('validFrom', val)
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='validFrom'
                        className='text-danger'
                      />
                    </div>
                    <div>
                      <InputDatePicker
                        isLabelSm
                        disabled={eventData}
                        isRequired
                        isForm
                        minDate={values.validFrom}
                        label={t('endDate')}
                        value={values.validTo}
                        onChange={(val) => {
                          setFieldValue('validTo', val)
                        }}
                      />
                      <ErrorMessage
                        component='div'
                        name='validTo'
                        className='text-danger'
                      />
                    </div>
                    <div>
                      <BForm.Label>
                        {t('imageText')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          type='text'
                          name='imageText'
                          placeholder={t('enterImageText')}
                          value={values.imageText}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='imageText'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>
                        {t('imageDescription')}<span className='text-danger'> *</span>
                      </BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Control
                          as='textarea'
                          rows={4}
                          name='imageDescription'
                          placeholder={t('enterDescription')}
                          value={values.imageDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <ErrorMessage
                          component='div'
                          name='imageDescription'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>{t('desktopBanner')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Text>
                          <input
                            id='desktopFile'
                            name='desktopFile'
                            type='file'
                            onChange={(event) => {
                              setFieldValue(
                                'desktopFile',
                                event.currentTarget.files[0]
                              )
                            }}
                          />

                        </BForm.Text>
                        {values?.desktopFile &&
                          (
                            <img
                              alt='file'
                              style={{ width: 150, maxHeight: 150, objectFit: 'contain' }}
                              src={
                                typeof values?.desktopFile !== 'string'
                                  ? URL.createObjectURL(values?.desktopFile)
                                  : values?.desktopFile
                              }
                            />
                          )}
                        <ErrorMessage
                          component='div'
                          name='desktopFile'
                          className='text-danger'
                        />
                      </div>
                    </div>
                    <div>
                      <BForm.Label>{t('mobileBanner')} <span className='text-danger'> *</span></BForm.Label>
                      <div className='d-flex flex-column gap-1'>
                        <BForm.Text>
                          <input
                            id='mobileFile'
                            name='mobileFile'
                            type='file'
                            onChange={(event) => {
                              setFieldValue(
                                'mobileFile',
                                event.currentTarget.files[0]
                              )
                            }}
                          />

                        </BForm.Text>
                        {values?.mobileFile &&
                          (
                            <img
                              alt='file'
                              style={{ width: 150, maxHeight: 150, objectFit: 'contain' }}
                              src={
                                typeof values?.mobileFile !== 'string'
                                  ? URL.createObjectURL(values?.mobileFile)
                                  : values?.mobileFile
                              }
                            />
                          )}
                        <ErrorMessage
                          component='div'
                          name='mobileFile'
                          className='text-danger'
                        />
                      </div>
                    </div>
                  </div>

                </Modal.Body>

                <div className='mt-4 '>
                  <Modal.Footer className='d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => {
                        handleClose()
                        handleReset()
                      }}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      variant='outline-success'
                      onClick={!createLoading && handleSubmit}
                      className='ml-2'
                    >
                      {t('submit')}
                      {createLoading && (
                        <Spinner className='ms-2' size='sm' animation='border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </Spinner>
                      )}
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateAndEditEvent
