import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Modal
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faEdit,
  faRedoAlt,
  faWindowClose,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import CreateCasinoGames from './components/CreateCasinoGames'
import BulkEditDefaultRtp from './components/BulkEditSystemRtp'
import useCasinoGamesListing from './hooks/useCasinoGamesListing'
import Trigger from '../../../components/OverlayTrigger'
import { tableHeaders } from './constants'
import ProviderFilter from '../../../components/ProviderFilter'
import useCheckPermission from '../../../utils/checkPermission'
import GameIdsModal from './components/GameIdsModal'
import ConfirmationModal from '../../../components/ConfirmationModal'
import ChangeStatusModal from '../../../common/Modals/ChangeStatusModal'
import ProviderCategoryDefaultRtpUpdater from './components/ProviderCategoryDefaultRtpUpdater'
export default ({ isCreateBonus, selectedGames = [], setSelectedGames }) => {
  const {
    limit,
    page,
    selectedSubCategoryId,
    show,
    data,
    type,
    setLimit,
    setPage,
    setSelectedSubCategoryId,
    gameSubCategory,
    casinoGamesData,
    totalPages,
    handleClose,
    handleShow,
    loading,
    search,
    setSearch,
    active,
    setActive,
    getProviderName,
    setSelectedProvider,
    selectedProvider,
    showGameIds,
    setShowGameIds,
    handleAction,
    isStatusModalOpen,
    setIsStatusModalOpen,
    userStatusData,
    setUserStatusData,
    getImageByGameCategory,
    t,
    code,
    setRtp,
    defaultRtp,
    fetchData,
    isHideOpen,
    handleCasinoHideYes,
    handleCasinoGameHideOpen,
    handleCasinoHideClose,
    updateLoading,
    selectedDefaultRtpGames,
    setDefaultRtpSelectedGames,
    handleBulkUpdate,
    showBulkUpdateForm,
    showCustomizeSystemRtpForm,
    handleCategoryProviderBasisDefaultRtpUpdate,
    gameCategory,
    categoryId,
    setCategoryId
  } = useCasinoGamesListing()
  const { isHidden } = useCheckPermission()

  const isChecked = (id) => {
    return isCreateBonus ? selectedGames?.find(e => Number(e.masterCasinoGameId) === Number(id)) : selectedDefaultRtpGames?.find(e => Number(e) === Number(id))
  }

  const handleSelectedGames = (e, item) => {
    if (isCreateBonus) {
      if (!isChecked(e.target.value)) {
        setSelectedGames(prev => [...prev, item])
      } else {
        setSelectedGames(selectedGames.filter(t => Number(t.masterCasinoGameId) !== Number(e.target.value)))
      }
    } else {
      if (!isChecked(e.target.value)) {
        setDefaultRtpSelectedGames(prev => [...prev, item.masterCasinoGameId])
      } else {
        setDefaultRtpSelectedGames(selectedGames.filter(t => Number(t) !== Number(e.target.value)))
      }
    }
  }

  return (
    <>
      <Row className='px-3 py-3'>
        <Col sm={8}>
          <h3>{t('casinoGames')}</h3>
        </Col>
      </Row>

      <Row className='mt-2 mb-3 px-3'>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100 mb-3'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
              t={t}
            />
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('search')}
            </Form.Label>
            <Form.Control
              type='search'
              value={search}
              placeholder={t('searchName')}
              size='sm'
              style={{
                width: 'auto',
                marginRight: '10px',
                maxHeight: '15px',
                fontSize: '13px'
              }}
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex gap-2 justify-content-start align-items-center w-100'>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('status')}
            </Form.Label>

            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              value={active}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              <option value='true'>{t('active')}</option>
              <option value='false'>{t('inActive')}</option>
            </Form.Select>
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              column='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              {t('category')}
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setCategoryId(e.target.value)
                setPage(1)
              }}
              value={categoryId}
              size='sm'
              style={{
                width: 'auto',
                marginBottom: '0',
                marginRight: '15px',
                marginTop: '5px',
                fontSize: '13px'
              }}
            >
              <option value=''>{t('all')}</option>
              {gameCategory && gameCategory?.rows?.map(({ mastergamecategoryid, name }) => {
                return <option key={mastergamecategoryid} value={mastergamecategoryid}>{name?.EN}</option>
              })}
            </Form.Select>
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              className='flex-shrink-0'
              style={{
                width: 'auto',
                marginRight: '15px',
                fontSize: '13px',
                marginTop: '5px'
              }}
            >
              {t('subCategory')}
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setSelectedSubCategoryId(e.target.value)
              }}
              value={selectedSubCategoryId || 'all'}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto'
              }}
            >
              <option value=''>{t('all')}</option>
              {gameSubCategory?.rows?.map(({ masterGameSubCategoryId, name }) => (
                <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                  {name?.EN}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label
              className='flex-shrink-0'
              style={{
                width: 'auto',
                marginRight: '15px',
                fontSize: '13px',
                marginTop: '5px'
              }}
            >
              {t('gameWithCustomRtp')}
            </Form.Label>

            <Form.Switch
              onChange={(e) => {
                setRtp(e.target.checked ? 'false' : 'true')
              }}
              checked={defaultRtp === 'false'}
              size='sm'
              style={{
                maxWidth: '180px',
                marginRight: '10px',
                height: 'auto'
              }}
            />
          </div>
        </Col>
        <Col>
          <Trigger message={t('resetFilters')}>
            <Button
              variant='outline-success'
              size='sm'
              className='mt-1'
              onClick={() => {
                setSearch('')
                setSelectedProvider('')
                setActive('')
                setSelectedSubCategoryId('')
                setLimit(15)
                setPage(1)
                setRtp(true)
                setCategoryId('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
        <Col xs='auto'>
          <Trigger message={t('bulkUpdateRtpMessage')}>
            <Button
              onClick={() => {
                handleBulkUpdate()
              }} variant='outline-success'
            >
              {t('bulkUpdate')}
            </Button>
          </Trigger>
        </Col>
        <Col xs='auto'>
          <Trigger message={t('customizeRtpUpdateMessage')}>
            <Button
              onClick={() => {
                handleCategoryProviderBasisDefaultRtpUpdate()
              }} variant='outline-success'
            >
              {t('customizeRtpUpdate')}
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
              >
                {t(h.label)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && casinoGamesData &&
            casinoGamesData?.rows?.map(
              (
                {
                  name,
                  masterCasinoGameId,
                  identifier,
                  thumbnailUrl,
                  masterGameSubCategoryId,
                  subCategoryName,
                  masterCasinoProviderId,
                  isActive,
                  operatorStatus,
                  devices,
                  returnToPlayer,
                  systemRtp,
                  moreDetails,
                  featureGroup,
                  defaultRtp,
                  isHidden: isHiddenGame,
                  activeStatus,
                  'MasterGameSubCategory.name': {
                    EN: subCategoryNameEN
                  } = {
                    EN: ''
                  },
                  'MasterGameCategory.name': {
                    EN: categoryName
                  } = { EN: '' },
                  translations
                },
                index
              ) => {
                return (
                  <tr key={masterCasinoGameId} className={!defaultRtp ? 'under-inspection' : ''}>
                    <td>
                      <div className='text-center d-flex align-items-center justify-content-center '>
                        <label className='me-2 d-inline-flex' htmlFor={masterCasinoGameId}>
                          <input
                            onChange={(e) => handleSelectedGames(e, { masterCasinoGameId, name })}
                            style={{ width: 20, height: 20 }}
                            checked={!!isChecked(masterCasinoGameId)}
                            value={masterCasinoGameId}
                            type='checkbox'
                            id={masterCasinoGameId}
                          />
                        </label>
                        {(index + 1) + (limit * (page - 1))}
                      </div>
                    </td>
                    <td>{masterCasinoGameId}</td>

                    <td>
                      <Trigger message={translations?.[code] ?? name}>
                        <span
                          style={{
                            // width: '130px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {translations?.[code] ?? name}
                        </span>
                      </Trigger>
                    </td>

                    <td className='text-capitalize'>{getProviderName(masterCasinoProviderId)}</td>
                    <td>{returnToPlayer || 'N/A'}</td>

                    <td>{systemRtp || 'N/A'}</td>

                    <td>{categoryName || ''}</td>
                    <td>{subCategoryNameEN || ''}</td>
                    <td>
                      {
                        thumbnailUrl
                          ? (!(masterCasinoProviderId === 13)
                              ? (<img
                                  src={thumbnailUrl} style={{
                                    height: '50px',
                                    width: '60px'
                                  }} onClick={() => window.open(thumbnailUrl)}
                                 />)
                              : (
                                <img
                                  src={getImageByGameCategory(categoryName) || ''}
                                  style={{
                                    height: '50px',
                                    width: '60px'
                                  }}
                                />
                                ))
                          : <img
                              src={getImageByGameCategory(categoryName) || ''}
                              style={{
                                height: '50px',
                                width: '60px'
                              }}
                            />

                      }
                    </td>
                    <td className={isActive ? 'table-success' : 'table-danger'}>
                      {isActive ? t('active') : t('inActive')}

                    </td>
                    <td>
                      {!isCreateBonus && (!isHidden({
                        module:
                        {
                          key: 'CasinoManagement',
                          value: 'U'
                        }
                      }) ||
                        !isHidden({
                          module: {
                            key: 'CasinoManagement',
                            value: 'T'
                          }
                        }))
                        ? (
                          <ButtonGroup>

                            {!isActive
                              ? (
                                <Trigger message={t('setStatusActive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='success'
                                    onClick={() => {
                                      setIsStatusModalOpen(true)
                                      setUserStatusData({
                                        actionType: 'update-status',
                                        gameId: identifier,
                                        masterCasinoGameId,
                                        activeStatus: true,
                                        casinoName: name,
                                        masterCasinoProviderId: masterCasinoProviderId
                                      })
                                    }}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                                )
                              : (
                                <Trigger message={t('setStatusInactive')}>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => {
                                      setIsStatusModalOpen(true)
                                      setUserStatusData({
                                        actionType: 'update-status',
                                        gameId: identifier,
                                        masterCasinoGameId,
                                        activeStatus: false,
                                        casinoName: name,
                                        masterCasinoProviderId: masterCasinoProviderId
                                      })
                                    }}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                                )}
                            <Trigger message={t('editProvider')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='warning'
                                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                onClick={() => handleShow(
                                  t('edit'),
                                  casinoGamesData?.rows[index]
                                )}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>
                            <Trigger message={isHiddenGame ? t('unHideGame') : t('hideGame')}>
                              <Button
                                className='m-1'
                                size='sm'
                                variant={!isHiddenGame ? 'danger' : 'success'}
                                onClick={() => {
                                  handleCasinoGameHideOpen({
                                    masterCasinoGameId: masterCasinoGameId,
                                    activeStatus: !isHiddenGame
                                  })
                                }}
                                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                              >
                                <FontAwesomeIcon icon={isHiddenGame ? faEyeSlash : faEye} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>)
                        : 'NA'}
                    </td>
                  </tr>
                )
              }
            )}

          {casinoGamesData?.count === 0 && !loading &&
            (
              <tr>
                <td
                  colSpan={8}
                  className='text-danger text-center'
                >
                  {t('noDataFound')}
                </td>
              </tr>
            )}
        </tbody>
      </Table>

      {
        casinoGamesData?.count !== 0 && !loading &&
        (
          <PaginationComponent
            page={casinoGamesData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={casinoGamesData?.count}
          />
        )
      }
      {isStatusModalOpen &&
        <ConfirmationModal
          setShow={setIsStatusModalOpen}
          show={isStatusModalOpen}
          handleYes={() => handleAction(userStatusData)}
          active={!userStatusData?.activeStatus}
        />}
      <Modal
        show={show || showBulkUpdateForm}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{showBulkUpdateForm ? `${t('update')} ${t('systemReturnToPlayer')}` : `${type} ${t('casinoGame')}`}</Modal.Title>
        </Modal.Header>
        {
  showBulkUpdateForm
    ? <BulkEditDefaultRtp
        fetchData={fetchData}
        handleClose={handleClose}
        data={data}
        limit={limit}
        pageNo={page}
        t={t}
        active={active}
        selectedSubCategoryId={selectedSubCategoryId}
        selectedProvider={selectedProvider}
        defaultRtp={defaultRtp}
        selectedDefaultRtpGames={selectedDefaultRtpGames}
      />
    : <CreateCasinoGames
        fetchData={fetchData}
        handleClose={handleClose}
        data={data}
        limit={limit}
        pageNo={page}
        t={t}
        active={active}
        selectedSubCategoryId={selectedSubCategoryId}
        selectedProvider={selectedProvider}
        defaultRtp={defaultRtp}
      />
}

      </Modal>

      <Modal
        show={showCustomizeSystemRtpForm}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('customizeRtpUpdateMessage')}</Modal.Title>
        </Modal.Header>
        <ProviderCategoryDefaultRtpUpdater
          fetchData={fetchData}
          handleClose={handleClose}
          t={t}
          selectedDefaultRtpGames={selectedDefaultRtpGames}
        />
      </Modal>

      {showGameIds &&
        <GameIdsModal
          setShow={setShowGameIds}
          show={showGameIds}
        />}
      <ChangeStatusModal handleClose={handleCasinoHideClose} show={isHideOpen} handleUpdateYes={handleCasinoHideYes} loading={updateLoading} />
    </>
  )
}
